import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from '../../..'

const ContainerCentral = styled.div`
    background-color: #ffff;
    padding: 22px;
    border: 1px solid #ccc;
    border-radius: 12px;

    position: relative;

    margin-top: 16px;
`
const Tag = styled.div`
    
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;


    max-width: ${props=>props.width ? props.width : '255px'};
    height: ${props=>props.height ? props.height : '23px'};
    
    color: #002070; 
    background-color: #E6E9F1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 20px;
`

const Text = styled.h2`
font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #292B30;

width: 684px;

margin-top: 8px;
margin-bottom: 20px;
`

const Btn = styled(Button)`
    width: 175px;
    height: 40px;

    position: absolute;
    top: 20px;
    right: 20px;


`

function SubdialogBlank(props){
    return <ContainerCentral {...props}>
                <Tag>{props.tag}</Tag>
                {props.text !== "" && <Text>{props.text}</Text>}
                {props.linkLabel && <Btn
                        label={props.linkLabel}
                        type="outline"
                        onClick={props.funcao}
                    />}
                {props.children}
            </ContainerCentral>
}

export default SubdialogBlank

