import { useState, useEffect, useContext } from "react";

import { format, parseISO } from "date-fns";
import { ptBR, enUS, es } from "date-fns/locale";
import {
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Button,
  Tr,
  Th,
  Td,
  TableContainer,
  Tag,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner,
  Box,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from "@chakra-ui/react";

import iconPendency from "../../assets/images/icon-pendency.svg";
import { TbDotsVertical } from "react-icons/tb";
import { asType, asReport } from "../../services/downloadCert";
import { formatDistance } from "date-fns";
import Translator from "../components/i18n/Translator";
import StrongTranslator from "../components/i18n/StrongTranslator";
import { CustomContext } from "../../context/CustomContext";
const minBronzeNote = window["runConfig"].MIN_BRONZE_NOTE;
const minSilverNote = window["runConfig"].MIN_SILVER_NOTE;
const minGoldNote = window["runConfig"].MIN_GOLD_NOTE;

const ResultsTable = ({ researchsList }) => {
  return researchsList.length > 0 ? (
    <TableContainer>
      <Table
        borderRadius={"2px"}
        variant="simple"
        color="#292B30"
        fontFamily="Raleway"
        border="1px"
        borderColor="#F4F4F4"
        bg="white"
      >
        <Thead
          h="46px"
          background="rgba(0, 0, 0, 0.1)"
          color="#292B30"
          boxShadow="0px 1px 4px rgba(0, 0, 0, 0.1)"
        >
          <Tr className="custom-th" fontWeight={700} borderColor="#F4F4F4">
            <Th color="rgba(41, 43, 48, 1)">
              <Translator path={"results.survey.table.header.name"} />
            </Th>
            <Th>
              <Translator path={"results.survey.table.header.date"} />
            </Th>
            <Th w="14px">
              <Translator path={"results.survey.table.header.note"} />
            </Th>
            <Th>
              <Translator path={"results.survey.table.header.result"} />
            </Th>
            <Th>
              <Translator path={"results.survey.table.header.file"} />
            </Th>
            <Th>
              <Translator path={"results.survey.table.header.resume"} />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {researchsList.map((research, index) => (
            <TableRowCustom
              key={index}
              columnName={
                <Translator path={"results.survey.table.content.name"} />
              }
              research={research}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  ) : (
    <Flex h="60px" align="center" justify="center">
      <Text fontWeight={600}>
        <Translator path={"results.survey.table.notFound"} />
      </Text>
    </Flex>
  );
};

const handleResume = (research) => {
  window.location.replace(
    `/results/resume?cr=${research.id_company_register}&st=${research.id}`
  );
};

const between = (value, min, max) => {
  return value >= min && value <= max;
};

const getCertificationLevel = (researchNote) => {
  if (between(researchNote, minBronzeNote, minSilverNote - 1)) {
    return (
      <Translator path={"results.survey.table.content.result.level.oneStar"} />
    );
  } else if (between(researchNote, minSilverNote, minGoldNote - 1)) {
    return (
      <Translator path={"results.survey.table.content.result.level.twoStars"} />
    );
  } else if (between(researchNote, minGoldNote, 100)) {
    return (
      <Translator path={"results.survey.table.content.result.level.threeStars"} />
    );
  }
};

const TableRowCustom = ({ columnName, research }) => {
  const { systemLanguage, userPermissions } = useContext(CustomContext);

  const [accepted, setAccepted] = useState();
  const [certified, setCertified] = useState();
  const [valid, setValid] = useState();
  const [minimumNote, setMinimumNote] = useState();
  const [daysUntilRating, setDaysUntilRating] = useState(null);
  const [locale, setLocale] = useState(ptBR);

  useEffect(() => {
    if (systemLanguage === "en-US") {
      setLocale(enUS);
    } else if (systemLanguage === "es") {
      setLocale(es);
    } else {
      setLocale(ptBR);
    }
  }, [systemLanguage]);

  useEffect(() => {
    if (research && research.application_date_end) {
      const oneDay = 24 * 60 * 60 * 1000;
      const initialDayCount = new Date(research.application_date_end);
      const addedDays = new Date(research.application_date_end).setDate(
        initialDayCount.getDate() + 16
      );

      if (addedDays - new Date() > oneDay) {
        const days = formatDistance(addedDays, new Date(), {
          locale: locale,
        });

        setDaysUntilRating(days);
      }
    }
  }, [research.application_date_end, research, locale]);

  return (
    <Tr fontSize="12px" maxH="46px">
      <Td
        pl="32px"
        pr="10px"
        whiteSpace={"nowrap"}
        maxW="200px"
        color="rgba(41, 43, 48, 1)"
        fontWeight={600}
        fontSize="13px"
        lineHeight={"15px"}
        textDecor="underline"
      >
        {columnName}
      </Td>
      <Td w="75px">
        {research.application_date === null ? (
          <>-- a --</>
        ) : (
          <Flex gap="4px">
            {format(parseISO(research.application_date), "P", {
              locale: locale,
            })}
            <Text>
              <Translator path={"global.prepositionBetweenDates"} />
            </Text>

            {format(parseISO(research.application_date_end), "P", {
              locale: locale,
            })}
          </Flex>
        )}
      </Td>
      <Td textAlign="center" w="13px">
        {!!research.note ? research.note : "--"}
      </Td>

      <Td>
        {research.status === "certified" ? (
          <Tag
            fontSize="13px"
            bg="#40BF91"
            color="white"
            borderRadius={"6px"}
            fontWeight={600}
            py="4px"
            px="8px"
          >
            <Translator
              path={"results.survey.table.content.result.certified"}
            />{" "}
            {getCertificationLevel(research.note)}
          </Tag>
        ) : research.status.includes("waiting-result") ? (
          <Box>
            <Popover trigger="hover">
              <PopoverTrigger>
                <Image
                  src={iconPendency}
                  alt="icon"
                  style={{ float: "right" }}
                />
              </PopoverTrigger>

              <PopoverContent>
                <PopoverArrow bg="#292B30" />

                <PopoverBody
                  bg="#292B30"
                  borderRadius="6px"
                  gap="10px"
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text color="#FFFFFF">
                    <StrongTranslator
                      path={
                        "results.survey.table.content.result.waiting.rating.helper1"
                      }
                    />
                    {daysUntilRating ? (
                      <>
                        <StrongTranslator
                          path={
                            "results.survey.table.content.result.waiting.rating.helper2"
                          }
                          days={daysUntilRating}
                        />
                      </>
                    ) : (
                      <>
                        <Translator
                          path={
                            "results.survey.table.content.result.waiting.rating.helper3"
                          }
                        />
                      </>
                    )}
                    <Translator
                      path={
                        "results.survey.table.content.result.waiting.rating.helper4"
                      }
                    />
                  </Text>
                </PopoverBody>
              </PopoverContent>
            </Popover>

            <Tag
              fontSize="13px"
              bg="yellow"
              color="black"
              borderRadius={"6px"}
              fontWeight={600}
              py="4px"
              px="8px"
            >
              <Translator
                path={
                  "results.survey.table.content.result.waiting.rating.title"
                }
              />
            </Tag>
          </Box>
        ) : research.status === "not-certified" ? (
          <Tag
            fontSize="13px"
            bg="red.100"
            color="white"
            borderRadius={"6px"}
            fontWeight={600}
            py="4px"
            px="8px"
          >
            <Translator
              path={"results.survey.table.content.result.notCertified"}
            />
          </Tag>
        ) : research.status === "in-progress" ||
          research.status === "waiting-customization" ? (
          <Tag
            fontSize="13px"
            bg="grey"
            color="white"
            borderRadius={"6px"}
            fontWeight={600}
            py="4px"
            px="8px"
          >
            <Translator
              path={"results.survey.table.content.result.waiting.beginning"}
            />
          </Tag>
        ) : (
          <Tag
            fontSize="13px"
            bg="red"
            color="white"
            borderRadius={"6px"}
            fontWeight={600}
            py="4px"
            px="8px"
          >
            <Translator path={"results.survey.table.content.result.error"} />
          </Tag>
        )}
      </Td>

      <Td textAlign={"center"} w="51px">
        <DoprodownButton
          research={research}
          certified={research.status === "certified"}
        />
      </Td>
      {userPermissions.includes("SURVEY_RESUME_VIEW") ? (
        <Td
          fontWeight={600}
          textDecor="underline"
          color="red.100"
          _hover={{
            cursor: "pointer",
          }}
          onClick={() =>
            !!research.extern_researchId ? handleResume(research) : ""
          }
        >
          <Translator path={"results.survey.table.content.resume.text"} />
        </Td>
      ) : (
        <Td fontWeight={600} textDecor="underline" color="red.100">
          Veja pesquisa GPTW
        </Td>
      )}
    </Tr>
  );
};

export default ResultsTable;

const handleDownloadLabel = (research) => {
  let surveyEndDate = new Date(research.application_date_end);
  let dueSurveyDate = new Date(research.application_date_end);

  dueSurveyDate.setDate(surveyEndDate.getDate() + 365);

  surveyEndDate = surveyEndDate.toLocaleDateString("pt-BR", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  dueSurveyDate = dueSurveyDate.toLocaleDateString("pt-BR", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  let formattedEndDate = dueSurveyDate;

  surveyEndDate = surveyEndDate.split(/\//g);
  surveyEndDate = `${surveyEndDate[2]}-${surveyEndDate[1]}-${surveyEndDate[0]}`;

  let certs = {
    surveyEndDate,
    formattedEndDate,
  };
  console.log(research.note);

  asType(research.note, "png");
};

const handleDownloadReport = (research) => {
  console.log("funcao em resultTable");
  asReport(research.id);
};

const DoprodownButton = ({ research, certified }) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        py="3.75px"
        bg="#E5E5E5"
        w="20px"
        minW={0}
        maxW="20px"
        h="20px"
        p={0}
      >
        <TbDotsVertical color="black" />
      </MenuButton>
      <MenuList
        borderRadius={"8px"}
        background="white"
        border="1px solid #F4F4F4"
      >
        <MenuItem
          disabled={!certified}
          opacity={!certified ? "0.4" : "1"}
          py="10px"
          onClick={() => (!!certified ? handleDownloadLabel(research) : "")}
        >
          <Translator path={"results.survey.table.content.file.label"} />
        </MenuItem>
        <MenuItem
          disabled={!research.result_link}
          opacity={!research.result_link ? "0.4" : "1"}
          py="10px"
          onClick={() => (!!certified ? handleDownloadReport(research) : "")}
        >
          <Translator path={"results.survey.table.content.file.report"} />
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
