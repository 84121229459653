import React, { useEffect, useState, useContext } from "react";
import { CSVLink } from "react-csv";

import styled from "styled-components";
import { MdOutlineArrowForwardIos } from "react-icons/md";

import getUrlParams from "../../../services/getUrlParams";
import { useResearch } from "../../../helpers/useResearch";

import iconPendency from "../../../assets/images/icon-pendency.svg";
import iconBar from "../../../assets/images/icon-bar.svg";
import iconSuccess from "../../../assets/images/icon-success.svg";
import iconWarning from "../../../assets/images/icon-warning.svg";
import backBlue from "../../../assets/images/back-blue.svg";
import certificationLogo from "../../../assets/images/icon-logo-certificacao.svg";

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Flex,
  Grid,
  GridItem,
  Tag,
  Text,
  VStack,
  Button,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Image,
  Spinner,
} from "@chakra-ui/react";

import UploadSheetModal from "./Modals/UploadSheet";
// import CardForm from "./CardForm";
import SimpleSidebar from "../../components/nav/Sidebar";
import LanguageData from "./LanguageData";
import ResearchDate from "./ResearchDate";
import Steps from "./Steps";
import SpecificQuestions from "./SpecifQuestions";
import Affirmatives from "./Affirmatives";

import { researchApi } from "../../../services/api-research";
import { CustomContext } from "../../../context/CustomContext";
import Comunication from "./Comunication";
import Translator from "../../components/i18n/Translator";
import StrongTranslator from "../../components/i18n/StrongTranslator";
import { useTranslation } from "react-i18next";
import ResearchLanguage from "./ResearchLanguage";

const Layout = styled.div`
  margin-left: 32px;
  background: #f6f5fb;
`;

const ResearchCertificates = ({ validateRoute }) => {
  const { document: doc, key: id } = getUrlParams("");
  const { t } = useTranslation();

  const companyRegister = doc[0];
  const researchIdentifier = id[0];

  const [allStepsComplete, setAllStepsCompletes] = useState(false);

  const [researchId, setResearchId] = useState("");
  const [researchLanguages, setResearchLanguages] = useState([]);
  const { custom, setId, userPermissions } = useContext(CustomContext);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadSheetCompleted, setUploadSheetCompleted] = useState(false);
  // const [freeQuestionCompleted, setFreeQuestionCompleted] = useState(false);
  const [elegibleEmployees, setElegibleEmployees] = useState(0);

  const [allSteps, setAllSteps] = useState({
    sheet: false,
    date: false,
  });

  const { data } = useResearch(
    !!researchId && researchId.length > 1 ? researchId : undefined
  );

  useEffect(() => {
    setResearchId(researchIdentifier);
    setId(researchIdentifier);

    if (data !== undefined) {
      handleDownloadList();
      setElegibleEmployees(data.research.elegible_employees);
      setResearchLanguages(data.research.languages);
    }
  }, [
    researchIdentifier,
    uploadSheetCompleted,
    data,
    elegibleEmployees,
    setId,
  ]);

  useEffect(() => {
    if (!!data) {
      data.research = {
        ...data.research,
        languages: researchLanguages,
      };
    }
  }, [researchLanguages]);

  console.log("Pesquisa: ", data);

  const breadcumbs = [
    {
      nome: <Translator path={"survey.title"} />,
      tipo: "link",
      path: `/researches?cr=${companyRegister}`,
      icone: "",
      ativado: true,
    },
  ];

  const handleUpload = () => {
    setUploadModal(true);
  };

  const handleCloseUpload = () => {
    setUploadModal(false);
  };

  const handleSuccessUpload = () => {
    setUploadSheetCompleted(true);
    handleDownloadList();
    handleUploadSuccess("sheet");
  };

  const handleUploadSuccess = (item) => {
    if (item === "sheet") {
      setAllSteps({
        ...allSteps,
        sheet: true,
      });
    }

    if (item === "date") {
      setAllSteps({
        ...allSteps,
        date: true,
      });
    }
  };

  const handleUpdateEmployeesTotal = (employeesTotal) => {
    setElegibleEmployees(employeesTotal);
  };

  // const handleFreeQuestionCreation = (success) => {
  //   setFreeQuestionCompleted(success)
  // }

  async function getTemplate() {
    fetch(
      `${process.env.PUBLIC_URL}/resources/${t(
        "survey.config.sheet.fileName"
      )}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", t("survey.config.sheet.fileName"));

        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  }

  const [csvData, setCsvData] = useState([]);

  async function handleDownloadList() {
    const response = await researchApi.get(`/export/${researchId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });

    const csvData = response.data;
    setCsvData(csvData);
  }

  return !validateRoute(userPermissions, window.location.pathname) ? (
    <></>
  ) : (
    <Layout>
      <SimpleSidebar path="">
        {data === undefined ? (
          <Box w="100%" textAlign="center" h="30vh" pt="20px">
            <Spinner />
          </Box>
        ) : (
          <Container maxW="container.xl" pt={4} pb={4}>
            <Flex justifyContent="space-between">
              <Breadcrumb
                mb="20px"
                spacing="8px"
                separator={<MdOutlineArrowForwardIos color="gray.500" />}
              >
                {breadcumbs.map((b, index) => (
                  <BreadcrumbItem key={index}>
                    <BreadcrumbLink href={b.path}>
                      {b.ativado ? (
                        <span style={{ fontWeight: "bold" }}> {b.nome}</span>
                      ) : (
                        <span>{b.nome}</span>
                      )}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                ))}
              </Breadcrumb>

              <Flex h="20px" alignItems="center" gap="8px">
                <Text fontSize="14px">
                  <Translator path={"survey.status.title"} />
                </Text>
                <Text
                  fontSize="14px"
                  color={
                    data.research.status === "Aprovada" ? "#40BF91" : "#FF6700"
                  }
                  fontWeight="600"
                >
                  {data.research.status}
                </Text>
                <Image src={iconPendency} alt="pendente" />
              </Flex>
            </Flex>

            <Grid
              templateColumns="repeat(4, 1fr)"
              gap={4}
              maxW="100%"
              h="148px"
              bg="white"
              borderRadius={"12px"}
              overflow="hidden"
              mb="20px"
              border="1px"
              borderColor="#CCCCCC"
            >
              <GridItem
                h="100%"
                colSpan={1}
                w="350px"
                backgroundRepeat={"no-repeat"}
                backgroundImage={backBlue}
                backgroundSize="fill"
                pl="32px"
                pt="55px"
              >
                <Tag
                  bg="#40BF91"
                  color="white"
                  fontSize="14px"
                  lineHeight="16px"
                  h="24px"
                  w="96px"
                >
                  <Translator path={"survey.infoCard.title"} />
                </Tag>
                <Heading
                  as="h3"
                  color="white"
                  fontSize="18px"
                  lineHeight="21px"
                  mt="10px"
                  mb="10px"
                >
                  <Translator path={"survey.infoCard.subtitle"} />
                </Heading>
              </GridItem>

              <GridItem colSpan={3} h="100%" p="20px">
                {data.research.status !== "Aprovada" ? (
                  <Steps
                    researchId={researchId}
                    cr={companyRegister}
                    setCompleted={setAllStepsCompletes}
                    allSteps={allSteps}
                  />
                ) : (
                  <Flex gap="36px">
                    <Image
                      src={certificationLogo}
                      alt="Logo certificação"
                      maxH="116px"
                    />
                    <Box>
                      <Text fontSize="15px" lineHeight="22.4px">
                        Parabéns!
                      </Text>
                      <Text
                        fontSize="15px"
                        fontWeight="600"
                        lineHeight="20.4px"
                        mt="5px"
                      >
                        Sua empresa
                        <br />
                        foi certificada!
                      </Text>

                      <Tag
                        fontSize="14px"
                        mt="10px"
                        bg="rgba(0, 32, 112, 0.1)"
                        borderRadius="6px"
                        color="#002070"
                        h="16px"
                        fontWeight="600"
                      >
                        Nota: 92
                      </Tag>
                    </Box>

                    <Box fontSize="13px" w="250px" color="#292B30" ml="20px">
                      <Text mb="10px" fontSize="13px" lineHeight="18.2px">
                        Agora que você conquistou a
                        <br />
                        <b>
                          Certificação Jungle, é hora de concorrer aos nossos
                          rankings!
                        </b>
                      </Text>
                      <Text fontSize="13px" lineHeight="18.2px">
                        Confira os
                        <Text
                          as="span"
                          color="red.100"
                          fontWeight="600"
                          textDecor="underline"
                          ml="4px"
                        >
                          Rankings disponíveis aqui
                        </Text>
                      </Text>
                    </Box>
                  </Flex>
                )}
              </GridItem>
            </Grid>

            <Box mt={6}>
              <Tabs variant="unstyled">
                <TabList>
                  <Tab
                    padding={0}
                    fontSize={"16px"}
                    fontWeight="500"
                    _selected={{
                      fontWeight: "bold",
                      borderBottom: "4px solid #013280",
                    }}
                  >
                    <Translator path={"survey.config.title"} />
                  </Tab>
                  <Tab
                    fontSize={"16px"}
                    fontWeight="500"
                    _selected={{
                      fontWeight: "bold",
                      borderBottom: "4px solid #013280",
                    }}
                  >
                    <Translator path={"survey.view.title"} />
                  </Tab>
                </TabList>
                {custom && allStepsComplete && (
                  <Flex
                    w="100%"
                    h="36px"
                    bg="#3D9BE9"
                    mt="24px"
                    mb="10px"
                    borderRadius="10px"
                    alignItems="center"
                    justify="center"
                    gap="8px"
                  >
                    <Image src={iconWarning} alt="icon" />
                    <Text fontSize="14px" color="red.100">
                      <StrongTranslator path={"survey.config.date.warning"} />
                    </Text>
                  </Flex>
                )}

                {!custom && (
                  <Flex
                    w="100%"
                    h="36px"
                    bg="#3D9BE9"
                    mt="24px"
                    mb="10px"
                    borderRadius="10px"
                    alignItems="center"
                    justify="center"
                    gap="8px"
                  >
                    <Image src={iconWarning} alt="icon" />
                    <Text fontSize="14px" color="red.100" fontWeight="600">
                      <Translator path={"survey.config.date.blocked"} />
                    </Text>
                  </Flex>
                )}
                <TabPanels>
                  <TabPanel px={0}>
                    <Flex gap="20px" h="206px">
                      {/* <CardForm researchId={researchId} cr={companyRegister} /> */}
                      <Box
                        h="100%"
                        w="360px"
                        minW="360px"
                        maxH="206px"
                        maxW="360px"
                        border="1px"
                        bg="white"
                        borderColor="#CCCCCC"
                        borderRadius="20px"
                        position="relative"
                        pl="30px"
                        pr="20px"
                        pb="30px"
                        pt="30px"
                      >
                        <Box>
                          <img src={iconBar} alt="icon bar" />
                          <Heading
                            as="h4"
                            fontSize={"16px"}
                            fontWeight="600"
                            color="#292B30"
                            pt={1.5}
                          >
                            <Translator
                              path={"survey.config.personalization.title"}
                            />
                          </Heading>
                        </Box>
                        <Box mt="15px" templateRows="repeat(1, 1fr)">
                          <ResearchLanguage
                            researchLanguages={researchLanguages}
                            setResearchLanguages={setResearchLanguages}
                            researchId={researchId}
                          />
                        </Box>
                      </Box>
                      <Box
                        w="100%"
                        h="206px"
                        border="1px"
                        bg="white"
                        borderColor="#CCCCCC"
                        borderRadius="20px"
                        position="relative"
                        pt="30px"
                        pl="30px"
                        pr="20px"
                        pb="30px"
                      >
                        <img
                          src={iconPendency}
                          alt="icon"
                          style={{ float: "right" }}
                        />

                        <img src={iconBar} alt="icon bar" />
                        <Text fontSize="16px" fontWeight="600" pt={1.5}>
                          <Translator path={"survey.config.sheet.title"} />
                        </Text>
                        <Flex
                          h="92px"
                          mt="15px"
                          w="100%"
                          justifyContent={"space-between"}
                        >
                          <VStack spacing={3} align="stretch">
                            <Button
                              bg="#013280"
                              w="265px"
                              color="#ffffff"
                              borderRadius="10px"
                              onClick={getTemplate}
                            >
                              <Translator
                                path={"survey.config.sheet.template"}
                              />
                            </Button>
                            <Button
                              w="265px"
                              fontWeight="700"
                              color={!custom ? "#292B30" : "red.100"}
                              border="1px"
                              disabled={!custom}
                              borderColor={!custom ? "#292B30" : "red.100"}
                              borderRadius="10px"
                              variant="outline"
                              _hover={
                                custom
                                  ? {
                                      bg: "#3D9BE9",
                                      color: "white",
                                    }
                                  : {}
                              }
                              onClick={handleUpload}
                            >
                              {data.employees_sheet ? (
                                <Translator
                                  path={"survey.config.sheet.updateButton"}
                                />
                              ) : (
                                <Translator
                                  path={"survey.config.sheet.sendButton"}
                                />
                              )}
                            </Button>
                          </VStack>
                          {data.employees_sheet ? (
                            <Box
                              w="371px"
                              border="1px"
                              borderColor="#40BF91"
                              borderRadius="12px"
                              py="16px"
                              px="20px"
                              h="92px"
                              position="relative"
                            >
                              <Image
                                src={iconSuccess}
                                alt="success"
                                position="absolute"
                                right="20px"
                              />
                              <Text fontWeight="600" color="#292B30">
                                {elegibleEmployees}{" "}
                                <Translator
                                  path={
                                    "survey.config.sheet.selected.employees"
                                  }
                                />
                              </Text>

                              <Box
                                w="20px"
                                color="red.100"
                                h="2px"
                                mt="5px"
                                bg="red.100"
                              />
                              <Flex
                                h="16px"
                                mt="5px"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Text fontSize="14px" color="#5C6066">
                                  <Translator
                                    path={"survey.config.sheet.selected.text"}
                                  />
                                </Text>

                                <Button
                                  bg="none"
                                  p={0}
                                  _hover={{
                                    bg: "none",
                                  }}
                                  fontSize="14px"
                                  color="#013280"
                                  fontWeight="500"
                                  textDecor="underline"
                                  onClick={handleDownloadList}
                                >
                                  <CSVLink
                                    filename={t(
                                      "survey.config.sheet.selected.employees"
                                    )}
                                    data={csvData}
                                  >
                                    <Translator
                                      path={
                                        "survey.config.sheet.selected.visualize"
                                      }
                                    />
                                  </CSVLink>
                                  ;
                                </Button>
                                {/* <CSVLink data={csvData}>Ver lista</CSVLink>; */}
                              </Flex>
                            </Box>
                          ) : (
                            <Box
                              onClick={handleUpload}
                              cursor="pointer"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              border="1px"
                              borderColor="#40BF91"
                              w="361px"
                              h="100%"
                              borderRadius="10px"
                              borderStyle="dashed"
                            >
                              <Text fontSize={"13px"}>
                                <Translator path={"survey.config.sheet.send"} />
                              </Text>
                            </Box>
                          )}
                        </Flex>
                      </Box>
                    </Flex>

                    {/* <Flex
                        bg="#ffffff"
                        mt="20px"
                        border="1px"
                        borderColor="#CCCCCC"
                        borderRadius="20px"
                        p={6}
                        flexDir="column"
                      >
                        <Box>
                          <img src={iconBar} alt="icon bar" />
                          <Heading
                            as="h4"
                            fontSize={"18px"}
                            fontWeight="600"
                            pt={2}
                            pb="24px"
                            color="#292B30"
                          >
                            Personalização da Pesquisa
                          </Heading>
                        </Box>
                        <Grid templateRows="repeat(1, 1fr)" gap={4} w="100%">

                          <SpecificQuestions
                            researchId={researchId}
                            hasEmployees={uploadSheetCompleted}
                            onSuccess={handleFreeQuestionCreation}
                          />

                        </Grid>
                      </Flex> */}

                    <Grid
                      templateColumns="repeat(2, 1fr)"
                      gap={4}
                      h="224px"
                      mt="20px"
                    >
                      <ResearchDate
                        researchId={researchId}
                        canEdit={uploadSheetCompleted}
                        onSuccess={handleUploadSuccess}
                      />

                      <Comunication />
                    </Grid>
                    <UploadSheetModal
                      researchId={researchId}
                      isOpen={uploadModal}
                      onClose={handleCloseUpload}
                      // handleDownloadList={handleDownloadList}
                      onSuccess={handleSuccessUpload}
                      updateEmployees={handleUpdateEmployeesTotal}
                    />
                  </TabPanel>
                  <TabPanel></TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Container>
        )}
      </SimpleSidebar>
    </Layout>
  );
};

export default ResearchCertificates;
