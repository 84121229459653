/* eslint-disable no-unused-vars */
import { useEffect, useState, useContext } from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Tag,
  Text,
  Button,
  Image,
} from "@chakra-ui/react";
import { useLanguage } from "../../../helpers/useLanguageInfo";
import { CustomContext } from "../../../context/CustomContext";

import managers from "../../../assets/images/managers.svg";
import iconReady from "../../../assets/images/icon-ready.svg";
import colaboradores from "../../../assets/images/colaboradores.svg";
import companyName from "../../../assets/images/company-name.svg";

import LanguageModal from "./Modals/LanguageModal";

const LanguageData = ({ researchId }) => {
  const { custom } = useContext(CustomContext);

  const [modal, setModal] = useState(false);
  const [languageData, setLanguageData] = useState(false);
  const [info, setInfo] = useState({});
  const { data } = useLanguage(researchId);

  useEffect(() => {
    if (data instanceof Array && data.length > 0) {
      setLanguageData(true);
      setInfo(data[0]);
    }
  }, [data]);

  const handleLanguageModal = () => {
    if (custom) {
      setModal(true);
    } else return;
  };

  const handleCloseLanguageModal = () => {
    setModal(false);
  };

  return (
    <GridItem
      h="277px"
      border="1px"
      borderColor="#CCCCCC"
      borderRadius="20px"
      p="20px"
    >
      <LanguageModal
        data={data}
        researchId={researchId}
        isOpen={modal}
        onClose={handleCloseLanguageModal}
      />
      <Flex justify={"space-between"} align="center">
        <Box>
          <Tag
            bg="#e5ebec"
            color="#002070"
            borderRadius="20px"
            fontSize="16px"
            h="23px"
            px="15px"
            fontWeight="600"
          >
            Linguagem organizacional
          </Tag>
          <Text as="p" fontSize="14px" pt={1} mt="8px">
            É possível <b> adequar alguns dos termos </b>
            que serão usados na Pesquisa de Clima para se adaptar melhor a realidade
            <br />a as <b>terminologias</b> utilizadas na sua empresa.
          </Text>
        </Box>
        <Button
          w="175px"
          variant="outline"
          color={!custom ? "#292B30" : "red.100"}
          border="1px"
          disabled={!custom}
          borderColor={!custom ? "#292B30" : "red.100"}
          borderRadius="10px"
          fontWeight="bold"
          onClick={handleLanguageModal}
        >
          {languageData ? "Editar" : "Configurar"}
        </Button>
      </Flex>

      <Grid templateColumns="repeat(3, 1fr)" gap={6} mt="25px">
        {languageData ? (
          <GridItem
            w="100%"
            h="140px"
            border="1px"
            py="30px"
            px="20px"
            borderColor="#40BF91"
            borderRadius="12px"
          >
            <Image src={iconReady} alt="true" h="20px" w="20px" mb="5px" />
            <Text fontSize="18px" color="#292B30" fontWeight="700">
              {info.manager}
            </Text>
            <Box h="2px" w="25px" bg="red.100" borderRadius="5px" />
            <Text fontSize="14px" color="#5C6066" mt="8px">
              São os nossos
              <Text as="span" fontWeight="600" ml="5px">
                gestores
              </Text>
            </Text>
          </GridItem>
        ) : (
          <GridItem
            w="100%"
            h="140px"
            border="1px"
            borderColor="#5C6066"
            fontSize="14px"
            borderRadius="10px"
            borderStyle="dashed"
          >
            <Flex>
              <Flex flex={2} alignItems="center" justify={"center"}>
                <Text>
                  Configure como são
                  <br />
                  chamados os <b> gestores </b>
                  <br />
                  da sua empresa
                </Text>
              </Flex>
              <Flex flex={1} pb="0px">
                <img src={managers} alt="character" />
              </Flex>
            </Flex>
          </GridItem>
        )}

        {languageData ? (
          <GridItem
            w="100%"
            h="140px"
            border="1px"
            py="30px"
            px="20px"
            borderColor="#40BF91"
            borderRadius="12px"
          >
            <Image src={iconReady} alt="true" h="20px" w="20px" mb="5px" />
            <Text fontSize="18px" color="#292B30" fontWeight="700">
              {info.collaborator}
            </Text>
            <Box h="2px" w="25px" bg="red.100" borderRadius="5px" />
            <Text fontSize="14px" color="#5C6066" mt="8px">
              São as nossas
              <Text as="span" fontWeight="600" ml="5px">
                pessoas
              </Text>
            </Text>
          </GridItem>
        ) : (
          <GridItem
            w="100%"
            h="140px"
            border="1px"
            borderColor="#5C6066"
            fontSize="14px"
            borderRadius="10px"
            borderStyle="dashed"
          >
            <Flex>
              <Flex flex={2} alignItems="center" justify={"center"}>
                <Text>
                  Configure como são
                  <br />
                  chamados os <b> colaboradores </b>
                  <br />
                  da sua empresa
                </Text>
              </Flex>
              <Flex flex={1} pb="0px">
                <img src={colaboradores} alt="character" />
              </Flex>
            </Flex>
          </GridItem>
        )}

        {languageData ? (
          <GridItem
            w="100%"
            h="140px"
            border="1px"
            py="30px"
            px="20px"
            borderColor="#40BF91"
            borderRadius="12px"
          >
            <Image src={iconReady} alt="true" h="20px" w="20px" mb="5px" />
            <Text fontSize="18px" color="#292B30" fontWeight="700">
              {info.company}
            </Text>
            <Box h="2px" w="25px" bg="red.100" borderRadius="5px" />
            <Text fontSize="14px" color="#5C6066" mt="8px">
              É a nossa
              <Text as="span" fontWeight="600" ml="5px">
                empresa
              </Text>
            </Text>
          </GridItem>
        ) : (
          <GridItem
            w="100%"
            h="140px"
            border="1px"
            borderColor="#5C6066"
            fontSize="14px"
            borderRadius="10px"
            borderStyle="dashed"
          >
            <Flex>
              <Flex flex={2} alignItems="center" justify={"center"}>
                <Text>
                  Configure como é chamada
                  <br />a sua empresa <b> empresa </b>
                </Text>
              </Flex>
              <Flex flex={1} pb="0px">
                <img src={companyName} alt="character" />
              </Flex>
            </Flex>
          </GridItem>
        )}
      </Grid>
    </GridItem>
  );
};

export default LanguageData;
