import {
  Box,
  FormLabel,
  CircularProgress,
  CircularProgressLabel,
  Image,
  Text,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { researchApi } from "../../../services/api-research";
import iconAdd from "../../../assets/images/icon-add-file.svg";
import Translator from "../i18n/Translator";

const FileInput = ({ companyData, setLogoKey, logoKey }) => {
  const toast = useToast();
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentFile, setCurrentFile] = useState();
  const [currentLogo, setCurrentLogo] = useState();

  const handleImageUpload = async (event) => {
    if (!event.target.files?.length) {
      return;
    }

    if (event.target.files[0].type !== "image/png") {
      toast({
        title: <Translator path={"company.logo.error.title"} />,
        description: <Translator path={"company.logo.error.description"} />,
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      return;
    }

    setCurrentFile(undefined);
    setIsLoading(true);

    const formData = new FormData();

    formData.append("logo", event.target.files[0]);

    const config = {
      headers: { "content-type": "multipart/form-data" },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      onUploadProgress: (e) => {
        setProgress(Math.round((e.loaded / e.total) * 100));
      },
    };

    try {
      const response = await researchApi.post(
        `/companies/${companyData.id}/logo`,
        formData,
        config
      );
      setLogoKey(response.data);
      setCurrentFile(event.target.files[0]);
      setCurrentLogo(URL.createObjectURL(event.target.files[0]));
    } catch (error) {
      console.error(error);
      console.error(error.message);
    } finally {
      setIsLoading(false);
      setProgress(0);
    }
  };

  useEffect(() => {
    async function getLogo() {
      setIsLoading(true);

      try {
        const response = await researchApi.get(
          `/companies/${companyData.id}/logo`,
          {
            responseType: "blob",
          }
        );

        const blob = new Blob([response.data], { type: "image/png" });
        const logoObject = URL.createObjectURL(blob);

        setCurrentLogo(logoObject);
      } catch (error) {
        console.error(
          `Erro ao recuperar a logo da empresa: ${companyData.id}`,
          error
        );
        setCurrentLogo("broken-logo");
      } finally {
        setIsLoading(false);
      }
    }

    if (companyData.logoKey) {
      getLogo();
    }
  }, [companyData.id, companyData.logoKey, currentFile, logoKey]);

  return (
    <form>
      <FormLabel
        mx="auto"
        w="100%"
        h="92px"
        cursor={isLoading ? "progress" : "pointer"}
        opacity={isLoading ? 0.5 : 1}
      >
        {currentLogo && !isLoading ? (
          <Flex
            boxSizing="border-box"
            position="absolute"
            w="197px"
            h="289px"
            border="1px"
            borderColor="#B8CAE8"
            borderRadius="10px"
            justifyContent="center"
            alignItems="center"
            gap="16px"
          >
            <Image src={currentLogo} alt="logo" position="absolute" />
          </Flex>
        ) : (
          <Flex
            w="100%"
            h="full"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            borderRadius="md"
            bgColor="pGray.800"
            color="pGray.200"
            borderWidth={false && 2}
            borderColor={false && "red.500"}
          >
            {isLoading ? (
              <Box pos="relative" h="full" w="100%">
                <Flex
                  boxSizing="border-box"
                  position="absolute"
                  w="197px"
                  h="289px"
                  border="1px"
                  borderStyle="dashed"
                  borderColor="#5C6066"
                  borderRadius="10px"
                  justifyContent="center"
                  alignItems="center"
                  gap="16px"
                >
                  <CircularProgress
                    trackColor="pGray.200"
                    value={progress}
                    color="orange.500"
                    top="30.17%"
                  >
                    <CircularProgressLabel>{progress}%</CircularProgressLabel>
                  </CircularProgress>
                </Flex>
              </Box>
            ) : (
              <Box pos="relative" h="full" w="100%">
                <Flex
                  boxSizing="border-box"
                  position="absolute"
                  w="197px"
                  h="289px"
                  border="1px"
                  borderStyle="dashed"
                  borderColor="#5C6066"
                  borderRadius="10px"
                  justifyContent="center"
                  alignItems="center"
                  gap="16px"
                >
                  <Image
                    src={iconAdd}
                    alt="icon add"
                    position="absolute"
                    top="30.17%"
                  />
                  <Text
                    as="span"
                    pt={10}
                    w="102px"
                    h="40px"
                    left="128px"
                    top="351px"
                    fontFamily="Raleway"
                    fontStyle="normal"
                    fontWeight="400"
                    fontSize="14px"
                    textAlign="center"
                    alignItems="center"
                    display="flex"
                    color="#292B30"
                  >
                    <Translator path={"company.logo.title"} />
                  </Text>
                </Flex>
              </Box>
            )}
          </Flex>
        )}

        <input
          onChange={handleImageUpload}
          type="file"
          style={{
            display: "none",
          }}
        />
      </FormLabel>
    </form>
  );
};

export default FileInput;
