import React, { useContext, useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { ptBR, enUS, es } from "date-fns/locale";

import {
  Box,
  Grid,
  GridItem,
  Text,
  Image,
  Divider,
  Spinner,
} from "@chakra-ui/react";
import { fetchResearch, useResearch } from "../../../helpers/useResearch";
import Translator from "../../components/i18n/Translator";
import StrongTranslator from "../../components/i18n/StrongTranslator";
import iconPendency from "../../../assets/images/icon-pendency.svg";
import iconReady from "../../../assets/images/icon-ready.svg";
import { RiContactsBookLine } from "react-icons/ri";
import { useSpecificQuestions } from "../../../helpers/useSpecificQuestions";
import { CustomContext } from "../../../context/CustomContext";

const Steps = ({ researchId, cr, setCompleted, allSteps }) => {
  // const { data, isLoading } = useFormsInfo(cr);
  // const { data, isLoading } = useSpecificQuestions(researchId);

  const { data: researchsData, refetch } = useResearch(researchId);

  const [initalDate, setInitialDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [locale, setLocale] = useState(ptBR);

  const { systemLanguage } = useContext(CustomContext);

  const from = researchsData ? researchsData.period.from : null;
  const to = researchsData ? researchsData.period.to : null;

  useEffect(() => {
    if (systemLanguage === "en-US") {
      setLocale(enUS);
    } else if (systemLanguage === "es") {
      setLocale(es);
    } else {
      setLocale(ptBR);
    }
  }, [systemLanguage]);

  useEffect(() => {
    if (researchsData && researchsData.research.application_date) {
      const initial = format(parseISO(from), "dd", {
        locale: locale,
      });

      const end = format(parseISO(to), "P", {
        locale: locale,
      });

      setInitialDate(initial);
      setEndDate(end);
    }
  }, [from, to, locale]);

  useEffect(() => {
    refetch();
  }, [allSteps]);

  useEffect(() => {
    if (
      setCompleted &&
      researchsData &&
      !!researchsData.employees_sheet &&
      !!researchsData.initial_date
      // && !!researchsData.customised
      // && !!data.cultureBrief.allRequiredAnswered
    ) {
      setCompleted(true);
    }
  }, [researchsData]); // caso seja adicionado a pergunta personalizada, adicionar `data`

  return true ? ( //!isLoading
    <Grid templateColumns="repeat(5, 1fr)" columnGap={4} rowGap={0} h="148px">
      {researchsData === undefined ? (
        <></>
      ) : true ? ( //!data.err
        <>
          <GridItem
            w="124px"
            colSpan={1}
            h="100%"
            display={"flex"}
            flexDir="column"
            justifyContent="center"
            alignItems={"center"}
            gap="10px"
          >
            {researchsData.employees_sheet ? (
              <Image src={iconReady} alt="finalizado" />
            ) : (
              <Image src={iconPendency} alt="pendente" />
            )}

            <Text
              fontSize={"14px"}
              lineHeight="20px"
              textAlign={"center"}
              color="#292B30"
              fontWeight="500"
            >
              <Translator path={"survey.infoCard.employee"} />
            </Text>
          </GridItem>

          {/* <GridItem
            w="124px"
            colSpan={1}
            h="100%"
            display={"flex"}
            flexDir="column"
            justifyContent="center"
            alignItems={"center"}
            gap="10px"
          >
            {researchsData.customised ? (
              <Image src={iconReady} alt="finalizado" />
            ) : (
              <Image src={iconPendency} alt="pendente" />
            )}

            <Text
              fontSize={"14px"}
              lineHeight="20px"
              textAlign={"center"}
              color="#292B30"
              fontWeight="500"
            >
              Personalização da Pesquisa de Clima
            </Text>
          </GridItem> */}

          {/* <GridItem
            w="124px"
            colSpan={1}
            h="100%"
            display={"flex"}
            flexDir="column"
            justifyContent="center"
            alignItems={"center"}
            gap="10px"
          >
            {!data.err && researchsData.customised ? (
              <Image src={iconReady} alt="finalizado" />
            ) : (
              <Image src={iconPendency} alt="pendente" />
            )}

            <Text
              fontSize={"14px"}
              lineHeight="20px"
              textAlign={"center"}
              color="#292B30"
              fontWeight="500"
            >
              Cadastro da Pergunta
            </Text>
          </GridItem> */}

          <GridItem
            w="124px"
            colSpan={1}
            h="100%"
            display={"flex"}
            flexDir="column"
            justifyContent="center"
            alignItems={"center"}
            gap="10px"
          >
            {researchsData.initial_date ? (
              <Image src={iconReady} alt="finalizado" />
            ) : (
              <Image src={iconPendency} alt="pendente" />
            )}

            <Text
              fontSize={"14px"}
              lineHeight="20px"
              textAlign={"center"}
              color="#292B30"
              fontWeight="500"
            >
              <StrongTranslator path={"survey.infoCard.date"} />
            </Text>
          </GridItem>

          <Divider as={GridItem} m={0} h="7px" color="#CCCCCC" colSpan={5} />
          <GridItem
            w="100%"
            h="20px"
            colSpan={5}
            justifyContent="space-between"
            display="flex"
            mb="20px"
          >
            <Text fontSize="14px" color="##292B30">
              <Translator path={"survey.infoCard.link.title"} />:
              {researchsData.link !== null &&
              researchsData.link !== "" &&
              researchsData.link !== "Test URL" &&
              researchsData.link !== "string" ? (
                <Text
                  as="span"
                  color="#013280"
                  fontSize="14px"
                  fontWeight="600"
                  ml="5px"
                >
                  {" "}
                  <a
                    href={`${window.location.protocol}//pesquisa.jungle.greatpeople.app.br/${researchsData.link}`}
                    target="_blank"
                    rel="noreferrer"
                  >{`pesquisa.jungle.greatpeople.app.br/${researchsData.link}`}</a>
                </Text>
              ) : (
                <Text
                  as="span"
                  color="292B30"
                  fontSize="14px"
                  fontWeight="600"
                  ml="5px"
                >
                  <Translator path={"survey.infoCard.link.unavailable"} />
                </Text>
              )}
            </Text>
            <Text fontSize="14px" color="##292B30">
              <Translator path={"survey.infoCard.link.cycle.title"} />:
              {researchsData.initial_date ? (
                <>
                  <Text as="span" fontSize="14px" fontWeight="600" mx="5px">
                    <Translator path={"global.prepositionInitialDate"} />{" "}
                    {initalDate}
                  </Text>
                  <Translator path={"global.prepositionBetweenDates"} />
                  <Text as="span" fontSize="14px" fontWeight="600" ml="5px">
                    {endDate}
                  </Text>
                </>
              ) : (
                <Text
                  as="span"
                  color="292B30"
                  fontSize="14px"
                  fontWeight="600"
                  ml="5px"
                >
                  <Translator path={"survey.infoCard.link.cycle.waiting"} />
                </Text>
              )}
            </Text>
          </GridItem>
        </>
      ) : (
        <></>
      )}
    </Grid>
  ) : (
    <Box w="100%" h="40vh" textAlign="center" pt="20px">
      <Spinner />
    </Box>
  );
};

export default Steps;
