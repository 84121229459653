import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Translator from "../components/i18n/Translator";
import StrongTranslator from "../components/i18n/StrongTranslator";
import { Container, Heading, Text, Box, Spinner } from "@chakra-ui/react";

import { researchApi } from "../../services/api-research";
import CompanyInput from "../components/company-input";
import SuccessModal from "../components/company-input/SuccessModal";

const Layout = styled.div``;

const FirstLogin = ({ userData }) => {
  const companyRegister = userData?.company?.id;
  const [companyData, setCompanyData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [successCompany, setSuccessCompany] = useState(false);
  const [successUser, setSuccessUser] = useState(false);
  const baseAppUrl =
    window["runConfig"].REACT_APP_JUNGLE_BASE_URL || "http://auth.domain.tld";

  const homeRedirect = (companyRegister) => {
    window.location.replace(`${baseAppUrl}/researches?cr=${companyRegister}`);
  };

  const handleCloseSuccess = () => {
    setSuccessCompany(false);
    setSuccessUser(false);

    homeRedirect(companyData.id);
  };

  const handleClose = () => {
    setSuccessCompany(false);
    setSuccessUser(false);

    window.location.replace(`${baseAppUrl}/login`);
  };

  useEffect(() => {
    async function updateUserData() {
      try {
        delete userData.company;

        await researchApi.put(`/users/${userData.id}`, {
          ...userData,
          firstAccess: false,
        });

        setSuccessUser(true);
      } catch (error) {
        setSuccessUser(false);
        console.error("Error ao atualizar info da empresa", error);
      }
    }

    if (successCompany) {
      updateUserData();
    }
  }, [successCompany, userData]);

  useEffect(() => {
    async function getCompany() {
      try {
        setIsLoading(true);
        const { data } = await researchApi.get(`/companies/${companyRegister}`);
        console.log("data", data);

        setCompanyData(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error ao recuperar info da empresa", error);
      }
    }

    if (companyRegister) {
      getCompany();
    }
  }, [companyRegister]);

  return (
    <Layout>
      {isLoading === true ? (
        <Box w="100%" textAlign="center" h="30vh" pt="20px">
          <Spinner />
        </Box>
      ) : (
        <Container
          minH={"100vh"}
          maxH={"100vh"}
          maxW="container.xl"
          paddingTop="60px"
          position="relative"
        >
          <Heading as="h2" fontSize="28px">
            <Translator path={"company.title"} />
          </Heading>

          <Text fontSize="18px" mt="20px">
            <StrongTranslator path={"company.firstAccess.description"} />
          </Text>

          <CompanyInput
            companyData={companyData}
            setCompanyData={setCompanyData}
            setSuccess={setSuccessCompany}
            onClose={handleClose}
          />

          <SuccessModal
            isOpen={successCompany && successUser}
            onClose={handleCloseSuccess}
            firstAccess={true}
          />
        </Container>
      )}
    </Layout>
  );
};

export default FirstLogin;
