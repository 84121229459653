import { Stack, Flex, Box, Text } from "@chakra-ui/react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export function CircularChartDescription({firstColor, secondColor}) {
  return (
    <Stack
    spacing={"40px"}
    direction={"row"}
    pt="24px"
    justifyContent="center"
  >
    <Flex gap="8px" align="center">
      <Box w="8px" h="8px" bg={firstColor} rounded={"full"} />
      <Text fontSize={"13px"} lineHeight="15.26px">
        Visão da empresa
      </Text>
    </Flex>
    <Flex gap="8px" align="center">
      <Box w="8px" h="8px" bg={secondColor} rounded={"full"} />
      <Text fontSize={"13px"} lineHeight="15.26px">
        Visão da área
      </Text>
    </Flex>
  </Stack>
  )
}
const CircularChart = ({ options }) => {
  return (
    <Stack
      h="62px"
      maxH="62px"
      textAlign={"center"}
      className="circular-custom"
    >
      <CircularProgressbar
        value={options.value}
        minValue={0}
        maxValue={options.final}
        text={options.value}
        strokeWidth={10}
        styles={buildStyles({
          textSize: "font-size: 14px",
          width: "62px",
          height: "62px",
          pathColor: options.color,
          textColor: options.color,
          trailColor: "#E5E5E5",
        })}
      />
    </Stack>
  );
};

export default CircularChart;
