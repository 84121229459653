import { researchApi } from "../services/api-research";
import { useQuery } from "react-query";
import { queryClient } from "../services/queryCliente";

const fetchCertifiedResearchs = async (page) => {
  const response = await researchApi.get(
    `/research?&status=certified&sort_by=note&order=DESC&page=${page}`
  );

  return response.data;
};

export const useCertifiedResearchs = (page) => {
  return useQuery(
    `companyCertifiedResearchs-${page}`,
    () => fetchCertifiedResearchs(page),
    {
      // The query will not execute until the page exists
      enabled: !!page,
    },
    {
      staleTime: 30 * 1000,
      initialData: () => queryClient.getQueryData(`companyCertifiedResearchs-${page}`),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(`companyCertifiedResearchs-${page}`)?.dataUpdatedAt,
    }
  );
};
