import React, { useContext } from "react";
import styled from "styled-components";

import iconPendency from "../../assets/images/icon-pendency.svg";
import logoUm from "../../assets/images/1_estrela.png";
import logoDois from "../../assets/images/2_estrelas.png";
import logoTres from "../../assets/images/3_estrelas.png";
import logoUmGrey from "../../assets/images/1_estrela_grey.png";
import logoDoisGrey from "../../assets/images/2_estrelas_grey.png";
import logoTresGrey from "../../assets/images/3_estrelas_grey.png";
import iconDownload from "../../assets/images/icon-download.svg";

import iconMore from "../../assets/images/icon-more.svg";
import {
  Heading,
  Container,
  Grid,
  GridItem,
  Link,
  Flex,
  Box,
  Tag,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";

import SimpleSidebar from "../components/nav/Sidebar";
import { useEffect, useState } from "react";
import { asType } from "../../services/downloadCert";
import { researchApi } from "../../services/api-research";
import Translator from "../components/i18n/Translator";
import StrongTranslator from "../components/i18n/StrongTranslator";
import { useTranslation } from "react-i18next";
import { CustomContext } from "../../context/CustomContext";

const Layout = styled.div`
  margin-left: 32px;
  background: #f6f5fb;
`;

const Certificados = ({ validateRoute }) => {
  const { systemLanguage, userPermissions } = useContext(CustomContext);

  const params = new URLSearchParams(window.location.search);
  const { t } = useTranslation();

  let minBronzeNote = window["runConfig"].MIN_BRONZE_NOTE;
  let minSilverNote = window["runConfig"].MIN_SILVER_NOTE;
  let minGoldNote = window["runConfig"].MIN_GOLD_NOTE;

  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState(false);
  const [certificates, setCertificates] = useState(null);

  useEffect(() => {
    params.has("cr") ? setError(false) : setError(true);
    setIsFetching(true);

    const getCertificates = async () => {
      try {
        const { data } = await researchApi.get(
          `/research?id_company_register=${params.get(
            "cr"
          )}&status=certified&status=expired`
        );

        if (data.data && data.data.length !== 0) {
          const certs = await Promise.all(
            data.data.map(async (certificado) => {
              if (!!!certificado.expiration_date) {
                return;
              }

              const surveyEndDate = new Date(certificado.expiration_date);
              const formattedEndDate =
                surveyEndDate.toLocaleDateString(systemLanguage);

              return {
                ...certificado,
                surveyEndDate,
                formattedEndDate,
                isCertified: certificado.status === "certified",
              };
            })
          ).then((res) => res.filter((research) => !!research));

          const sortedCerts = certs.sort(
            (a, b) => b.surveyEndDate - a.surveyEndDate
          );

          setCertificates(sortedCerts);
          setIsFetching(false);
        } else {
          setError(true);
          return;
        }
      } catch (error) {
        setError(true);
        return;
      }
    };

    getCertificates();
  }, [systemLanguage]);

  const between = (value, min, max) => {
    return value >= min && value <= max;
  };

  const getCertificationLevel = (researchNote) => {
    researchNote = Number(researchNote);
    minBronzeNote = Number(minBronzeNote);
    minSilverNote = Number(minSilverNote);
    minGoldNote = Number(minGoldNote);

    let response;
    if (between(researchNote, minBronzeNote, minSilverNote - 1)) {
      response = 1;
    } else if (between(researchNote, minSilverNote, minGoldNote - 1)) {
      response = 2;
    } else if (between(researchNote, minGoldNote, 100)) {
      response = 3;
    }

    console.log("response", response);
    return response;
  };

  const handleCertificatedCompanyDownload = () => {
    fetch(
      `${process.env.PUBLIC_URL}/resources/${t("myCertifications.fileName")}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${t("myCertifications.fileName")}`);

        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  };

  console.log("certificates", certificates);

  if (isFetching) {
    console.log("loading...");
  } else {
    console.log("loaded!");
  }

  const today = new Date();

  const breadcumbs = [
    {
      nome: "Certificações",
      tipo: "link",
      link: "/certificates",
      icone: "",
      ativado: true,
    },
    {
      nome: "Ranking",
      tipo: "icone",
      link: "/search",
      icone: "pesquisar",
      ativado: false,
    },
  ];

  const handleDownloadCert = (research, type) => {
    let surveyEndDate = new Date(research.application_date_end);
    let dueSurveyDate = new Date(research.expiration_date);

    surveyEndDate = surveyEndDate.toLocaleDateString("pt-BR", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    dueSurveyDate = dueSurveyDate.toLocaleDateString("pt-BR", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    let formattedEndDate = dueSurveyDate;

    surveyEndDate = surveyEndDate.split(/\//g);
    surveyEndDate = `${surveyEndDate[2]}-${surveyEndDate[1]}-${surveyEndDate[0]}`;

    let certs = {
      surveyEndDate,
      formattedEndDate,
    };

    switch (type) {
      case "png":
        asType(research.note, "png");
        break;
      case "jpg":
        asType(research.note, "jpg");
        break;
      case "svg":
        asType(research.note, "svg");
        break;
      case "eps":
        asType(research.note, "eps");
        break;
      case "zip":
        asType(research.note, "zip");
        break;
      default:
        break;
    }
  };

  return !validateRoute(userPermissions, window.location.pathname) ? (
    <></>
  ) : (
    <Layout>
      <SimpleSidebar path="">
        <Container maxW="container.xl">
          <Heading as="h3" fontSize={"18px"} mt="30px">
            <Translator path={"myCertifications.title"} />
          </Heading>

          <Tabs variant="unstyled">
            <Flex justifyContent="space-between" mt="25px" h="27px" mb="35px">
              <TabList>
                <Tab
                  _selected={{
                    fontWeight: "bold",
                    borderBottom: "4px solid #013280",
                  }}
                >
                  <Translator path={"myCertifications.certifications.title"} />
                </Tab>
              </TabList>

              <Link
                fontSize="16px"
                fontWeight="600"
                w="auto"
                color="#013280"
                isExternal={true}
                variant="ghost"
                onClick={handleCertificatedCompanyDownload}
              >
                <Box display="flex" flex="1">
                  <img src={iconDownload} alt="icon download" />
                  &nbsp; <Translator path={"myCertifications.guide"} />
                </Box>
              </Link>
            </Flex>
            <TabPanels>
              <TabPanel>
                {error ? (
                  <Flex justify={"center"}>
                    <Translator path={"myCertifications.notFound"} />
                  </Flex>
                ) : isFetching || !!!certificates ? (
                  <Flex justify={"center"}>
                    <Translator path={"myCertifications.loading"} />
                  </Flex>
                ) : (
                  <Grid templateColumns="repeat(4, 1fr)" gap={6} w="100%">
                    {certificates.map((certificado, key) => (
                      <GridItem
                        w="100%"
                        h="256px"
                        bg="white"
                        border="1px"
                        borderColor="#CCCCCC"
                        borderRadius="12px"
                        position="relative"
                        overflow="hidden"
                        key={key}
                      >
                        <Box p={4} position="relative">
                          <Flex justify={"space-between"}>
                            <img src={iconPendency} alt="icon pendency" />
                            {certificado.surveyEndDate > today ? (
                              <Menu>
                                <MenuButton>
                                  <img src={iconMore} alt="more" />
                                </MenuButton>
                                <MenuList>
                                  <MenuItem
                                    onClick={() =>
                                      handleDownloadCert(
                                        certificates[key],
                                        "png"
                                      )
                                    }
                                  >
                                    <StrongTranslator
                                      path={
                                        "myCertifications.certifications.label.png"
                                      }
                                    />
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      handleDownloadCert(
                                        certificates[key],
                                        "jpg"
                                      )
                                    }
                                  >
                                    <StrongTranslator
                                      path={
                                        "myCertifications.certifications.label.jpg"
                                      }
                                    />
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      handleDownloadCert(
                                        certificates[key],
                                        "svg"
                                      )
                                    }
                                  >
                                    <StrongTranslator
                                      path={
                                        "myCertifications.certifications.label.svg"
                                      }
                                    />
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      handleDownloadCert(
                                        certificates[key],
                                        "eps"
                                      )
                                    }
                                  >
                                    <StrongTranslator
                                      path={
                                        "myCertifications.certifications.label.eps"
                                      }
                                    />
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      handleDownloadCert(
                                        certificates[key],
                                        "zip"
                                      )
                                    }
                                  >
                                    <StrongTranslator
                                      path={
                                        "myCertifications.certifications.label.all"
                                      }
                                    />
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            ) : null}
                          </Flex>
                          <img
                            src={
                              certificado.surveyEndDate > today
                                ? getCertificationLevel(certificado.note) === 1
                                  ? logoUm
                                  : getCertificationLevel(certificado.note) ===
                                    2
                                  ? logoDois
                                  : logoTres
                                : getCertificationLevel(certificado.note) === 1
                                ? logoUmGrey
                                : getCertificationLevel(certificado.note) === 2
                                ? logoDoisGrey
                                : logoTresGrey
                            }
                            alt="logo de certificação"
                            style={{
                              margin: "0 auto",
                              width: "150px",
                            }}
                          />

                          <Tag
                            fontWeight="600"
                            bg="#e9e8ef"
                            w="65px"
                            fontSize="12px"
                            position={"absolute"}
                            right={4}
                            bottom={1}
                          >
                            <Translator
                              path={"myCertifications.certifications.note"}
                            />{" "}
                            {certificado.note ? certificado.note : "-"}
                          </Tag>
                        </Box>
                        {certificado.surveyEndDate > today ? (
                          <Box
                            bg="#e9e8ef"
                            h="46px"
                            position="absolute"
                            bottom={0}
                            w="100%"
                            p={4}
                          >
                            <Flex justify="space-between" align="center">
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#002070",
                                  fontWeight: "500",
                                }}
                              >
                                <Translator
                                  path={
                                    "myCertifications.certifications.active"
                                  }
                                />
                              </span>
                              <span
                                style={{
                                  color: "#013280",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  lineHeight: "22px",
                                }}
                              >
                                {certificado.formattedEndDate}
                              </span>
                            </Flex>
                          </Box>
                        ) : (
                          <Box
                            bg="#fce6e6"
                            h="46px"
                            position="absolute"
                            bottom={0}
                            w="100%"
                            p={4}
                          >
                            <Flex justify="space-between" align="center">
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#013280",
                                  fontWeight: "500",
                                }}
                              >
                                <Translator
                                  path={
                                    "myCertifications.certifications.inactive"
                                  }
                                />
                              </span>
                              <span
                                style={{
                                  color: "#013280",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  lineHeight: "22px",
                                }}
                              >
                                {certificado.formattedEndDate}
                              </span>
                            </Flex>
                          </Box>
                        )}
                      </GridItem>
                    ))}
                  </Grid>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      </SimpleSidebar>
    </Layout>
  );
};

export default Certificados;
