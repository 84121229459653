import React from 'react';
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 14px; 

    width: 100%;

    vertical-align: middle;

    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 25px;
    padding-right: 25px;

    background: #FFD9C0;
    border-radius: 10px;

    height: 40px;
`

const Left = styled.div`
font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;

color: #013280;

`

const Right = styled.div`
font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;

color: #013280;

`

function Alert(props){
    return  <Container>
                <Left>{props.textLeft && props.textLeft}</Left>
                <Right>{props.textRight && props.textRight}</Right>
            </Container>
}

export default Alert;