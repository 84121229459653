/* eslint-disable no-unused-vars */
import { FC, useEffect, useState } from "react";

import * as Yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";

import { queryClient } from "../../../../services/queryCliente";

import iconBar from "../../../../assets/images/icon-bar.svg";
import iconRemove from "../../../../assets/images/icon-remove-red.svg";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  ModalFooter,
  Box,
  Flex,
  Tag,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
} from "@chakra-ui/react";
import { Input } from "../../../components/input";
import { Icon } from "../../../../style";
import { researchApi } from "../../../../services/api-research";

const schema = Yup.object().shape({
  customTheme: Yup.string().required("Campo obrigatório"),
  workalternative: Yup.string().max(
    30,
    "A opção deve ter no máximo 30 caracteres"
  ),
  customOption: Yup.string().max(
    150,
    "A opção deve ter no máximo 30 caracteres"
  ),
  positionalternative: Yup.string().max(
    30,
    "A opção deve ter no máximo 30 caracteres"
  ),
});

const defaultPositionOptions = [
  "Vendedor",
  "Operacional",
  "Gerente e Supervisor",
  "Administrativo",
  "Estagiário",
  "Executivo e Gestor Sênior",
  "Técnico",
];

const defaultWorkOptions = [
  "Vendas/Comercial",
  "Logística/Supply Chain",
  "Tecnologia da Informação",
  "Jurídico/Auditoria",
  "Produção/Operação",
  "Recursos Humanos",
  "Qualidade",
  "Administrativo/Financeiro",
  "Marketing",
  "Regionais/Agências",
  "Manutenção",
];

const SpecificQuestionsModal = ({ onClose, isOpen, researchId, info, onSuccess }) => {
  const {
    register,
    handleSubmit,
    watch,
    resetField,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [success, setSuccess] = useState(false);
  const [hasPreviousData, setPreviousData] = useState(false);
  const [workOptions, setworkOptions] = useState(defaultWorkOptions);
  const [positionOptions, setPositionOptions] = useState(
    defaultPositionOptions
  );
  const [customOptions, setCustomOptions] = useState([]);

  const updateDate = useMutation(
    async (model) => {
      let responses = []

      const responseFreeQuestion = await researchApi.post("/free-question", model);

      const responseUpdateDate = await researchApi.put(`/research/update-date/${researchId}`, {
        customised_at: new Date()
      });

      if (responseFreeQuestion.statusText === "Created" && responseUpdateDate.statusText === "OK") {
        setSuccess(true);
        onSuccess(true)
      }

      responses.push(responseFreeQuestion)
      responses.push(responseUpdateDate)

      return responses;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("specific");
      },
    }
  );

  const onSubmit = async (values) => {

    if (values.customTheme.length === 0) return;
    // const work = {
    //   type: "Desktop",
    //   alternatives: workOptions.join(";"),
    //   teme: "Áreas de trabalho",
    //   research: researchId,
    // };

    // const position = {
    //   type: "Positions",
    //   alternatives: positionOptions.join(";"),
    //   teme: "Cargo",
    //   research: researchId,
    // };

    // const CUSTOM eh o body da requisição
    const custom = {
      type: "Custom",
      title: values.customTheme,
      language: "pt_BR",
      research: researchId
    };
    // const model = [work, position, custom];
    // const model = [custom];

    try {
      
      const res = await updateDate.mutateAsync(custom);
      console.log("RESSS", res);
    } catch (err) {
      console.log(err);
    }
  };
  const handleAddOption = (type) => {
    if (watch("workalternative").length > 30) return;
    if (watch("workalternative").replace(/^\s+|\s+$/g, "").length === 0) return;

    if (workOptions.length < 11) {
      const newOptions = [...workOptions];
      newOptions.push(watch("workalternative"));
      setworkOptions(newOptions);
      resetField("workalternative");
    }
  };

  const handlePositionAlternative = () => {
    if (watch("positionalternative").length > 30) return;
    if (watch("positionalternative").replace(/^\s+|\s+$/g, "").length === 0)
      return;

    if (positionOptions.length < 11) {
      const newOptions = [...positionOptions];
      newOptions.push(watch("positionalternative"));
      setPositionOptions(newOptions);
      resetField("positionalternative");
    }
  };

  const handleCustomAlternative = () => { // customTheme

    console.log(`watch(customTheme): ${watch("customTheme")}`)

    if (watch("customTheme").length === 0) return;

  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
  };

  const removeOption = (ref) => {
    const newOptions = [...workOptions].filter((o, index) => index !== ref);
    setworkOptions(newOptions);
  };

  const removePositionOption = (ref) => {
    const newOptions = [...positionOptions].filter((o, index) => index !== ref);
    setPositionOptions(newOptions);
  };

  const removeCustomOption = (ref) => {
    const newOptions = [...customOptions].filter((o, index) => index !== ref);
    setCustomOptions(newOptions);
  };

  useEffect(() => {
    if (info instanceof Object && info.length > 1) {
      setPreviousData(true);
      const work = info.find((w) => w.type === "Desktop");
      const positions = info.find((w) => w.type === "Positions");
      const custom = info.find((w) => w.type === "Custom");
      setworkOptions(work.alternatives.split(";"));
      setPositionOptions(positions.alternatives.split(";"));

      // console.log('IF CUSTOM?')
      // console.log(custom)

      // if (custom) {
      //   console.log('VAI SETAR')
      //   setCustomOptions(custom.alternatives.split(";"));
      // }
    }
  }, [info]);

  return success ? (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent w="581px" p="0px" borderRadius="20px" overflow="hidden">
        <ModalBody p="60px">
          <Box display={"flex"} flexDir="column" alignItems="center" gap="24px">
            <Icon type="check-green" width="100px" height="100px" />

            <Heading fontSize="24px" fontWeight="700" textAlign="center">
              Pergunta customizada definida com sucesso
            </Heading>
          </Box>
        </ModalBody>
        <ModalFooter width="100%" p={0} height="69px">
          <Button
            width="100%"
            color="white"
            background="#013280"
            borderRadius={0}
            height="69px"
            onClick={handleClose}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: "30px",
          width: "1112px",
          padding: "10px",
          // height: "552px",
        }}
      >
        <ModalCloseButton />

        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <img src={iconBar} alt="icon" />
            <Heading fontSize="18px" fontWeight="600" mt="8px" mb="24px">
              Personalização da Pesquisa
            </Heading>

            <Box
              // h="365px"
              p={5}
              border="1px"
              borderColor="#CCCCCC"
              borderRadius="12px"
            >
              <Box>
                <Tag
                  bg="#e4e1e1"
                  color="#002070"
                  fontSize="16px"
                  fontWeight={"600"}
                  borderRadius="20px"
                  pl="15px"
                  pr="15px"
                >
                  Pergunta customizada
                </Tag>
              </Box>

              <Tabs variant="unstyled" mt="10px">
                <TabList>
                  {/* <Tab
                    fontSize="15px"
                    p={0}
                    _focus={{
                      border: "none",
                    }}
                    _selected={{
                      fontWeight: "bold",
                      borderBottom: "4px solid #013280",
                    }}
                  >
                    Sobre áreas de trabalho
                  </Tab>
                  <Tab
                    _focus={{
                      border: "none",
                    }}
                    fontSize="15px"
                    _selected={{
                      fontWeight: "bold",
                      borderBottom: "4px solid #013280",
                    }}
                  >
                    Sobre cargos
                  </Tab> */}
                  <Tab
                    _focus={{
                      border: "none",
                    }}
                    fontSize="15px"
                    _selected={{
                      fontWeight: "bold",
                      borderBottom: "4px solid #013280",
                    }}
                  >
                    Pergunta customizável
                  </Tab>
                </TabList>

                <TabPanels>
                  {/* <TabPanel pl={0}>
                    <Text fontSize={"14px"} lineHeight="20px">
                      Para a pergunta referente a <b>Áreas de trabalho </b>da
                      empresa você pode <b> adicionar até 11 alternativas.</b>
                    </Text>

                    <Flex mt="24px" h="78px" gap="22px">
                      <Box>
                        <Text
                          fontSize="12px"
                          color="#292B30"
                          fontWeight="600"
                          mb="5px"
                        >
                          Alternativas
                        </Text>
                        <Input
                          name="workalternative"
                          {...register("workalternative")}
                          error={errors.workalternative}
                          placeholder="Digite uma alternativa"
                          w="413px"
                          h="56px"
                          fontSize="14px"
                          borderColor="#B8CAE8"
                          borderRadius="10px"
                        />
                      </Box>

                      <Button
                        mt="auto"
                        alignSelf={"baseline"}
                        justifySelf="baseline"
                        onClick={() => handleAddOption("Áreas de trabalho")}
                        mb="0px"
                        w="265px"
                        h="56px"
                        fontWeight="700"
                        variant="outline"
                        color="#013280"
                        borderColor={"#013280"}
                        border="1px"
                        borderRadius="10px"
                      >
                        Adicionar alternativa
                      </Button>
                    </Flex>

                    <Text mt="24px" fontSize={"12px"}>
                      Você já selecionou{" "}
                      <b>{workOptions.length} alternativas</b>.
                    </Text>

                    <Flex w="100%" gap="8px" mt="16px" flexWrap="wrap">
                      {workOptions.map((list, index) => (
                        <Flex
                          key={index}
                          gap="10px"
                          alignItems="center"
                          borderRadius="20px"
                          px="12px"
                          py="2.5px"
                          bg="rgba(41, 43, 48, 0.1)"
                        >
                          <Text fontSize="12px" fontWeight="600">
                            {list}
                          </Text>

                          <Image
                            src={iconRemove}
                            alt="icon remove"
                            h="16px"
                            w="16px"
                            // as='button'
                            cursor="pointer"
                            onClick={() => removeOption(index)}
                          />
                        </Flex>
                      ))}
                    </Flex>
                  </TabPanel>
                  <TabPanel pl={0}>
                    <Text fontSize={"14px"} lineHeight="20px">
                      Para a pergunta referente as <b>Cargos</b> da empresa você
                      pode <b> adicionar até 11 alternativas.</b>
                    </Text>

                    <Flex mt="24px" h="78px" gap="22px">
                      <Box>
                        <Text
                          fontSize="12px"
                          color="#292B30"
                          fontWeight="600"
                          mb="5px"
                        >
                          Alternativas
                        </Text>
                        <Input
                          name="positionalternative"
                          {...register("positionalternative")}
                          placeholder="Digite uma alternativa"
                          error={errors.positionalternative}
                          w="413px"
                          h="56px"
                          fontSize="14px"
                          borderColor="#B8CAE8"
                          borderRadius="10px"
                        />
                      </Box>
                      <Button
                        mt="auto"
                        alignSelf={"baseline"}
                        justifySelf="baseline"
                        onClick={handlePositionAlternative}
                        mb="0px"
                        w="265px"
                        h="56px"
                        fontWeight="700"
                        variant="outline"
                        color="#013280"
                        borderColor={"#013280"}
                        border="1px"
                        borderRadius="10px"
                      >
                        Adicionar alternativa
                      </Button>
                    </Flex>
                    <Text mt="24px" fontSize={"12px"}>
                      Você já selecionou{" "}
                      <b>{positionOptions.length} alternativas</b>.
                    </Text>

                    <Flex w="100%" gap="8px" mt="16px" flexWrap="wrap">
                      {positionOptions.map((list, index) => (
                        <Flex
                          key={index}
                          gap="10px"
                          alignItems="center"
                          borderRadius="20px"
                          px="12px"
                          py="2.5px"
                          bg="rgba(41, 43, 48, 0.1)"
                        >
                          <Text fontSize="12px" fontWeight="600">
                            {list}
                          </Text>

                          <Image
                            src={iconRemove}
                            alt="icon remove"
                            h="16px"
                            w="16px"
                            cursor="pointer"
                            onClick={() => removePositionOption(index)}
                          />
                        </Flex>
                      ))}
                    </Flex>
                  </TabPanel> */}
                  <TabPanel pl={0}>
                    <Text fontSize={"14px"} lineHeight="20px" mb="8px">
                      Você pode inserir abaixo a sua{" "}
                      <b>pergunta livre</b>.
                    </Text>

                    <Flex>
                      <Text fontSize={"12px"} mt="24px" mr="20px">
                        Digite a <b>sua pergunta</b>
                      </Text>
                      <Input
                        mt="8px"
                        {...register("customTheme")}
                        placeholder="Ex: Como a sua empresa pode te ajudar a cuidar da sua saúde mental?"
                        w="800px"
                        h="56px"
                        fontSize="14px"
                        borderColor="#B8CAE8"
                        borderRadius="10px"
                      />
                    </Flex>

                    {/* <Flex mt="24px" h="78px" gap="22px">
                      <Box>
                        <Text
                          fontSize="12px"
                          color="#292B30"
                          fontWeight="600"
                          mb="5px"
                        >
                          Alternativas
                        </Text>

                        <Input
                          name="customOption"
                          {...register("customOption")}
                          error={errors.customOption}
                          placeholder="Digite uma alternativa"
                          w="413px"
                          h="56px"
                          fontSize="14px"
                          borderColor="#B8CAE8"
                          borderRadius="10px"
                        />
                      </Box>

                      <Button
                        mt="auto"
                        w="265px"
                        h="56px"
                        variant="outline"
                        color="#013280"
                        borderColor={"#013280"}
                        border="1px"
                        borderRadius="10px"
                        onClick={handleCustomAlternative}
                      >
                        Adicionar alternativa
                      </Button>
                    </Flex>
                    <Text mt="24px" fontSize={"12px"}>
                      Você já selecionou{" "}
                      <b>{customOptions.length} alternativas</b>.
                    </Text> */}

                    <Flex w="100%" gap="8px" mt="16px" flexWrap="wrap">
                      {customOptions.map((list, index) => (
                        <Flex
                          key={index}
                          gap="10px"
                          alignItems="center"
                          borderRadius="20px"
                          px="12px"
                          py="2.5px"
                          bg="rgba(41, 43, 48, 0.1)"
                        >
                          <Text fontSize="12px" fontWeight="600">
                            {list}
                          </Text>

                          <Image
                            src={iconRemove}
                            alt="icon remove"
                            h="16px"
                            w="16px"
                            cursor="pointer"
                            onClick={() => removeCustomOption(index)}
                          />
                        </Flex>
                      ))}
                    </Flex>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>

            <Flex h="92px" w="100%" justifyContent={"space-between"} mt="40px">
              <Button
                w="265px"
                h="50px"
                variant="outline"
                colorScheme="blue"
                borderRadius="10px"
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                bg="#013280"
                w="265px"
                color="#ffffff"
                borderRadius="10px"
                h="50px"
                // onClick={handleCustomAlternative}
              >
                Salvar
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SpecificQuestionsModal;
