const RoutesPath = [
  {
    paths: ['/registration/company', '/registration'],
    roles: ['COMPANY_EDIT']
  },
  {
    paths: ['/researches/certification'],
    roles: ['SURVEY_EDIT']
  },
  {
    paths: ['/researches'],
    roles: ['SURVEY_VIEW']
  },
  {
    paths: ['/certificates'],
    roles: ['CERTIFICATIONS_VIEW']
  },
  {
    paths: ['/results'],
    roles: ['RESULTS_VIEW']
  },
  {
    paths: ['/results/resume'],
    roles: ['SURVEY_RESUME_VIEW']
  },
  {
    paths: ['/collaborators'],
    roles: ['EMPLOYEE_VIEW']
  }
];

export default RoutesPath;
