import {
  Button,
  Checkbox,
  Grid,
  GridItem,
  Icon,
  Input,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { RiInformationFill } from "react-icons/ri";
import * as yup from "yup";
import InputMask from "react-input-mask";
import Translator from "../../components/i18n/Translator";
import { researchApi } from "../../../services/api-research";
import { useTranslation } from "react-i18next";
import FileInput from "./FileInput";

const CompanyInput = ({ companyData, setCompanyData, setSuccess, onClose }) => {
  const [address, setAddress] = useState(null);
  const [fantasyName, setFantasyName] = useState(null);
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [registryCode, setRegistryCode] = useState(null);
  const [responsibleEmployee, setResponsibleEmployee] = useState(null);
  const [rivalRegistryCode, setRivalRegistryCode] = useState(null);
  const [sameRegistryCode, setSameRegistryCode] = useState(false);
  const [logoKey, setLogoKey] = useState(null);

  const toast = useToast();
  const { t } = useTranslation();

  const updateInFormSchema = yup.object().shape({
    name: yup
      .string()
      .transform((_, val) => (val.length === 0 ? name : val))
      .required(<Translator path={"company.form.name.error.required"} />),
    address: yup
      .string()
      .transform((_, val) => (val.length === 0 ? address : val))
      .required(<Translator path={"company.form.address.error.required"} />),
    fantasyName: yup
      .string()
      .transform((_, val) => (val.length === 0 ? fantasyName : val))
      .nullable(true),
    registryCode: yup
      .string()
      .transform((_, val) => (val.length === 0 ? registryCode : val)),
    responsibleEmployee: yup
      .string()
      .transform((_, val) => (val.length === 0 ? responsibleEmployee : val))
      .required(
        <Translator path={"company.form.responsible.error.required"} />
      ),
    phone: yup
      .string()
      .transform((_, val) => (val.length === 0 ? null : val))
      .nullable(true)
      .matches(
        /((\d{2}) (\d{5})-(\d{4}))|(__ _____-____)/,
        <Translator path={"company.form.phone.error.valid"} />
      ),
    rivalRegistryCode: yup
      .string()
      .transform((_, val) =>
        sameRegistryCode
          ? rivalRegistryCode.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              "$1.$2.$3/$4-$5"
            )
          : val.length === 0
          ? null
          : val
      )
      .nullable(true)
      .matches(
        /^(\d{2}).(\d{3}).(\d{3})\/(\d{4})-(\d{2})|(__.___.___\/____-__)/,
        <Translator path={"company.form.registryCode.error.valid"} />
      ),
  });

  useEffect(() => {
    setAddress(companyData.address);
    setFantasyName(companyData.fantasyName);
    setName(companyData.name);
    setPhone(companyData.phone);
    setRegistryCode(companyData.registryCode);
    setResponsibleEmployee(companyData.responsibleEmployee);
    setRivalRegistryCode(companyData.rivalRegistryCode);
    setLogoKey(companyData.logoKey);

    if (companyData.rivalRegistryCode === companyData.registryCode) {
      setSameRegistryCode(true);
    } else {
      setSameRegistryCode(false);
    }
  }, [companyData]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(updateInFormSchema),
  });

  const handleClose = () => {
    onClose();
  };

  const onValid = async (updatedData) => {
    updatedData.rivalRegistryCode =
      updatedData?.rivalRegistryCode?.replace(/\D/g, "") || "";
    updatedData.phone = updatedData?.phone?.replace(/\D/g, "") || "";

    await researchApi
      .put(`/companies/${companyData.id}`, {
        ...updatedData,
        logoKey: logoKey,
      })
      .then(({ data }) => {
        setCompanyData(data);

        setSuccess(true);
      })
      .catch((error) => {
        console.error("Error ao atualizar informações da empresa", error);
        setSuccess(false);

        toast({
          title: <Translator path={"company.form.error.title"} />,
          description: <Translator path={"global.error.tryAgain"} />,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const onInvalid = (errors) => {
    for (const error in errors) {
      toast({
        title: <Translator path={"global.form.error"} />,
        description: errors[error].message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleInput = (event) => {
    const targetName =
      event.target.id.charAt(0).toUpperCase() + event.target.id.slice(1);

    const targetValue = event.target.value;

    eval(`set${targetName}('${targetValue}')`);
  };

  const handleCheckbox = (event) => {
    if (event.target.checked) {
      setRivalRegistryCode(
        companyData.registryCode.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        )
      );
      setSameRegistryCode(true);
    } else {
      setRivalRegistryCode("");
      setSameRegistryCode(false);
    }
  };

  return (
    <>
      <Grid
        templateRows="repeat(3, 1fr)"
        templateColumns="repeat(5, 1fr)"
        mt="25px"
      >
        <GridItem rowSpan={3} colSpan={1} justifySelf="flex-start">
          <FileInput
            companyData={companyData}
            setLogoKey={setLogoKey}
            logoKey={logoKey}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <Grid templateColumns="repeat(3, 1fr)" gap={5}>
            <GridItem w="305px" marginLeft="-30px">
              <Text fontSize="12px">
                <Translator path={"company.form.registryCode.buyer.title"} />
              </Text>
              <Input
                id="registryCode"
                isInvalid={errors.registryCode}
                errorBorderColor="crimson"
                {...register("registryCode", { required: true })}
                label={
                  <Translator path={"company.form.registryCode.buyer.title"} />
                }
                placeholder={t("company.form.registryCode.buyer.title")}
                border="1px"
                borderStyle="solid"
                borderColor="#B8CAE8"
                borderRadius="10px"
                mt="8px"
                h="56px"
                as={InputMask}
                mask="99.999.999/9999-99"
                value={registryCode || ""}
                disabled={true}
                backgroundColor="#5C6066"
                textColor="#E5E5E5"
                onChange={handleInput}
              />
            </GridItem>

            <GridItem colSpan={1}>
              <Text fontSize="12px">
                <Translator path={"company.form.registryCode.rival.title"} />
              </Text>
              <Input
                id="rivalRegistryCode"
                placeholder={t("global.messages.type")}
                label={
                  <Translator path={"company.form.registryCode.rival.title"} />
                }
                isInvalid={errors.rivalRegistryCode}
                errorBorderColor="crimson"
                {...register("rivalRegistryCode", { required: true })}
                border="1px"
                borderStyle="solid"
                borderColor="#B8CAE8"
                borderRadius="10px"
                w="305px"
                h="56px"
                mt="8px"
                as={InputMask}
                mask="99.999.999/9999-99"
                value={rivalRegistryCode || ""}
                onChange={handleInput}
                disabled={sameRegistryCode}
              />
            </GridItem>

            <GridItem colSpan={1}>
              <Checkbox
                mt="65px"
                width="207px"
                height="16px"
                fontWeight="400"
                fontSize="14px"
                lineHeight="16px"
                display="flex"
                alignItems="center"
                whiteSpace="nowrap"
                onChange={handleCheckbox}
                isChecked={sameRegistryCode}
                colorScheme="white"
                borderColor={sameRegistryCode ? "#013280" : ""}
                icon={
                  <Icon viewBox="0 0 200 200" backgroundColor="#013280"></Icon>
                }
              >
                <Translator path={"company.form.registryCode.same"} />
              </Checkbox>
            </GridItem>
          </Grid>
        </GridItem>

        <GridItem colSpan={4} mt="4px">
          <Grid templateColumns="repeat(3, 1fr)" gap={5}>
            <GridItem colSpan={1} ml="-30px">
              <Text fontSize="12px">
                <Translator path={"company.form.name.title"} />*
              </Text>
              <Input
                placeholder={t("company.form.name.helper")}
                label={<Translator path={"company.form.name.title"} />}
                type="string"
                id="name"
                isInvalid={errors.name}
                errorBorderColor="crimson"
                {...register("name", { required: true })}
                border="1px"
                borderStyle="solid"
                borderColor="#B8CAE8"
                borderRadius="10px"
                w="413px"
                h="56px"
                mt="8px"
                value={name || ""}
                onChange={handleInput}
              />
            </GridItem>

            <GridItem colSpan={1}>
              <Text fontSize="12px">
                <Translator path={"company.form.fantasyName.title"} />
              </Text>
              <Input
                id="fantasyName"
                isInvalid={errors.fantasyName}
                errorBorderColor="crimson"
                {...register("fantasyName", { required: true })}
                placeholder={t("company.form.fantasyName.helper")}
                label={<Translator path={"company.form.fantasyName.title"} />}
                type="string"
                border="1px"
                borderStyle="solid"
                borderColor="#B8CAE8"
                borderRadius="10px"
                w="413px"
                h="56px"
                mt="8px"
                value={fantasyName || ""}
                onChange={handleInput}
              />
            </GridItem>
          </Grid>
        </GridItem>

        <GridItem colSpan={4} mt="14px">
          <Grid templateColumns="repeat(3, 1fr)" gap={5}>
            <GridItem colSpan={1} marginLeft="-30px">
              <Text fontSize="12px">
                <Translator path={"company.form.address.title"} />*
              </Text>
              <Input
                id="address"
                isInvalid={errors.address}
                errorBorderColor="crimson"
                {...register("address", { required: true })}
                placeholder={t("global.messages.type")}
                label={<Translator path={"company.form.address.title"} />}
                type="string"
                border="1px"
                borderStyle="solid"
                borderColor="#B8CAE8"
                borderRadius="10px"
                w="413px"
                h="56px"
                mt="8px"
                value={address || ""}
                onChange={handleInput}
              />
            </GridItem>

            <GridItem colSpan={1}>
              <Text fontSize="12px">
                <Translator path={"company.form.phone.title"} />
              </Text>
              <Input
                id="phone"
                isInvalid={errors.phone}
                errorBorderColor="crimson"
                {...register("phone", { required: true })}
                label={<Translator path={"company.form.phone.title"} />}
                placeholder={t("global.messages.type")}
                type="string"
                border="1px"
                borderStyle="solid"
                borderColor="#B8CAE8"
                borderRadius="10px"
                w="305px"
                h="56px"
                mt="8px"
                value={phone || ""}
                as={InputMask}
                mask="99 99999-9999"
                onChange={handleInput}
              />
            </GridItem>

            <GridItem colSpan={1}>
              <Tooltip
                hasArrow
                arrowPadding={100}
                w="119px"
                label={<Translator path={"company.form.responsible.helper"} />}
                bg="#949494"
                borderRadius="10px"
                color="white"
                fontSize="12px"
                fontWeight="normal"
                p="8px"
                placement="top-end"
              >
                <div style={{ width: "100%", position: "relative" }}>
                  <RiInformationFill
                    fontSize="16px"
                    color="#013280"
                    style={{ position: "absolute", right: "0" }}
                  />

                  <Text fontSize="12px">
                    <Translator path={"company.form.responsible.title"} />*
                  </Text>

                  <Input
                    id="responsibleEmployee"
                    isInvalid={errors.responsibleEmployee}
                    errorBorderColor="crimson"
                    {...register("responsibleEmployee", { required: true })}
                    label={
                      <Translator path={"company.form.responsible.title"} />
                    }
                    placeholder={t("global.messages.type")}
                    type="string"
                    border="1px"
                    borderStyle="solid"
                    borderColor="#B8CAE8"
                    borderRadius="10px"
                    w="305px"
                    h="56px"
                    mt="8px"
                    value={responsibleEmployee || ""}
                    onChange={handleInput}
                  />
                </div>
              </Tooltip>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>

      <Grid
        h="56px"
        w="98%"
        templateColumns="repeat(2, 1fr)"
        gap={4}
        position="absolute"
        bottom="60px"
        m="0px"
      >
        <GridItem colSpan={1} justifySelf="flex-start">
          <Button
            colorScheme="blue"
            variant="outline"
            w="285px"
            h="100%"
            borderRadius="12px"
            onClick={handleClose}
          >
            <Translator path={"global.modal.cancel"} />
          </Button>
        </GridItem>
        <GridItem colSpan={1} justifySelf="flex-end">
          <Button
            onClick={handleSubmit(onValid, onInvalid)}
            bg="#949494"
            color="white"
            variant="outline"
            w="285px"
            h="100%"
            borderRadius="12px"
          >
            <Translator path={"global.modal.finish"} />
          </Button>
        </GridItem>
      </Grid>
    </>
  );
};

export default CompanyInput;
