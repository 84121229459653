import { FC, useEffect, useState } from "react";

import * as Yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { MdOutlineEmail } from "react-icons/md";
import apiForms from "../../../services/api-forms";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  ModalFooter,
  Box,
  Flex,
  Input,
  Spinner
} from "@chakra-ui/react";
import confImage from "../../../assets/images/confirmation.svg";
import { sassFalse } from "sass";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// const ConfModalData = {
//   email: string
// };

const schema = Yup.object().shape({
  email: Yup.string()
    .required("Campo obrigatório")
    .email("Insira um email válido"),
});

function ModalProps (props) {
  const [onClose, setOnClose] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [notifications, setNotifications] = useState(null);

  useEffect(() => {
    apiForms.get(`/api/notifications/`)
    .then(res => {
      let filtered = res.data.data.filter(item => item.active === true)
      if (filtered && filtered.length) {
        filtered.sort(function(a, b) {
          return a.position - b.position
        })
        setNotifications(filtered)
      }
    })
  },[])

  function setLSWithExpiry(key, value, ttl) {
    const now = new Date()
  
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  const handleClose = () => {
    setOnClose(true);
    setIsOpen(false);
    setLSWithExpiry('connect-notificated', true, 21600000);
  };

  return (notifications && 
    <Modal isOpen={isOpen} onClose={handleClose} size="2xl" >
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: "30px",
          minWidth: "360px",
          maxWidth: "900px",
          position: "relative",
          overflow: "hidden",
          minHeight: "400px",
          maxHeight: "600px",
        }}
      >
        <ModalHeader
          style={{
            padding: "5px 10px 0px 10px",
            marginBottom: "0px"
          }}
        >
          <Flex>
            <Box p="4" w="100%"
              style={{
                padding: "5px 10px 0px 10px",
                marginBottom: "0px"
              }}
            >
              <Text color="#C53030" fontSize="30px">
                Atenção!
              </Text>
              <Heading fontSize="20px" fontFamily="Raleway" lineHeight="34px">
                 Temos alguns avisos para você!
              </Heading>
            </Box>
          </Flex>
        </ModalHeader>
        <ModalBody
          style={{
            padding: "5px 10px 0px 10px",
            marginBottom: "0px",
            overflow: "auto"
          }}
        >
          <Box p="4" w="100%"
            style={{
              padding: "5px 10px 0px 10px",
              marginBottom: "0px"
            }}
          >
            {notifications ? notifications.map((notification, index) => {
                return <Text color="#000" fontSize="18px" padding="10px" margin="5px 0px 10px 0px" fontWeight="600">
                 <span style={{display: "inline-grid", alignContent: "center", justifyContent: "center", color:"#FFF", width: "30px", height: "30px", borderRadius:"10px",border:"1px solid #E53E3E", backgroundColor:"#E53E3E", padding:"5px", margin:"0px 10px 0px 0px"}}>{index + 1 + "º"}</span>
                 {notification.message}
                </Text>
              }
            ) : (
              <> 
              <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </>
            )}
          </Box>
        </ModalBody>
        <ModalFooter bottom={0} w="100%" h="80px" p={0}>
          <Button
            onClick={handleClose}
            colorScheme="blue"
            w="100%"
            fontSize="16px"
            h="80px"
            borderRadius={0}
            alignSelf="center"
          >
           Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalProps;
