import { researchApi } from "../services/api-research";

const dateFormatter = ({startCert, endCert}) => {
  const months = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez'
  ]

  startCert = startCert.split("-")

  const startMonth = months[parseInt(startCert[1])-1]

  endCert = endCert.split("/")

  const endMonth = months[parseInt(endCert[1])-1]

  return `${startMonth}/${startCert[0]} - ${endMonth}/${endCert[2]}`
}

export const asType = async (date, type) => {

  // const startCert = date.surveyEndDate
  // const endCert = date.formattedEndDate


  // const texts = dateFormatter({ startCert, endCert })

  try {
    const selo = await researchApi.get(`/labels/${type}?text=${date}`)

    const a = document.createElement('a')
    a.download = `selo${type === 'zip' ? "s" : ""}.${type}`
    a.href = type === 'zip' ? "data:application/zip;base64," + selo.data : selo.data
    a.click()    

    return selo.data
  } catch (error) {
    return new Error('erro')
  }
}

export const asReport = async (researchId) => {
  try {
    const response = await researchApi.get(`/research/${researchId}/export-report`, {
      responseType: 'blob',
    })

    const a = document.createElement('a')
    a.download = `${response.headers['file-name']}`
    const test =  window.URL.createObjectURL(response.data)
    a.href = test
    a.click()    
    window.URL.revokeObjectURL(test)

  } catch (error) {
    return new Error('erro')
  }
}