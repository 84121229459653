import React, { useState } from "react";
import {
  Box,
  Grid,
  Heading,
  Text,
  Textarea,
  Input,
  Flex,
  Button,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { researchApi } from "../../../services/api-research";
import { formatISO } from "date-fns";
import Translator from "../i18n/Translator";

const Help = ({ title = "", text = "", hasCloseButton }) => {
  const [close, setOnClose] = useState(true);
  const [helpCheckEmail, setHelpCheckEmail] = useState(undefined);
  const [helpEmail, setHelpEmail] = useState(undefined);
  const [emailValue, setEmailValue] = useState("");
  const [helpText, setHelpText] = useState(undefined);
  const [helpUrl, setHelpUrl] = useState(undefined);
  const [helpDate, setHelpDate] = useState(undefined);
  const [helpCountry, setHelpCountry] = useState(undefined);
  const [helpDevice, setHelpDevice] = useState(undefined);
  const [helpBrowser, setHelpBrowser] = useState(undefined);
  const [helpOS, setHelpOS] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const ipDataSecret = window["runConfig"].REACT_APP_IP_DATA_SECRET;

  const handleClose = async (value) => {
    if (value === false) {
      setHelpUrl(document.URL);
      setHelpDevice(navigator.userAgent);
      setHelpBrowser(navigator.appCodeName);
      setHelpOS(navigator.platform || navigator.userAgentData.platform);
      await getIp();
    } else {
      setHelpEmail(undefined);
      setHelpText(undefined);
      setHelpUrl(undefined);
      setHelpDate(undefined);
      setHelpCheckEmail(undefined);
    }

    setOnClose(value);
  };

  async function getIp() {
    let urlGetIP = `https://api.ipdata.co/?api-key=${ipDataSecret}`;

    await fetch(urlGetIP, { method: "GET" })
      .then((response) => {
        return response.json();
      }, "jsonp")
      .then((res) => {
        if (res.ip) {
          setHelpDate(res.time_zone.current_time);
          setHelpCountry(res.country_name);
        } else {
          console.log("Problem getting IPData " + JSON.stringify(res));
          this.setState({ clientIP: "NÃO IDENTIFICADO" });
        }
      })
      .catch((err) => {
        console.log("Erro ao recuperar informações", err);
        setHelpDate(formatISO(new Date()));
      });
  }

  const checkEmail = (email) => {
    let response;
    let test = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

    if (email === "") {
      response = undefined;
    } else if (test && test.length) {
      response = true;
    } else {
      response = false;
    }
    setHelpCheckEmail(response);
    return response;
  };

  const handleEmail = () => {
    const email = document.getElementById("helpEmail").value;
    setEmailValue(email);

    if (checkEmail(email)) {
      setHelpEmail(email);
    }
  };

  const handleText = () => {
    setHelpText(document.getElementById("helpText").value);
  };

  const handleSend = async () => {
    setIsLoading(true);
    handleEmail();

    if (!!!helpEmail || !!!helpCheckEmail) {
      toast({
        title: <Translator path={"global.form.email.required"} />,
        description: <Translator path={"helper.form.email.required"} />,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const helpJson = {
      date: helpDate ? helpDate : "",
      country: helpCountry ? helpCountry : "",
      sourcePage: helpUrl ? helpUrl : "",
      device: helpDevice ? helpDevice : "",
      browser: helpBrowser ? helpBrowser : "",
      os: helpOS ? helpOS : "",
      message: helpText ? helpText : "",
      email: helpEmail ? helpEmail : "",
    };

    await researchApi
      .post("/tickets", helpJson, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        toast({
          title: <Translator path={"helper.form.success.title"} />,
          description: <Translator path={"helper.form.success.description"} />,
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        setOnClose(true);
        setHelpEmail(undefined);
        setHelpText(undefined);
        setHelpUrl(undefined);
        setHelpDate(undefined);
        setHelpCheckEmail(undefined);
        setEmailValue(undefined);
      })
      .catch((error) => {
        console.log("Erro ao se comunicar com /tickets", error);

        toast({
          title: <Translator path={"global.error.something"} />,
          description: <Translator path={"global.error.pleaseTryAgain"} />,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });

    setIsLoading(false);
  };

  return (
    <>
      <Box
        position="fixed"
        bottom="50%"
        right="-80px"
        h="30px"
        alignContent="center"
        display="grid"
        backgroundColor="#3D9BE9"
        borderRadius="5px 5px 0px 0px"
        p="5px"
        filter="auto"
        transform="rotate(-90deg)"
        cursor="pointer"
        zIndex="9999999"
        onClick={() => handleClose(false)}
        opacity="0.7"
        _hover={{ opacity: "1" }}
        width="190px"
      >
        <Grid
          h="20px"
          borderRadius="12px"
          position="relative"
          w="120px"
          bottom={0}
          alignContent="center"
          justifyContent="center"
          templateColumns="16px 80px"
          gap="10px"
        >
          <Text>&#128512;</Text>
          <Text
            display="inline"
            as="p"
            fontSize="16px"
            lineHeight="24px"
            fontFamily="Raleway"
            fontWeight="bold"
            color="white"
            p="0px"
            alignText="center"
            whiteSpace={"nowrap"}
          >
            <Translator path={"helper.welcome"} />
          </Text>
        </Grid>
      </Box>

      {!close && (
        <Box
          position="fixed"
          bottom="30%"
          right="5px"
          h="345px"
          w="250px"
          display="grid"
          backgroundColor="white"
          border="1px solid #3D9BE9"
          borderRadius="5px"
          zIndex="9999999"
        >
          <Grid
            h="100%"
            borderRadius="12px"
            position="relative"
            w="100%"
            bottom={0}
            gap="10px"
          >
            <Heading
              backgroundColor="#3D9BE9"
              color="white"
              borderRadius="3px 3px 0px 0px"
              alignSelf="top"
              p="5px"
              textAlign="center"
              fontWeight="bold"
              h="30px"
              fontSize="16px"
            >
              <Translator path={"helper.description"} />
            </Heading>
            <Grid h="100%" w="100%" p="5px">
              {isLoading ? (
                <Grid h="100%" w="100%" pt="57%">
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="gray.500"
                    size="xl"
                    position="relative"
                    right="-100px"
                    top="-100%"
                    display="inline"
                  />
                </Grid>
              ) : (
                <>
                  <Grid h="100%" w="100%" p="5px">
                    <Text
                      display="inline"
                      as="p"
                      fontSize="12px"
                      fontFamily="Raleway"
                      fontWeight="bold"
                      color="gray"
                      textAlign="left"
                      p="0px"
                    >
                      <Translator path={"global.form.email.title"} />
                    </Text>
                    <Input
                      id="helpEmail"
                      w="100%"
                      h="30px"
                      fontSize="14px"
                      border={"1px solid #5C6066"}
                      borderRadius="5px"
                      resize="none"
                      p="2px"
                      onInput={() => handleEmail()}
                      type="email"
                      value={emailValue}
                    />
                    {helpCheckEmail === false && (
                      <Text color="red" fontSize="12px">
                        <Translator path={"helper.form.email.invalid"} />
                      </Text>
                    )}
                    {helpCheckEmail === true && (
                      <Text color="green" fontSize="12px">
                        <Translator path={"helper.form.email.valid"} />
                      </Text>
                    )}
                    {!!!helpEmail && helpCheckEmail === undefined && (
                      <Text color="red" fontSize="12px">
                        <Translator path={"global.form.email.required"} />
                      </Text>
                    )}
                  </Grid>
                  <Grid h="100%" w="100%" p="5px">
                    <Text
                      display="inline"
                      as="p"
                      fontSize="12px"
                      fontFamily="Raleway"
                      fontWeight="bold"
                      color="gray"
                      textAlign="left"
                      p="0px"
                    >
                      <Translator path={"helper.form.question.title"} />
                    </Text>
                    <Textarea
                      id="helpText"
                      w="100%"
                      h="70px"
                      fontSize="14px"
                      border="1px solid #5C6066"
                      focusBorderColor="#5C6066"
                      borderRadius="5px"
                      resize="none"
                      p="2px"
                      onInput={() => handleText()}
                      value={helpText}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Flex w="100%" p="5px" justifyContent="space-between">
              <Button
                type="submit"
                w="60px"
                h="30px"
                fontSize="14px"
                fontWeight="bold"
                color="#3D9BE9"
                border="2px solid #3D9BE9"
                borderRadius="5px"
                p="5px"
                onClick={() => handleClose(true)}
              >
                <Translator path={"global.modal.close"}/>
              </Button>
              <Button
                type="submit"
                w="60px"
                h="30px"
                fontSize="14px"
                fontWeight="bold"
                color="white"
                backgroundColor={
                  helpCheckEmail !== false && !!helpEmail ? "teal" : "grey"
                }
                borderRadius="5px"
                p="5px"
                onClick={() => handleSend()}
                cursor={
                  helpCheckEmail !== false && !!helpEmail
                    ? "pointer"
                    : "not-allowed"
                }
                isDisabled={
                  helpCheckEmail !== false && !!helpEmail ? false : true
                }
              >
                <Translator path={"global.modal.send"} />
              </Button>
            </Flex>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Help;
