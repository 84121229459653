import { Box, Stack, Text, Flex, Image, Tag, Button } from "@chakra-ui/react";

const ResumeCard = ({ title, description, rowType = false, children }) => {
  return (
    <Stack
      spacing="8px"
      p="24px"
      bg="white"
      borderRadius={"6px"}
      color="#292B30"
      direction={rowType ? "row" : "column"}
    >
      <Box w="20px" h="5px" bg="#40BF91" borderRadius={"5px"} />
      <Text fontWeight={600} whiteSpace="nowrap">
        {title}
      </Text>

      {description && (
        <Text fontSize={"14px"} lineHeight="20px">
          {description}
        </Text>
      )}

      {children}
    </Stack>
  );
};

const BottomCard = ({ title, description, buttonName, tagColor, children }) => {
  return (
    <Stack
      spacing="16px"
      p="28px 32px"
      bg="white"
      borderRadius={"8px"}
      color="#292B30"
    >
      <Tag
        w="fit-content"
        p="4px 8px"
        fontWeight={600}
        color="white"
        bg={tagColor}
      >
        {title}
      </Tag>

      {description && (
        <Text fontSize={"20px"} lineHeight="28px" fontWeight={700}>
          {description}
        </Text>
      )}
      <Flex pt="32px" justify={"flex-end"}>
        <Button
          w="162px"
          h="48px"
          variant={"primary"}
          fontSize="14px"
          fontWeight={600}
        >
          {buttonName}
        </Button>
      </Flex>
      {children}
    </Stack>
  );
};

export { ResumeCard, BottomCard };
