import {
  Table,
  Thead,
  Tbody,
  Button,
  Tr,
  Th,
  Td,
  TableContainer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import Translator from "../components/i18n/Translator";
import { researchApi } from "../../services/api-research";
import { Pagination } from "../components/pagination";
import getUrlParams from "../../services/getUrlParams";
import { useCertifiedResearchs } from "../../helpers/useCertifiedResearchs";
import { CustomContext } from "../../context/CustomContext";

const RankingTable = () => {
  const { document: doc } = getUrlParams("");
  const { systemLanguage } = useContext(CustomContext);

  const companyRegister = doc[0];

  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemCount, setItemCount] = useState();
  const [isLoading, setIsLoading] = useState();

  const { data } = useCertifiedResearchs(page);

  useEffect(() => {
    async function getResearches() {
      if (data === undefined) {
        setIsLoading(true);
        return;
      }

      const researchsCertified = await Promise.all(
        data.data.map(async (research) => {
          try {
            const company = await (
              await researchApi.get(
                `/companies/${research.id_company_register}`
              )
            ).data;

            return {
              ...research,
              company: company,
            };
          } catch (error) {
            console.error(
              `Erro ao recuperar empresa da pesquisa: ${research.id}`,
              error
            );
            return {
              ...research,
              company: [],
            };
          }
        })
      );

      setItemCount(data.meta.itemCount);
      setTableData(researchsCertified);
      setIsLoading(false);
    }

    getResearches();
  }, [page, itemCount, data]);

  return isLoading ? (
    <Box w="100%" h="40vh" textAlign="center" pt="20px">
      <Spinner />
    </Box>
  ) : (
    <TableContainer>
      <Table
        borderRadius={"2px"}
        variant="simple"
        color="#292B30"
        fontFamily="Raleway"
        border="1px"
        borderColor="#F4F4F4"
        bg="white"
      >
        <Thead
          h="46px"
          background="rgba(0, 0, 0, 0.1)"
          color="#292B30"
          boxShadow="0px 1px 4px rgba(0, 0, 0, 0.1)"
        >
          <Tr className="custom-th" fontWeight={700} borderColor="#F4F4F4">
            <Th w="0px" color="rgba(41, 43, 48, 1)" textAlign="center">
              <Translator path={"results.ranking.table.header.position"} />
            </Th>
            <Th>
              <Translator path={"results.ranking.table.header.company"} />
            </Th>
            <Th textAlign="center">
              <Translator path={"results.ranking.table.header.note"} />
            </Th>
            <Th>
              <Translator path={"results.ranking.table.header.expire"} />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {tableData.map((item, index) => (
            <TableRowCustom
              key={index}
              position={`${
                page === 1
                  ? index + 1
                  : `${(index === 9 ? page + 1 : page) - 1}${
                      index === 9 ? 0 : index + 1
                    }`
              }°`}
              companyName={item.company.name}
              note={item.note}
              expiration={new Date(item.expiration_date).toLocaleDateString(
                systemLanguage
              )}
              isCurrentUser={item.company.id === companyRegister}
            />
          ))}
        </Tbody>
      </Table>

      <Pagination
        totalCountOfRegisters={itemCount}
        currentPage={page}
        onPageChange={setPage}
      />
    </TableContainer>
  );
};

export default RankingTable;

const TableRowCustom = ({
  position,
  companyName,
  note,
  expiration,
  isCurrentUser,
}) => {
  return (
    <Tr fontSize="12px" maxH="46px" bgColor={isCurrentUser ? "blue.100" : ""}>
      <Td
        pl="32px"
        pr="0px"
        whiteSpace={"normal"}
        maxW="0px"
        color="rgba(41, 43, 48, 1)"
        fontWeight={600}
        fontSize="13px"
        lineHeight={"15px"}
        textDecor="underline"
      >
        {position}
      </Td>
      <Td textAlign={"left"}>{companyName}</Td>
      <Td textAlign="center">{note}</Td>
      <Td fontWeight={600}>{expiration}</Td>
    </Tr>
  );
};
