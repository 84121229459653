import {
  Box,
  Button,
  Container,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tag,
  Text,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Spinner,
  Image,
  useDisclosure,
  Collapse,
  Input,
} from "@chakra-ui/react";
import Card from "../components/card";

import styled from "styled-components";
import SimpleSidebar from "../components/nav/Sidebar";

import iconSearch from "../../assets/images/icon-search.svg";
import iconUserGreen from "../../assets/images/icon-user-green.svg";
import iconUserBlue from "../../assets/images/icon-user-blue.svg";
import iconUserOrange from "../../assets/images/icon-user-orange.svg";
import iconSelected from "../../assets/images/icon-arrow-down.svg";
import { useContext, useEffect, useState } from "react";
import ModalColaborator from "./modals/ModalColaborator";
import UploadSheetModal from "../pesquisas/certficacao/Modals/UploadSheet";
import getURLParams from "../../services/getUrlParams";
import { Pagination } from "../components/pagination";
import { useResearchEmployees } from "../../helpers/useResearchEmployees";
import iconDelete from "../../assets/images/icon-delete.svg";
import iconEdit from "../../assets/images/icon-edit.svg";
import ConfModal from "./modals/ConfirmationModal";
import { CSVLink } from "react-csv";
import Translator from "../components/i18n/Translator";
import { useTranslation } from "react-i18next";
import StrongTranslator from "../components/i18n/StrongTranslator";
import { CustomContext } from "../../context/CustomContext";

const Layout = styled.div`
  margin-left: 32px;
  background: #f6f5fb;
`;

const Colaboradores = ({ validateRoute }) => {
  const { document, key } = getURLParams("");
  const { t } = useTranslation();
  const companyRegister = document[0];
  const [modal, setModal] = useState(false);
  const [modalUpload, setModalUpload] = useState(false);
  const [modalColaborator, setModalColaborator] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [eligibleEmployees, setEligibleEmployees] = useState(0);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [inactiveEmployees, setInactiveEmployees] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemCount, setItemCount] = useState();
  const [isLoading, setIsLoading] = useState();
  const [filterOrderBy, setFilterOrderBy] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [searchLogin, setSearchLogin] = useState("");
  const [currentEmployee, setCurrentEmployee] = useState();
  const { userPermissions } = useContext(CustomContext);

  const { isOpen, onToggle } = useDisclosure();

  const { data, refetch } = useResearchEmployees(
    companyRegister,
    page,
    filterOrderBy,
    filterStatus,
    searchLogin
  );

  useEffect(() => {
    refetch();
    setPage(1);
  }, [filterOrderBy, filterStatus, searchLogin, refetch]);

  useEffect(() => {
    if (data === undefined) {
      setIsLoading(true);
      return;
    }

    if (data.employees.data.length === 0) {
      setPage(1);
    }

    setTotalEmployees(data?.company?.total_employees);
    setEligibleEmployees(data?.company?.elegible_employees);
    setInactiveEmployees(
      data?.company?.total_employees - data?.company?.elegible_employees
    );
    setTableData(data.employees.data);
    setItemCount(data.employees.meta.itemCount);
    setCsvData(data.csv);
    setIsLoading(false);
  }, [data]);

  const handleModal = () => {
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleUploadModal = () => {
    setModalUpload(true);
  };

  const handleCloseUploadModal = () => {
    setModalUpload(false);
  };

  const handleModalColaborator = () => {
    setModalColaborator(true);
  };

  const handleCloseModalColaborator = () => {
    setCurrentEmployee(null);
    setModalColaborator(false);
  };

  const handleCloseModalConfirmation = () => {
    setModalConfirmation(false);
    setCurrentEmployee(null);
    refetch();
  };

  const handleSuccessModalColaborator = () => {
    refetch();
    setCurrentEmployee(null);
    setModal(false);
  };

  const handleSuccessUpload = () => {
    refetch();
    setModal(false);
    setPage(1);
  };

  const handleUpdateEmployeesTotal = (value) => {
    refetch();
  };

  const handleEditEmployee = (employee) => {
    setCurrentEmployee(employee);
    setModalColaborator(true);
  };

  const handleDeleteEmployee = (employee) => {
    setCurrentEmployee(employee);
    setModalConfirmation(true);
  };

  const handleSearch = (e) => {
    if (e.key === "Enter" || e.target.value.length === 0) {
      setSearchLogin(e.target.value);
    }
  };

  return !validateRoute(userPermissions, window.location.pathname) ? (
    <></>
  ) : (
    <Layout>
      <SimpleSidebar path="">
        <Container maxW="container.xl" pt={4} pb={4}>
          <Card
            title={<Translator path={"employees.card.title"} />}
            text={<Translator path={"employees.card.description"} />}
            hasCloseButton
          />
          <Flex justify="space-between" h="40px" alignItems="center" mt="30px">
            <Heading as="h3" fontSize="18px" lineHeight="21px">
              <Translator path={"employees.title"} />
            </Heading>
            <Box ml="auto" mr="24px" display="flex" alignItems="center">
              <Box
                _hover={{
                  cursor: "pointer",
                }}
                onClick={onToggle}
                mr="10px"
              >
                <img src={iconSearch} alt="icon search" />
              </Box>
              <Collapse in={isOpen} animateOpacity>
                <Input
                  placeholder={t("employees.add.manual.form.login.title")}
                  onKeyUp={(e) => handleSearch(e)}
                />
              </Collapse>
            </Box>
            <Flex h="100%" gap="20px">
              <Button
                color="#013280"
                variant="outline"
                w="265px"
                h="100%"
                borderColor="#013280"
                borderRadius="10px"
              >
                <CSVLink filename={t("employees.title")} data={csvData}>
                  <Translator path={"employees.download"} />
                </CSVLink>
              </Button>

              <Button
                disabled={!userPermissions.includes("EMPLOYEE_EDIT")}
                onClick={handleModal}
                bg="#013280"
                color="white"
                variant={
                  !userPermissions.includes("EMPLOYEE_EDIT") ? null : "outline"
                }
                w="265px"
                h="100%"
                borderRadius="10px"
                border="none"
              >
                <Translator path={"employees.add.title"} />
              </Button>
            </Flex>
          </Flex>
          <Grid templateColumns="repeat(3, 1fr)" gap={4} mt="32px" mb="24px">
            <GridItem
              bg="white"
              border="1px"
              borderColor="#CCCCCC"
              colSpan={1}
              h="86px"
              borderRadius="12px"
              p="25px"
            >
              <Flex
                h="100%"
                alignItems={"center"}
                justify="space-between"
                gap="5px"
              >
                <Box>
                  <img src={iconUserBlue} alt="icon user" />
                </Box>
                <Text color="#011F3B" fontSize="13px" lineHeight="19px">
                  <StrongTranslator path={"employees.registered"} />
                </Text>

                <Tag
                  bg="#d1daf7"
                  color="#174BD6"
                  fontSize="18px"
                  fontWeight="700"
                  borderRadius="8px"
                  display="flex"
                  justifyContent="center"
                  w="76px"
                  h="40px"
                >
                  <Text>{totalEmployees}</Text>
                </Tag>
              </Flex>
            </GridItem>
            <GridItem
              bg="white"
              border="1px"
              borderColor="#CCCCCC"
              colSpan={1}
              h="86px"
              borderRadius="12px"
              p="25px"
            >
              <Flex
                h="100%"
                alignItems={"center"}
                justify="space-between"
                gap="5px"
              >
                <Box>
                  <img src={iconUserGreen} alt="icon user" />
                </Box>
                <Text color="#011F3B" fontSize="13px" lineHeight="19px">
                  <StrongTranslator path={"employees.eligibles"} />
                </Text>

                <Tag
                  bg="#d4f7fa"
                  color="#2CD9DD"
                  fontSize="18px"
                  fontWeight="700"
                  borderRadius="8px"
                  display="flex"
                  justifyContent="center"
                  w="76px"
                  h="40px"
                >
                  <Text>{eligibleEmployees}</Text>
                </Tag>
              </Flex>
            </GridItem>

            <GridItem
              bg="white"
              border="1px"
              borderColor="#CCCCCC"
              colSpan={1}
              h="86px"
              borderRadius="12px"
              p="25px"
            >
              <Flex
                h="100%"
                alignItems={"center"}
                justify="space-between"
                gap="5px"
              >
                <Box>
                  <img src={iconUserOrange} alt="icon user" />
                </Box>
                <Text color="#011F3B" fontSize="13px" lineHeight="19px">
                  <StrongTranslator path={"employees.inactive"} />
                </Text>

                <Tag
                  bg="#ffe1cc"
                  color="#FF6700"
                  fontSize="18px"
                  fontWeight="700"
                  borderRadius="8px"
                  display="flex"
                  justifyContent="center"
                  w="76px"
                  h="40px"
                >
                  <Text>{inactiveEmployees}</Text>
                </Tag>
              </Flex>
            </GridItem>
          </Grid>
          <Grid templateColumns="repeat(4, 1fr)" gap={6} h="62px" mb="26px">
            <GridItem w="100%" h="100%">
              <FormLabel mb="8px" fontSize="11px" fontFamily="Raleway">
                <Translator path={"employees.order.title"} />
              </FormLabel>
              <Select
                fontSize="14px"
                fontWeight="600"
                icon={<img src={iconSelected} />}
                placeholder={t("employees.order.default")}
                borderRadius="10px"
                borderColor="#B8CAE8"
                defaultValue="name"
                onChange={(e) => setFilterOrderBy(e.target.value)}
              >
                <option value="name">
                  {t("employees.add.manual.form.description.title")}
                </option>
                <option value="login">
                  {t("employees.add.manual.form.login.title")}
                </option>
                <option value="status">
                  {t("employees.add.manual.form.status.title")}
                </option>
              </Select>
            </GridItem>
            <GridItem w="100%" h="100%">
              <FormLabel mb="8px" fontSize="11px" fontFamily="Raleway">
                <Translator path={"employees.add.manual.form.status.title"} />
              </FormLabel>
              <Select
                fontSize="14px"
                fontWeight="600"
                icon={<img src={iconSelected} />}
                placeholder={t("employees.add.manual.form.status.description")}
                borderRadius="10px"
                borderColor="#B8CAE8"
                defaultValue=""
                onChange={(e) => setFilterStatus(e.target.value)}
              >
                <option value="Ativo">
                  {t("employees.add.manual.form.status.active")}
                </option>
                <option value="Inativo">
                  {t("employees.add.manual.form.status.inactive")}
                </option>
              </Select>
            </GridItem>
            <GridItem w="100%" h="100%"></GridItem>
            <GridItem w="100%" h="100%" display="flex" alignItems="end">
              <Text fontSize="14px" ml="auto">
                <StrongTranslator
                  path={"employees.view"}
                  length={tableData.length}
                  total={itemCount}
                />
              </Text>
            </GridItem>
          </Grid>
          {isLoading ? (
            <Box w="100%" h="40vh" textAlign="center" pt="20px">
              <Spinner />
            </Box>
          ) : (
            <TableContainer>
              <Table
                borderRadius={"2px"}
                variant="simple"
                color="#292B30"
                fontFamily="Raleway"
                border="1px"
                borderColor="#F4F4F4"
                bg="white"
              >
                <Thead
                  h="46px"
                  background="rgba(0, 0, 0, 0.1)"
                  color="#292B30"
                  boxShadow="0px 1px 4px rgba(0, 0, 0, 0.1)"
                >
                  <Tr>
                    <Th w="0px" color="#292B30" textAlign="center">
                      #
                    </Th>

                    <Th textAlign="left">
                      <Translator
                        path={"employees.add.manual.form.description.title"}
                      />
                    </Th>
                    <Th textAlign="left">
                      <Translator
                        path={"employees.add.manual.form.login.title"}
                      />
                    </Th>
                    <Th textAlign="left">
                      <Translator
                        path={"employees.add.manual.form.status.title"}
                      />
                    </Th>
                    <Th textAlign="center">
                      <Translator path={"employees.action.title"} />
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {tableData.map((employee, index) => (
                    <TableRowCustom
                      key={index}
                      position={`${
                        page === 1
                          ? `${
                              index + 1 !== 10
                                ? `0${index + 1}`
                                : `${index + 1}`
                            }`
                          : `${(index === 9 ? page + 1 : page) - 1}${
                              index === 9 ? 0 : index + 1
                            }`
                      }`}
                      employee={employee}
                      handleEditEmployee={handleEditEmployee}
                      handleDeleteEmployee={handleDeleteEmployee}
                    />
                  ))}
                </Tbody>
              </Table>

              <Pagination
                totalCountOfRegisters={itemCount}
                currentPage={page}
                onPageChange={setPage}
              />
            </TableContainer>
          )}
          <Modal isOpen={modal} onClose={handleCloseModal} size="xl">
            <ModalOverlay />
            <ModalContent
              borderRadius="20px"
              p="20px"
              w="600px"
              textAlign="center"
              h="248px"
            >
              <ModalHeader fontSize="18px">
                <Translator path={"employees.add.subtitle"} />
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Button
                  onClick={handleUploadModal}
                  bg="#013280"
                  mb="10px"
                  color="white"
                  w="453px"
                  borderRadius="10px"
                  h="56px"
                >
                  <Translator path={"employees.add.sheet"} />
                </Button>
                <Button
                  onClick={handleModalColaborator}
                  bg="#013280"
                  color="white"
                  w="453px"
                  borderRadius="10px"
                  h="56px"
                >
                  <Translator path={"employees.add.manual.title"} />
                </Button>
              </ModalBody>
            </ModalContent>
          </Modal>
          <UploadSheetModal
            researchId={data?.company?.id}
            isOpen={modalUpload}
            onClose={handleCloseUploadModal}
            // handleDownloadList={handleDownloadList}
            onSuccess={handleSuccessUpload}
            updateEmployees={handleUpdateEmployeesTotal}
          />
          <ModalColaborator
            isOpen={modalColaborator}
            onClose={handleCloseModalColaborator}
            onSuccess={handleSuccessModalColaborator}
            researchId={data?.company?.id}
            currentEmployee={currentEmployee}
            setCurrentEmployee={setCurrentEmployee}
          />
          <ConfModal
            isOpen={modalConfirmation}
            onClose={handleCloseModalConfirmation}
            currentEmployee={currentEmployee}
            setCurrentEmployee={setCurrentEmployee}
          />
        </Container>
      </SimpleSidebar>
    </Layout>
  );
};

export default Colaboradores;

const TableRowCustom = ({
  position,
  employee,
  handleEditEmployee,
  handleDeleteEmployee,
}) => {
  return (
    <Tr fontSize="12px" maxH="46px">
      <Td pr="0px">{position}</Td>
      <Td textAlign={"left"}>{employee.name}</Td>
      <Td textAlign="left">{employee.login}</Td>
      <Td>
        <Tag
          fontSize="13px"
          bg={employee.status === "Ativo" ? "#2CD9DD" : "#FF6700"}
          color="white"
          borderRadius={"6px"}
          fontWeight={600}
          py="4px"
          px="8px"
        >
          {employee.status === "Ativo" ? (
            <Translator path={"employees.add.manual.form.status.active"} />
          ) : (
            <Translator path={"employees.add.manual.form.status.inactive"} />
          )}
        </Tag>
      </Td>

      <Td textAlign={"center"}>
        <Button
          bg="transparent"
          variant="link"
          border="none"
          outline="none"
          _focus={{
            background: "transparent",
          }}
          onClick={() => handleEditEmployee(employee)}
        >
          <Image src={iconEdit} />
        </Button>
        <Button
          bg="transparent"
          variant="link"
          border="none"
          outline="none"
          _focus={{
            background: "transparent",
          }}
          onClick={() => handleDeleteEmployee(employee)}
        >
          <Image src={iconDelete} />
        </Button>
      </Td>
    </Tr>
  );
};
