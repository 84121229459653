import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import RoutesPath from './RoutesPath.js';
import isUserInRole from './helpers/user';
import { getCookie } from './app/common/cookieManager.js';

export function PrivateRoute({ component: Component, ...rest }) {
  function isAuthenticated() {
    const token = getCookie("credential");
    const company = getCookie("company");

    if (token && company) {
      return true
    }

    return false
  }

  function isValidRoute(permissions, path) {
    console.log(RoutesPath)
    let index = path.includes(':') ? path.indexOf(':') - 1 : path.length

    const accessedMenu = RoutesPath.filter(c => c.paths.includes(path.substr(0, index)))[0]
    if (!accessedMenu) return true
    if (!isUserInRole(permissions, ...accessedMenu.roles)) {
      return false
    }
    return true
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} validateRoute={isValidRoute} />
        ) : (
          <Redirect
            to={{
              pathname: '/login'
            }}
          />
        )
      }
    />
  )
}