/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import { Box, Flex, GridItem, Text, Button, Heading } from "@chakra-ui/react";
import { useResearch } from "../../../helpers/useResearch";
import { format, parseISO, formatDistance } from "date-fns";
import { ptBR, enUS, es } from "date-fns/locale";
import Translator from "../../components/i18n/Translator";
import InitialDateModal from "./Modals/InitialDateModal";
import iconBar from "../../../assets/images/icon-bar.svg";
import { CustomContext } from "../../../context/CustomContext";

const ResearchDate = ({ researchId, canEdit, onSuccess }) => {
  const { custom, systemLanguage } = useContext(CustomContext);

  const { data: researchsData, refetch } = useResearch(researchId);
  const [modal, setModal] = useState(false);

  const [initialDate, setInitialDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [daysUntilStart, setDaysUntilStart] = useState(null);
  const [canCustomize, setCanCustomize] = useState(false);
  const [locale, setLocale] = useState(ptBR);

  useEffect(() => {
    if (systemLanguage === "en-US") {
      setLocale(enUS);
    } else if (systemLanguage === "es") {
      setLocale(es);
    } else {
      setLocale(ptBR);
    }
  }, [systemLanguage]);

  useEffect(() => {
    if (researchsData.research.application_date) {
      const initial = format(
        parseISO(researchsData.period.from),
        "P",
        {
          locale: locale,
        }
      );

      const end = format(parseISO(researchsData.period.to), "P", {
        locale: locale,
      });

      const startDate = formatDistance(
        new Date(),
        new Date(parseISO(researchsData.research.application_date)),
        {
          locale: locale,
        }
      );
      setInitialDate(initial);
      setEndDate(end);
      if (new Date() > new Date(researchsData.period.to)) {
        setDaysUntilStart(null);
      } else {
        setDaysUntilStart(startDate);
      }
    }
  }, [
    researchsData.period.from,
    researchsData.period.to,
    researchsData,
    locale,
  ]);

  const handleModal = () => {
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  useEffect(() => {
    if (researchsData.employees_sheet === true) {
      setCanCustomize(true);
    }
  }, [researchsData, canEdit]);

  const handleOnSuccess = () => {
    refetch();
    onSuccess("date");
  };

  return (
    <GridItem
      h="100%"
      colSpan={1}
      bg="white"
      border="1px"
      borderColor="#CCCCCC"
      p="30px"
      borderRadius="12px"
    >
      <InitialDateModal
        data={researchsData}
        initialDate={initialDate}
        endDate={endDate}
        researchId={researchId}
        isOpen={modal}
        onClose={handleCloseModal}
        onSuccess={handleOnSuccess}
      />
      <Box borderRadius="10px">
        <Flex h="65px" justify="space-between">
          <Box h="100%">
            <img src={iconBar} alt="icon bar" />
            <Heading as="h4" fontSize="18px" pt="12px">
              <Translator path={"survey.config.date.title"} />
            </Heading>

            <Box>
              {!!!daysUntilStart && !!researchsData.period.to ? (
                <Text fontSize="14px" pt="12px" mb="10px">
                  <Translator path={"survey.config.date.closed"} />
                </Text>
              ) : (
                <Text fontSize="14px" pt="12px" mb="10px">
                  <Translator path={"survey.config.date.period"} />
                  {researchsData.initial_date ? (
                    <Text as="span" fontWeight="600" ml="5px">
                      {daysUntilStart}
                    </Text>
                  ) : (
                    <Text as="span" fontWeight="600" ml="5px">
                      -- <Translator path={"survey.config.date.day"} />{" "}
                    </Text>
                  )}
                </Text>
              )}
              <Flex gap="16px">
                <Box
                  border="1px"
                  borderColor="#40BF91"
                  borderRadius="12px"
                  w="234px"
                  h="83px"
                  p="16px"
                >
                  {researchsData.initial_date ? (
                    <Text fontWeight="700" color="#292B30" mb="2px">
                      {initialDate}
                    </Text>
                  ) : (
                    <Text fontWeight="700" color="#5C6066" mb="2px">
                      --/--/----
                    </Text>
                  )}

                  <Box
                    h="2px"
                    w="25px"
                    bg="red.100"
                    borderRadius="5px"
                    mb="5px"
                  />
                  <Text fontSize="14px" color="#5C6066">
                    <Translator path={"survey.config.date.start"} />
                  </Text>
                </Box>

                <Box
                  border="1px"
                  borderColor="#40BF91"
                  borderRadius="12px"
                  w="234px"
                  h="83px"
                  p="16px"
                >
                  {researchsData.research.application_date_end ? (
                    <Text fontWeight="700" color="#292B30" mb="2px">
                      {endDate}
                    </Text>
                  ) : (
                    <Text fontWeight="700" color="#5C6066" mb="2px">
                      --/--/----
                    </Text>
                  )}

                  <Box
                    h="2px"
                    w="25px"
                    bg="red.100"
                    borderRadius="5px"
                    mb="5px"
                  />
                  <Text fontSize="14px" color="#5C6066">
                    <Translator path={"survey.config.date.end"} />
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Box>

          <Button
            w="175px"
            variant="outline"
            color={!custom ? "#292B30" : "red.100"}
            border="1px"
            disabled={!custom}
            borderColor={!custom ? "#292B30" : "red.100"}
            borderRadius="12px"
            fontWeight="bold"
            onClick={handleModal}
          >
            {researchsData.initial_date ? (
              <Translator path={"global.modal.edit"} />
            ) : (
              <Translator path={"global.modal.config"} />
            )}
          </Button>
        </Flex>
      </Box>
    </GridItem>
  );
};

export default ResearchDate;
