export default function isUserInRole(roles) {
  if (arguments.length > 1) {
    for (var arg = 1; arg < arguments.length; ++arg) {
      var arr = arguments[arg];
      let found = roles.find(r => {
        return r === arr;
      })
      if (found) {
        return true;
      }
    }
  }

  return false;
};