/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import {
  Button,
  Grid,
  GridItem,
  Container,
  Flex,
  Text,
  Heading,
  Box,
  Spinner,
  Image,
  Input as ChakraInput,
  flexbox,
} from "@chakra-ui/react";

import styled from "styled-components";
import InputMask from "react-input-mask";
import { Icon, Radio } from "../../../style";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import apiForms from "../../../services/api-forms";
import { useDispatch } from "react-redux";
import getUrlParams from "../../../services/getUrlParams";

const SubDiv = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: #fff;
  margin: 0;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-top: 22px;
  padding-left: 22px;
  padding-bottom: ${(props) => props.paddingBottom};
  padding-right: 25.8px;
  position: relative;
`;

const Input = styled.input`
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  border: none;
  border: 1px solid #b8cae8;
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
  padding: 16px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

const Label = styled.label`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #292b30;
  margin-bottom: 8px;
`;

const DivGlobal = styled.div`
  background-color: #f5f5fb;
`;

function InfoOrganizacao(props) {
  let dispatch = useDispatch();

  function filterOptions(answerOptions, idQuestionOption) {
    const options = answerOptions.filter(
      (answerOption) => answerOption.idQuestionOption === idQuestionOption
    );
    return options;
  }
  const [imageUrl, setImageUrl] = useState("");
  const [imagesLoading, setImagesLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [generalQuestionsTrue, setGeneralQuestions] = useState({});
  const [answerOptions, setAnswerOptions] = useState({});
  const [responseData, setResponseData] = useState({});
  const [responseForms, setResponseForms] = useState({});
  let [answers, setAnswers] = useState([]);
  const [answersNumber, setAnswersNumber] = useState(0);
  const [requiredNumber, setRequiredNumber] = useState(0);
  const [requiredAnswered, setRequiredAnswered] = useState(0);
  const [canEdit, setCanEdit] = useState(null);
  const [document, setDocument] = useState(null);
  let getAnswers = [];

  function countQuestions() {
    let requiredAnsweredCounter = 0;

    answers
      .filter((ans) => ans.answered === true)
      .map((ansValue) =>
        generalQuestionsTrue.find(
          (generalQuestion) => ansValue.idAnswer === generalQuestion.idAnswer
        ).required === true
          ? requiredAnsweredCounter++
          : (requiredAnsweredCounter = requiredAnsweredCounter)
      );

    setAnswersNumber(answers.filter((ans) => ans.answered === true).length);
    setRequiredAnswered(requiredAnsweredCounter);
  }

  function sendAlert(help) {
    alert(help);
  }

  async function save(file, companyRegister, isLogo = false) {
    const AWS = require("aws-sdk");
    //TODO: ver se existe uma abordagem melhor

    AWS.config.update({
      accessKeyId: window["runConfig"].AWS_ACCESS_KEY_ID,
      secretAccessKey: window["runConfig"].AWS_SECRET_ACCESS_KEY,
    });

    const s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      // eslint-disable-next-line no-undef
      region: window["runConfig"].AWS_REGION,
    });

    const params = {
      // eslint-disable-next-line no-undef
      Bucket: `${window["runConfig"].AWS_S3_BUCKET}/CompanyPics/${companyRegister}`,
      Key: isLogo ? `Logo.${file.name.split(".").pop()}` : file.name,
      Body: file,
      ACL: "public-read",
    };

    return await s3.upload(params).promise();
  }

  async function setAware(event) {
    if (event.target.checked) {
      const current = new Date().toLocaleString();
      answers.find((answer) => answer.idAnswer === event.target.name).acceptConfidential = current
    } else {
      answers.find((answer) => answer.idAnswer === event.target.name).acceptConfidential = ''
    }
  }

  async function createAnswers(event) {
    if (answers.find((answer) => answer.idAnswer === event.target.name)) {

      if (event.target.type === "checkbox") {
        const { value, checked } = event.target;
        let arrayOrString = answers.find(
          (answer) => answer.idAnswer === event.target.name
        ).answer;

        if (
          !Array.isArray(arrayOrString) &&
          arrayOrString !== "" &&
          arrayOrString !== null
        ) {
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answer = answers
            .find((answer) => answer.idAnswer === event.target.name)
            .answer.split(",");
        } else if (
          arrayOrString === undefined ||
          arrayOrString === "" ||
          arrayOrString === null
        ) {
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answer = [];
        }

        if (checked) {
          answers
            .find((answer) => answer.idAnswer === event.target.name)
            .answer.push(value);
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answered = true;
          arrayOrString = answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answer;
        } else {
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answer = answers
            .find((answer) => answer.idAnswer === event.target.name)
            .answer.filter((element) => element !== value);
          arrayOrString = answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answer;
        }

        if (arrayOrString.length === 0) {
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answered = null;
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answered = false;
        }
      } else if (event.target.type === "file") {
        if (event.target.multiple) {
          const links = [];
          Object.keys(event.target.files).forEach(async (index) => {
            if (event.target.files[index].size > 5 * 1024 * 1024) {
              alert(
                `O arquivo ${event.target.files[index].name} não pode ser carregado por conta do tamanho. Diminua o arquivo e tente novamente. Limite de 5MB.`
              );
            } else {
              let link = await save(event.target.files[index], document);
              links.push(link.Location);
              answers.find(
                (answer) => answer.idAnswer === event.target.name
              ).answer = links;
              answers.find(
                (answer) => answer.idAnswer === event.target.name
              ).answered = true;
            }
            // setImagesUrl(links);
          });
        } else {
          if (event.target.files[0].size > 5 * 1024 * 1024) {
            alert(
              `O arquivo ${event.target.files[0].name} não pode ser carregado por conta do tamanho. Diminua o arquivo e tente novamente. Limite de 5MB.`
            );
          } else {
            setImageLoading(true);

            setTimeout(() => {
              setImageLoading(false);
            }, 1000);
            let link = await save(event.target.files[0], document, true);
            answers.find(
              (answer) => answer.idAnswer === event.target.name
            ).answer = link.Location;
            answers.find(
              (answer) => answer.idAnswer === event.target.name
            ).answered = true;

            setImageUrl(link.Location);
          }
        }
      } else {
        if (event.target.value === "") {
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answer = null;
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answered = false;
        } else {
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answer = event.target.value;
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answered = true;
        }
      }
    }

    countQuestions();
  }

  function sendInfo(url) {
      answers.filter((answer) => answer.answer !== null);
      answers.forEach((answer) => {
        if (Array.isArray(answer.answer)) {
          answer.answer = answer.answer.toString();
        }
      });
      apiForms.put("/api/answer/", answers, {
        headers: {
          "Content-Type": "application/json",
        },
        }).then(() => {
          window.location.replace(url)
        }        
      );
  }

  function checkConfidential(answers) {
    if (answers.isConfidential === true) {
      return <div style={{display:"inline-flex", alignItems:"center", gap: "5px"}}>
      <input
        border="none"
        cursor="pointer"
        type="checkbox"
        name={answers.idAnswer}
        disabled={canEdit === "false" ? true : false}
        defaultChecked={answers.acceptConfidential}
        onClick={(e) => setAware(e)}
      />
      <label>Eu estou de acordo em compartilhar essas informações.</label>
    </div>
    } 
  }

  useEffect(async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const formType = urlParams.get("form");
    const { document, key } = getUrlParams("not");
    setDocument(document);
    setCanEdit(key[0]);

    let mounted = true;
    setIsLoading(true);

    await apiForms
      .get(`/api/form-questions`)
      .then(async (resData) => {
        if (mounted) {
          setResponseData(
            resData.data.data.filter((data) => formType === data.type)[0]
          );
          apiForms
            .get(`/api/answer/create-and-get/${document}`)
            .then((res) => {
              if (mounted) {
                setResponseForms(
                 
                  res.data.data.filter(
                    (data) => formType === data[0].form
                  )[0][0]
                );
                
              }
            })
            .catch((err) => {
              console.error("Erro no getFormsData, problema: ", err);
            });
        }
      })
      .catch((err) => {
        console.error("Erro no getQuestions, problema: ", err);
      });

    await apiForms.get(`/api/question-option`).then((res) => {
      setAnswerOptions(res.data.data);
      dispatch({
        type: "FETCH_QUESTIONS_OPTIONS",
        questionsOptions: answerOptions,
      });
    });

    await apiForms
      .get(`/api/answer?idCompanyRegister=${document}`)
      .then(async (res) => {
        if (mounted) {
          const filteredQuestions = res.data.data.filter(
            (data) => data.form === formType
          );
          setRequiredNumber(
            filteredQuestions[0].result.filter(
              (question) => question.required === true
            ).length
          );
          setGeneralQuestions(filteredQuestions[0].result.sort(function(a, b){return a.position - b.position}));
          dispatch({
            type: "FETCH_QUESTIONS",
            questions: generalQuestionsTrue,
          });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error("Erro no getQuestions, problema: ", err);
      });

    return () => (mounted = false);
  }, []);

  useEffect(() => {

    Object.keys(generalQuestionsTrue).forEach((key) => {
      getAnswers.push({
        idAnswer: generalQuestionsTrue[key].idAnswer,
        answer: generalQuestionsTrue[key].answer,
        answered: generalQuestionsTrue[key].answered,
        acceptConfidential: generalQuestionsTrue[key].acceptConfidential,
        isConfidential: generalQuestionsTrue[key].isConfidential
      });
    });

    setAnswers(getAnswers);
    countQuestions();
  }, [generalQuestionsTrue]);

  useEffect(() => {
    countQuestions();
  });

  return (
    <DivGlobal>
      <Container maxW="1440px" background="#FFFFFF" p="30px" h="162px">
        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          <GridItem colSpan={1}>
            <Heading
              as="h2"
              fontSize={"24px"}
              lineHeight="54px"
              borderBottom="1px"
              borderColor="#E5E5E5"
            >
              {`${responseData.nameVersion}`}
            </Heading>
            <Text
              fontSize={"14px"}
            >{`${responseForms.totalQuestions} perguntas`}</Text>
          </GridItem>

          <GridItem colSpan={1}>
            <Flex width="100%" justify="center" gap="80px" alignItems="center">
              <Box w="80px">
                <CircularProgressbar
                  value={`${answersNumber}`}
                  minValue={0}
                  maxValue={`${responseForms.totalQuestions}`}
                  text={
                    `${answersNumber}` +
                    "/" +
                    `${
                      responseForms.totalQuestions
                        ? responseForms.totalQuestions
                        : 0
                    }`
                  }
                  styles={buildStyles({
                    textSize: "font-size: 16px",
                    width: "71px",
                    height: "71px",
                    pathColor: `#174BD6`,
                    textColor: "#5C6066",
                    trailColor: "#C4C4C4",
                  })}
                />
                <Text whiteSpace="nowrap" fontSize="14px" lineHeight="16px">
                  Respondidas
                </Text>
              </Box>
              <Box w="80px">
                <CircularProgressbar
                  value={`${requiredAnswered}`}
                  minValue={0}
                  maxValue={`${requiredNumber}`}
                  text={`${requiredAnswered}` + "/" + `${requiredNumber}`}
                  styles={buildStyles({
                    textSize: "font-size: 16px",
                    width: "71px",
                    height: "71px",
                    pathColor: `#D50000`,
                    textColor: "#5C6066",
                    trailColor: "#C4C4C4",
                  })}
                />
                <Text whiteSpace="nowrap" fontSize="14px" lineHeight="16px">
                  Obrigatórias
                </Text>
              </Box>
            </Flex>
          </GridItem>
          <GridItem colSpan={1}>
            <Flex
              flexDir="column"
              alignItems="flex-end"
              gap="12px"
              w="100%"
              justify="flex-end"
            >
              <Button
                as="a"
                w="265px"
                color="white"
                fontSize="16px"
                lineHeight={"19px"}
                borderRadius="10px"
                backgroundColor="#013280"
                // href={`/registration/profile?cr=${document}&st=${canEdit}`}
                onClick={() => sendInfo(`/registration/profile?cr=${document}&st=${canEdit}`)}
              >
                Salvar
              </Button>
              <Button
                w="265px"
                as="a"
                color="#013280"
                variant="outline"
                borderRadius={"12px"}
                href={`/registration/profile?cr=${document}&st=${canEdit}`}
              >
                Voltar
              </Button>
            </Flex>
          </GridItem>
        </Grid>
      </Container>

      <Container maxW="container.xl" mt="30px">
        {generalQuestionsTrue.length ? (
          generalQuestionsTrue.map((chave, valor) => {
            return (
              <SubDiv
                paddingBottom={"22px"}
                key={valor + chave.type}
                style={{ order: chave.position }}
              >
                <Text>{chave.questionText}</Text>
                {chave.required === true ? (
                  <Text color="#D50000" fontWeight="500" fontSize="14px">
                    * Questão Obrigatória
                  </Text>
                ) : (
                  ""
                )}

                {chave.typeForm === "CHECKBOX" && (
                  <Grid
                    style={{
                      marginTop: "20px",
                      display: "grid",
                    }}
                  >
                    {filterOptions(answerOptions, chave.idQuestionOption).map(
                      (option) => {
                        return (
                          <span style={{ marginRight: "10px" }}>
                            <input
                              key={chave.idAnswer}
                              name={chave.idAnswer}
                              value={option.text}
                              type="checkbox"
                              onInput={(e) => createAnswers(e)}
                              defaultChecked={
                                chave.answer &&
                                chave.answer.includes(option.text)
                              }
                              disabled={canEdit === "false" ? true : false}
                            />
                            <label> {option.text}</label>
                          </span>
                        );
                      }
                    )}

                    {chave.help.length > 2 && (
                      <Text fontSize="13px" mt="10px" lineHeight="20px">
                        Dica: {chave.help}
                      </Text>
                    )}
                    {checkConfidential(chave)}
                  </Grid>
                )}

                {chave.typeForm === "RADIO" && (
                  <Grid
                    row
                    style={{
                      marginTop: "20px",
                      display: "grid",
                    }}
                  >
                    {filterOptions(answerOptions, chave.idQuestionOption).map(
                      (option) => {
                        return (
                          <span style={{ marginRight: "10px" }}>
                            <input
                              key={chave.idAnswer}
                              name={chave.idAnswer}
                              value={option.text}
                              type="radio"
                              onInput={(e) => createAnswers(e)}
                              defaultChecked={
                                chave.answer &&
                                chave.answer.includes(option.text)
                              }
                              disabled={canEdit === "false" ? true : false}
                            />
                            <label> {option.text}</label>
                          </span>
                        );
                      }
                    )}
                    {checkConfidential(chave)}
                  </Grid>
                )}

                {chave.typeForm === "TEXT" && (
                  <Grid column>
                    <Label>{chave.label}</Label>
                    <Input
                      key={chave.idAnswer}
                      name={chave.idAnswer}
                      width={chave.width}
                      placeholder={chave.placeholder}
                      height={chave.height}
                      onInput={(e) => createAnswers(e)}
                      defaultValue={chave.answer}
                      readOnly={canEdit === "false" ? true : false}
                    />
                    {chave.help.length > 2 && (
                      <Text fontSize="13px" mt="10px" lineHeight="20px">
                        Dica: {chave.help}
                      </Text>
                    )}
                    {checkConfidential(chave)}
                  </Grid>
                )}

                {chave.typeForm === "CURRENCYBRL" && (
                  <Grid column>
                    <Label>{chave.label}</Label>
                    <InputMask
                      mask="R$9999999999999999999999999999"
                      maskChar=" "
                      formatChars={{
                        9: "[0-9,]",
                      }}
                      key={chave.idAnswer}
                      name={chave.idAnswer}
                      width={chave.width}
                      placeholder={chave.placeholder}
                      height={chave.height}
                      onInput={(e) => createAnswers(e)}
                      defaultValue={chave.answer}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={chave.placeholder}
                        />
                      )}
                    </InputMask>
                    {chave.help.length > 2 && (
                      <Text fontSize="13px" mt="10px" lineHeight="20px">
                        Dica: {chave.help}
                      </Text>
                    )}
                    {checkConfidential(chave)}
                  </Grid>
                )}

                {chave.typeForm === "CURRENCYUSA" && (
                  <Grid column>
                    <Label>{chave.label}</Label>
                    <InputMask
                      mask="$9999999999999999999999999999"
                      maskChar=" "
                      formatChars={{
                        9: "[0-9,]",
                      }}
                      key={chave.idAnswer}
                      name={chave.idAnswer}
                      width={chave.width}
                      placeholder={chave.placeholder}
                      height={chave.height}
                      onInput={(e) => createAnswers(e)}
                      defaultValue={chave.answer}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={chave.placeholder}
                        />
                      )}
                    </InputMask>
                    {chave.help.length > 2 && (
                      <Text fontSize="13px" mt="10px" lineHeight="20px">
                        Dica: {chave.help}
                      </Text>
                    )}
                    {checkConfidential(chave)}
                  </Grid>
                )}


                {chave.typeForm === "NUMBER" && (
                  <Grid column>
                    <Label>{chave.label}</Label>
                    <InputMask
                      mask="999999999999999999999999"
                      maskChar=" "
                      formatChars={{
                        9: "[0-9]",
                      }}
                      key={chave.idAnswer}
                      name={chave.idAnswer}
                      width={chave.width}
                      placeholder={chave.placeholder}
                      height={chave.height}
                      onInput={(e) => createAnswers(e)}
                      defaultValue={chave.answer}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={chave.placeholder}
                        />
                      )}
                    </InputMask>
                    {chave.help.length > 2 && (
                      <Text fontSize="13px" mt="10px" lineHeight="20px">
                        Dica: {chave.help}
                      </Text>
                    )}
                    {checkConfidential(chave)}
                  </Grid>
                )}

                {chave.typeForm === "CNPJ" && (
                  <Grid column>
                    <Label>{chave.label}</Label>
                    <InputMask
                      mask="99.999.999/9999-99"
                      {...props}
                      onInput={(e) => createAnswers(e)}
                      name={chave.idAnswer}
                      key={chave.idAnswer}
                      defaultValue={chave.answer}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={chave.placeholder}
                        />
                      )}
                    </InputMask>
                    {checkConfidential(chave)}
                  </Grid>
                )}

                {chave.typeForm === "SITE" && (
                  <Grid column>
                    <Label>{chave.label}</Label>
                    <InputMask
                      mask="https://aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
                      maskChar=" "
                      formatChars={{
                        a: "[a-z0-9-./]",
                      }}
                      {...props}
                      onInput={(e) => createAnswers(e)}
                      name={chave.idAnswer}
                      key={chave.idAnswer}
                      defaultValue={chave.answer}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={chave.placeholder}
                        />
                      )}
                    </InputMask>
                    {chave.help.length > 2 && (
                      <Text fontSize="13px" mt="10px" lineHeight="20px">
                        Dica: {chave.help}
                      </Text>
                    )}
                    {checkConfidential(chave)}
                  </Grid>
                )}

                {chave.typeForm === "EMAIL" && (
                  <Grid column>
                    <Label>{chave.label}</Label>
                    <InputMask
                      {...props}
                      onInput={(e) => createAnswers(e)}
                      name={chave.idAnswer}
                      key={chave.idAnswer}
                      defaultValue={chave.answer}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={chave.placeholder}
                        />
                      )}
                    </InputMask>
                    {chave.help.length > 2 && (
                      <Text fontSize="13px" mt="10px" lineHeight="20px">
                        Dica: {chave.help}
                      </Text>
                    )}
                    {checkConfidential(chave)}
                  </Grid>
                )}

                {chave.typeForm === "PHONE" && (
                  <Grid column>
                    <Label>{chave.label}</Label>
                    <InputMask
                      {...props}
                      mask="+99(99)99999-9999"
                      maskChar=" "
                      onInput={(e) => createAnswers(e)}
                      name={chave.idAnswer}
                      key={chave.idAnswer}
                      defaultValue={chave.answer}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={chave.placeholder}
                        />
                      )}                      
                    </InputMask>
                    {checkConfidential(chave)}
                  </Grid>
                )}

                {chave.typeForm === "DATA" && (
                  <Grid column>
                    <Label>{chave.label}</Label>
                    <InputMask
                      mask="99/99/9999"
                      {...props}
                      onInput={(e) => createAnswers(e)}
                      name={chave.idAnswer}
                      key={chave.idAnswer}
                      defaultValue={chave.answer}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={chave.placeholder}
                        />
                      )}
                    </InputMask>
                    {chave.help.length > 2 && (
                      <Text fontSize="13px" mt="10px" lineHeight="20px">
                        Dica: {chave.help}
                      </Text>
                    )}
                    {checkConfidential(chave)}
                  </Grid>
                )}

                {chave.typeForm === "FILE" && (
                  <Grid column>
                    <label for={chave.idAnswer} style={{ display: "none" }}>
                      {valor + "FILE"}
                    </label>
                    <ChakraInput
                      border="none"
                      mt="10px"
                      fontSize="15px"
                      key={chave.idAnswer}
                      name={chave.idAnswer}
                      type="file"
                      accept=".jpeg, .png, .jpg"
                      onInput={(e) => createAnswers(e)}
                      readOnly={canEdit === "false" ? true : false}
                    />

                    {imageLoading && (
                      <>
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="blue.500"
                          size="xl"
                        />
                      </>
                    )}

                    {chave.answered && (
                      <Box maxW="200px" mt="20px">
                        <Image src={chave.answer} alt="load" />                        
                      </Box>
                    )}
{/* 
                    {imageUrl.length > 2 && (
                      <Box maxW="200px" mt="20px">
                        <Image src={imageUrl} alt="load" />                        
                      </Box>
                    )} */}
                    {checkConfidential(chave)}
                  </Grid>
                )}

                {chave.typeForm === "FILES" && (
                  <Grid column>
                    <label for={chave.idAnswer} style={{ display: "none" }}>
                      {valor + "FILE"}
                    </label>
                    <ChakraInput
                      mt="10px"
                      fontSize="14px"
                      border="none"
                      cursor="pointer"
                      key={chave.idAnswer}
                      name={chave.idAnswer}
                      type="file"
                      accept=".jpeg, .png, .jpg"
                      onInput={(e) => createAnswers(e)}
                      readOnly={canEdit === "false" ? true : false}
                      multiple
                    />
                    <div
                      style={{
                        display: "grid",
                        gap: "10px",
                        gridTemplateColumns: "repeat(4, 1fr)",
                      }}
                    >
                      {imagesLoading && (
                        <>
                          <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                          />
                        </>
                      )}

                      {chave.answered &&
                        Object.keys(chave.answer.split(",")).map((index) => {
                          const link = chave.answer.split(",")[index];
                          return (
                            <img
                            alt='load'
                              src={link}
                              style={{
                                maxWidth: "200px",
                                maxHeight: "200px",
                                marginTop: "20px",
                              }}
                            />
                          );
                        })}
                    </div>
                    {checkConfidential(chave)}
                  </Grid>
                )}

                {chave.help && (
                  <Box
                    as="button"
                    position="absolute"
                    top="16px"
                    right="16px"
                    onClick={() => sendAlert(chave.help)}
                  >
                    <Icon type="alert-gray" />
                  </Box>
                )}
              </SubDiv>
            );
          })
        ) : (
          <Flex w="100%" align="center" justifyContent="center" py="40px">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        )}
      </Container>
    </DivGlobal>
  );
}

export default InfoOrganizacao;
