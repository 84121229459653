import React, { useEffect, useState } from "react";
import {
  HeaderColaboradores,
  TableCollabs,
  Grid,
  Breadcumbs,
  NavBarHorizontal,
  Button,
  Select,
  Icon,
  HeaderGray
} from "../../style";
import styled from "styled-components";
import Modal from "react-modal";
import getFaturaService from "../../services/getFaturaService";
import updateInvoiceDueDateService from "../../services/updateInvoiceDueDateService";
import FORMAT from "../../helpers/formatDateHelper";
import OPTIONSDAYS from "../../helpers/expirationDaysHelper";
import getURLParams from "../../services/getUrlParams";
import { useDispatch } from "react-redux";

import SimpleSidebar from '../components/nav/Sidebar'

const Layout = styled.div`
  margin-left: 32px;
  background: #f6f5fb;
`;

const ModalStyled1 = styled(Modal)`
  z-index: 1;

  margin-top: 15%;
  margin-left: 35%;

  width: 600px;
  height: 330px;

  background-color: #fff;

  position: relative;

  border-radius: 20px;
  border: 1px solid #ccc;
`;
const ModalStyled2 = styled(Modal)`
  z-index: 1;

  margin-top: 15%;
  margin-left: 35%;

  width: 581px;
  height: 341px;

  background-color: #fff;

  position: relative;

  border-radius: 20px;
  border: 1px solid #ccc;
`;

const Title = styled.h1`
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;

  color: #292b30;
`;

const TagStyled = styled.span`
  padding: 1px;
  width: 20px;
  height: 5px;
  border-radius: 6px;
  background-color: #40bf91;
  margin-bottom: 8px;
`;

const ButtonFooter = styled.button`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  text-align: center;

  color: #ffffff;

  padding-top: 19px;
  padding-bottom: 19px;

  border-radius: 0 0 20px 20px;
  border: 0px solid black;
  background-color: #013280;
  width: 581px;

  position: absolute;
  bottom: 0;
  left: 0;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
`;

function Faturas(props) {
  let dispatch = useDispatch();
  const [listInvoice, setListInvoice] = useState([]);
  const [infokey, setInfoKey] = useState(null);
  const [document, setDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getInitialState = () => {
    const value = "";
    return value;
  };

  const [value, setValue] = useState(getInitialState);

  useEffect(() => {
    let mounted = true;
    const { document, key } = getURLParams("bill");
    setInfoKey(key);
    setDocument(document);

    setIsLoading(true);
    getFaturaService(document, key).then((items) => {
      if (mounted) {
        setListInvoice(items);
        setIsLoading(false);

        let usuarioObj = {
          email: items.length > 0 ? items[0].customerEmail : "",
          nome: items.length > 0 ? items[0].customerName : "",
        };
        dispatch({ type: "CLICK_ADD_USUARIO", usuario: usuarioObj });
      }
    });

    return () => (mounted = false);
  }, []);

  if (listInvoice.length > 0) {
  }

  let breadcumbs = [
    { nome: "Faturas", tipo: "link", link: "/bills", icone: "", ativado: true },
  ];

  let listaHeader = [
    {
      titulo: "Forma de Pagamento",
      text: listInvoice.length > 0 ? listInvoice[0].paymentMethod : " ",
      icon: "collabs-blue",
      number: 1000,
      corFundo: "#D1DBF7",
      corTexto: "#174BD6",
    },

    {
      titulo: "Plano",
      text: listInvoice.length > 0 ? listInvoice[0].plan : " ",
      icon: "collabs-green",
      number: 1000,
      corFundo: "#D9F2E9",
      corTexto: "#40BF91",
    },
  ];

  const options = OPTIONSDAYS.expirationDays();

  let tabelaCabecalho = [
    { item: "#", icone: false },
    { item: "Emissão", icone: false },
    { item: "Vencimento", icone: false },
    { item: "Ciclo", icone: false },
    { item: "Valor", icone: false },
    { item: "Status", icone: true },
    { item: "Ações", icone: false },
  ];

  let tabelaBody = [];
  if (listInvoice.length > 0) {
    for (const item in listInvoice) {
      const invItem = {
        id: listInvoice[item].id,
        emissao: FORMAT.convertDateUTCToBR(listInvoice[item].creationDate),
        vencimento:
          listInvoice[item].dueAt !== null
            ? FORMAT.convertDateUTCToBR(listInvoice[item].dueAt)
            : FORMAT.convertDateUTCToBR(listInvoice[item].creationDate),
        ciclo: listInvoice[item].cycle,
        valor: listInvoice[item].amount,
        status: listInvoice[item].status,
        selecionado: true,
        icones: true,
        url: listInvoice[item].url,
      };
      tabelaBody.push(invItem);
    }
  }

  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);

  const handleModal1 = () => setModal1(!modal1);
  const handleModal2 = () => setModal2(!modal2);

  async function updateDueDate(newDate, document) {
    if (
      newDate === "" ||
      newDate === null ||
      document === "" ||
      document === null
    ) {
      alert("Escolha uma data para o novo vencimento.");
      return;
    }

    alert(
      "ATENÇÃO: A alteração do vencimento só produzirá efeito nas mensalidades que ainda não foram faturadas."
    );
    await updateInvoiceDueDateService(newDate, document[0]);
    handleModal2();
  }

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Layout>
    <SimpleSidebar path=''>
      <Grid row>
      
        <Grid
          column
          style={{ width: "100%", marginTop: "0px", marginLeft: "10px" }}
        >
          <Grid row style={{ width: "100%", marginTop: "32px" }}>
            <Breadcumbs
              lista={breadcumbs}
              spacing="space-between"
              style={{ width: "100%" }}
            />
          </Grid>

          {tabelaBody.length > 0 ? (
            <Grid>
              {/* <NavBarHorizontal /> */}
              <Grid row style={{ marginLeft: "32px", marginRight: "32px" }}>
                {listaHeader.map((chave, valor) => {
                  return (
                    <HeaderColaboradores
                      title={chave.titulo}
                      text={chave.text}
                      icon={chave.icon}
                      number={chave.number}
                      background={chave.corFundo}
                      color={chave.corTexto}
                    />
                  );
                })}
              </Grid>

              {tabelaBody.length > 0 &&
              listInvoice[0].paymentMethod === "Boleto Bancário" ? (
                <Grid
                  row
                  spacing="space-between"
                  style={{
                    marginLeft: "-32px",
                    marginRight: "-32px",
                    width: "100%",
                  }}
                >
                  <Button
                    type="outline"
                    width="265px"
                    height="40px"
                    marginLeft="80%"
                    label="Alterar Vencimento"
                    onClick={handleModal1}
                  />
                </Grid>
              ) : (
                <p></p>
              )}

              <Grid row>
                <TableCollabs header={tabelaCabecalho} body={tabelaBody} />
              </Grid>
            </Grid>
          ) : (
            <Grid
              row
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "95%",
                
              }}
            >
              {isLoading === false ? (
                <HeaderGray
                  title="A sua modalidade não é assinatura!"
                  text="Não foram encontrados dados com as informações fornecidas.\nEste acesso são para clientes com assinaturas ativas."
                  icon={false}
                />
              ) : (
                <p></p>
              )}
            </Grid>
          )}
        </Grid>

        <ModalStyled1 isOpen={modal1} onRequestClose={handleModal1}>
          <Grid
            column
            spacing="space-between"
            style={{
              position: "absolute",
              top: "32px",
              left: "32px",
            }}
          >
            <TagStyled />
            <Title style={{ marginBottom: "29px" }}>
              Alterar data de vencimento
            </Title>
            <Grid row>
              <Select
                width="250px"
                background="#F5F5FB"
                options={options}
                onChange={handleChange}
              />
            </Grid>
            <Grid row spacing="space-between" style={{ marginTop: "60px" }}>
              <span style={{ marginRight: "35px" }}>
                <Button
                  label="Cancelar"
                  type="outline"
                  width="265px"
                  height="56px"
                  onClick={handleModal1}
                />
              </span>
              <Button
                label="Confirmar"
                width="245px"
                height="56px"
                onClick={(e) => {
                  updateDueDate(value, document);
                }}
              />
            </Grid>
          </Grid>
        </ModalStyled1>

        <ModalStyled2 isOpen={modal2} onRequestClose={handleModal2}>
          <Grid
            column
            style={{
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: "32px",
              left: "0px",
              width: "100%",
            }}
          >
            <div style={{ marginBottom: "24px", marginTop: "30px" }}>
              <Icon type="check-green" width="100px" height="100px" />
            </div>
            <Title>Alteração realizada com sucesso</Title>
          </Grid>

          <ButtonFooter
            onClick={() => {
              handleModal1();
              handleModal2();
            }}
          >
            Fechar
          </ButtonFooter>
        </ModalStyled2>
      </Grid>
  
    </SimpleSidebar>
    </Layout>
  );
}

export default Faturas;
