/* eslint-disable no-unused-vars */
import { useState } from "react";
import Translator from "../../../components/i18n/Translator";
import { useForm } from "react-hook-form";
import StrongTranslator from "../../../components/i18n/StrongTranslator";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  ModalFooter,
  Box,
} from "@chakra-ui/react";
import { Icon } from "../../../../style";
import { FileInput } from "../FileInput";
import { useMutation } from "react-query";
import { queryClient } from "../../../../services/queryCliente";

const UploadSheetModal = ({
  onClose,
  isOpen,
  researchId,
  handleDownloadList,
  onSuccess,
  updateEmployees,
}) => {
  const [success, setSuccess] = useState(false);
  const [currentFile, setCurrentFile] = useState();
  const [employeesTotal, setEmployeesTotal] = useState(0);
  const [errorsTotal, setErrorsTotal] = useState(0);
  const [imageUrl, setImageUrl] = useState("");

  const { register, handleSubmit, formState, setError, trigger } = useForm();
  const { errors } = formState;

  const handleClose = () => {
    onClose();
    // handleDownloadList();
    if (success) onSuccess();
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
    setCurrentFile(undefined);
  };

  const upsateSheet = useMutation(async () => {}, {
    onSuccess: () => {
      queryClient.invalidateQueries("research");
    },
  });

  const onSubmit = async (values) => {
    if (employeesTotal === 0) return;
    else {
      updateEmployees(employeesTotal);
      const response = upsateSheet.mutateAsync();
      setSuccess(true);
      setEmployeesTotal(0);
      // handleDownloadList();
    }
  };

  return success ? (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent w="581px" p="0px" borderRadius="20px" overflow="hidden">
        <ModalBody p="60px">
          <Box display={"flex"} flexDir="column" alignItems="center" gap="24px">
            <Icon type="check-green" width="100px" height="100px" />

            <Heading fontSize="24px" fontWeight="700">
              <Translator path={"survey.config.sheet.modal.success"} />
            </Heading>
          </Box>
        </ModalBody>
        <ModalFooter width="100%" p={0} height="69px">
          <Button
            width="100%"
            color="white"
            background="#013280"
            borderRadius={0}
            height="69px"
            onClick={handleClose}
          >
            <Translator path={"global.modal.close"} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent w="581px" p="0px" borderRadius="20px" overflow="hidden">
        <ModalHeader pt="40px" px="40px">
          <Heading fontWeight="600" fontSize="18px">
            <Translator path={"survey.config.sheet.modal.title"} />
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <form onSubmit={onSubmit}>
          <ModalBody px="40px">
            <Text fontSize="14px" mb="24px">
              <StrongTranslator
                path={"survey.config.sheet.modal.description"}
              />
            </Text>

            <FileInput
              errorsTotal={errorsTotal}
              setEmployeesTotal={setEmployeesTotal}
              researchId={researchId}
              setImageUrl={setImageUrl}
              currentFile={currentFile}
              setCurrentFile={setCurrentFile}
              setError={setError}
              setErrorsTotal={setErrorsTotal}
              trigger={trigger}
              {...register("file")}
              error={errors.file}
              onClose={handleClose}
            />
          </ModalBody>
          <ModalFooter p={0}>
            <Button
              type="submit"
              bg={currentFile ? "red.100" : "#292B30"}
              opacity={currentFile ? "1" : "0.5"}
              borderRadius={0}
              h="69px"
              w="100%"
              color="white"
              fontWeight="400"
            >
              <Translator path={"survey.config.sheet.modal.save"} />
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UploadSheetModal;
