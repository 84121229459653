import apiAuth from './api-auth'
import { researchApi } from './api-research'

async function authenticate(user, pass) {
    const body = {	login: user,
	                password: pass,
	                applicationName: "Connect"}
    let result = {}    
    try {
        result = await apiAuth.post('/auth/generic/login', body) 
    } catch (error) {
        console.error(error)
    }

    return result
}

async function recover(user) {
    return await researchApi.post(`/users/recovery/?email=${user}`)
}

async function reset(key, pass) {
    const body = {
        new_password: pass
    }

    let result = {}    
    try {
        result = await researchApi.post(`/users/reset/${key}`, body)
    } catch (error) {
        console.error(error)
    }

    return result
}

export { authenticate, recover, reset } 