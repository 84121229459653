import { Flex, GridItem, Text, Button, Heading } from "@chakra-ui/react";
import Translator from "../../components/i18n/Translator";
import StrongTranslator from "../../components/i18n/StrongTranslator";
import iconBar from "../../../assets/images/icon-bar.svg";
import { useTranslation } from "react-i18next";

export default function Comunication() {
  const { t } = useTranslation();

  const handleModelDownload = () => {
    fetch(
      `${process.env.PUBLIC_URL}/resources/${t(
        "survey.config.communication.fileNameGuide"
      )}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${t("survey.config.communication.fileNameGuide")}`
        );

        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  };

  const handleModelEmailDownload = () => {
    fetch(
      `${process.env.PUBLIC_URL}/resources/${t(
        "survey.config.communication.fileNameDisclosure"
      )}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          t("survey.config.communication.fileNameDisclosure")
        );

        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  };

  return (
    <GridItem
      p="30px"
      bg="white"
      colSpan={1}
      h="100%"
      border="1px"
      borderColor="#CCCCCC"
      borderRadius="10px"
      position="relative"
    >
      <img src={iconBar} alt="icon bar" />
      <Heading as="h5" fontSize="17px" fontWeight="600" pt="10px">
        <Translator path={"survey.config.communication.title"} />
      </Heading>
      <Text fontSize="14px" mt="8px">
        <StrongTranslator path={"survey.config.communication.description"} />
      </Text>

      <Flex fontSize="14px" mt="22px">
        <Button
          color="#013280"
          variant="link"
          fontSize="14px"
          borderBottom="2px"
          borderRadius="0px"
          fontWeight={"600"}
          mr="5px"
          onClick={handleModelEmailDownload}
        >
          <Translator path={"survey.config.communication.click"} />
        </Button>
        <Translator path={"survey.config.communication.text1"} />
      </Flex>
      <Flex fontSize="14px">
        <Button
          color="#013280"
          variant="link"
          fontSize="14px"
          borderBottom="2px"
          borderRadius="0px"
          fontWeight={"600"}
          mr="5px"
          onClick={handleModelDownload}
        >
          <Translator path={"survey.config.communication.click"} />
        </Button>
        <Translator path={"survey.config.communication.text2"} />
      </Flex>
    </GridItem>
  );
}
