import React, { useState } from "react";
import { Box, Button, Flex, Heading, ScaleFade, Text } from "@chakra-ui/react";

import people from "../../../assets/images/people.svg";
import iconClose from "../../../assets/images/icon-close.svg";

const Card = ({ title = "", text = "", hasCloseButton }) => {
  const [close, setOnClose] = useState(true);

  const handleClose = () => {
    setOnClose(false);
  };
  return (
    <>
      <ScaleFade in={close} reverse unmountOnExit>
        <Box borderColor="black">
          <Box position="relative" h="151px">
            <Flex
              p="6"
              bg="#e2e1e7"
              h="141px"
              borderRadius="12px"
              flexDir="column"
              position="absolute"
              w="100%"
              bottom={0}
            >
              {hasCloseButton && (
                <Button
                  h="23px"
                  position={"absolute"}
                  right={0}
                  top="16px"
                  p={0}
                  onClick={handleClose}
                  bg="transparent"
                  leftIcon={<img src={iconClose} alt="icon close" />}
                  _hover={{
                    background: "transparent",
                  }}
                ></Button>
              )}

              <Heading
                as="h3"
                fontSize="24px"
                lineHeight="28px"
                fontFamily="Raleway"
                fontWeight="bold"
              >
                {title ? (
                  <>{title}</>
                ) : (
                  <>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</>
                )}
              </Heading>
              <Text pt="16px" w="448px" fontSize={"14px"}>
                {text ? (
               <>{text}</> 
                ) : (
                  <>
                    {" "}
                    Sed in libero commodo enim laoreet auctor. Donec ac
                    ultricies nibh,
                    <br /> non gravida nibh. Orci varius natoque penatibus.
                  </>
                )}
              </Text>

              <img
                src={people}
                alt="people"
                style={{
                  height: "150px",
                  position: "absolute",
                  bottom: "0",
                  right: "70px",
                }}
              />
            </Flex>
          </Box>
        </Box>
      </ScaleFade>
    </>
  );
};

export default Card;
