import React, { useState } from "react";
import styled from "styled-components";
import { Links, Icon, Grid, Container } from "../../../style";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import background from "./background.png";

const StyleContainer = styled.div`
  background-image: url(${background});
  background-size: cover;
  border-radius: 12px;
  background-repeat: no-repeat;
  /* width: ${(props) => (props.width ? props.width : "550px")}; */
  height: 203px;
  margin-right: 16px;
  margin-bottom: 16px;
  position: relative;
`;

const Title = styled.h1`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  position: absolute;
  top: 45px;
  left: 33px;
`;
const Color = styled.div`
  width: 20px;
  height: 5px;

  background-color: ${(props) => props.cor};
  border-radius: 6px;
  position: absolute;
  top: 32px;
  left: 32px;
`;

const ContainerCircular = styled.div`
  width: 70px;
  height: 70px;
  position: absolute;
  bottom: 48px;
  right: 32px;
`;
const ContainerStatus = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  position: absolute;
  top: 78px;
  left: 33px;
`;

const Alerta = styled.div`
  width: 100%;
  color: white;
  position: absolute;
  bottom: 0;
  height: 32px;
  background: #d50000;
  border-radius: 0px 0px 12px 12px;
  font-size: 14px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

const AlertaOK = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  background-color: #008000;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 99.7%;
  border-radius: 0 0 12px 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  vertical-align: middle;
`;

const MiddlePlay = styled.div`
  vertical-align: middle;
  position: absolute;
  bottom: 52px;
  left: 32px;
`;

const MidddleLink = styled.div`
  vertical-align: middle;
  position: absolute;
  bottom: 52px;
  left: 32px;
`;

const MiddleLink = styled.div`
  vertical-align: middle;
  position: absolute;
  bottom: 32px;
  left: 32px;
`;

function Dialog(props) {
  console.error(props);

  return (
    <StyleContainer width={props.width} style={{ order: props.order }}>
      <Color cor={props.cor} />
      <Title>{props.titulo}</Title>
      {/* {props.status === true &&  <ContainerStatus>
                <Icon type="check" /> {' '} {props.concluidos} {' '} {props.concluidos <= 1 ? "concluído": "concluídos"}
                <Icon type="alert-orange" /> {' '} {props.pendentes} {' '} {props.pendentes <= 1 ? "pendente": "pendentes"}
              </ContainerStatus>
            } */}
      {props.message === "false" && (
        <ContainerStatus>
          <Icon type="alert-orange" /> {`Prazo de preenchimento encerrado!`}
        </ContainerStatus>
      )}
      <ContainerCircular>
        <CircularProgressbar
          value={props.inicial}
          minValue={0}
          maxValue={props.final}
          text={props.inicial + "/" + props.final}
          styles={buildStyles({
            textSize: "font-size: 16px",
            width: "71px",
            height: "71px",
            pathColor: `#40BF91`,
            textColor: "#ffff",
            trailColor: "#E5E5E5",
          })}
        />
      </ContainerCircular>
      {props.iconePlay === false && props.estado === true && (
        <MidddleLink>
          <Links to={props.url} label={props.textoUrl} color="#fff" underline />
        </MidddleLink>
      )}
      {props.iconePlay === false && props.estado === false && (
        <MiddleLink>
          <Links to={props.url} label={props.textoUrl} color="#fff" underline />
        </MiddleLink>
      )}
      {props.iconePlay === true && props.estado === false && (
        <MiddleLink>
          <Icon type="icone-play" />
          {"   "}
          <Links to={props.url} label={props.textoUrl} color="#fff" underline />
        </MiddleLink>
      )}
      {props.iconePlay === true && props.estado === true && (
        <MiddlePlay>
          <Icon type="icone-play" />
          {"   "}
          <Links to={props.url} label={props.textoUrl} color="#fff" underline />
        </MiddlePlay>
      )}
      {props.iconePlay}
      {props.estado && props.todasRespondidas === false && (
        <Alerta>
          <Icon type="alert-white" /> {props.textoAlerta}
        </Alerta>
      )}
      {props.estado && props.todasRespondidas === true && (
        <AlertaOK>
          <Icon type="alert-white" /> {props.textoAlerta}
        </AlertaOK>
      )}
    </StyleContainer>
  );
}

export default Dialog;
