import { useState } from "react";
import { BsBagDash } from "react-icons/bs";

import {
  Button,
  Modal,
  Text,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Heading,
} from "@chakra-ui/react";

import { Input } from "../../components/input/index";

import CompanyInfo from "./CompanyInfo";

const FirstAccess = ({ onClose, isOpen, url }) => {
  const [companyInfoModal, setCompanyInfoModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [cnpj, setCnpj] = useState("");

  function handleCnpj(cnpj) {
    let cleanCnpj = cnpj
      .replaceAll("/", "")
      .replaceAll(".", "")
      .replaceAll("-", "");
    setCnpj(cleanCnpj);
  }

  const handleCompanyInfo = () => {
    setCompanyInfoModal(true);
  };

  const handleCloseCompanyInfo = () => {
    setCompanyInfoModal(false);
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const handleCloseSuccess = () => {
    onClose();
    setSuccess(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: "30px",
          width: "543px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <ModalHeader pt="70px" px="70px">
          <Heading fontSize={"28px"}>Primeiro acesso</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px="70px">
          <Text as="p" fontSize={"18px"} mb="30px">
            Para iniciarmos o seu primeiro acesso, digite o número do CNPJ
            cadastrado e o código recebido por e-mail enviado pelo consultor.
          </Text>

          <Input
            id="cnpj"
            label="CNPJ do comprador"
            placeholder="Digite o CNPJ do comprador"
            type="string"
            icon={<BsBagDash color="#9ea3ad" fontSize="22px" />}
            value={cnpj}
            onChange={(e) => {
              handleCnpj(e.target.value);
            }}
          />

          <a href={url + `/cadastro/${cnpj}`} width="443px" height="56px">
            <Button
              type="submit"
              colorScheme="blue"
              bg="#013280"
              h="56px"
              borderRadius={10}
              fontSize="16px"
              width="100%"
              mt="25px"
              mb="50px"
              onClick={handleCompanyInfo}
            >
              Prosseguir
            </Button>
          </a>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FirstAccess;
