import { createContext, useEffect, useState } from "react";
import { useResearch } from "../helpers/useResearch";
import { subDays, isAfter } from "date-fns";
import { getCookie } from "../app/common/cookieManager";
export const CustomContext = createContext({});

export function CustomProvider({ children }) {
  const [custom, setCustom] = useState(true);
  const [id, setId] = useState();
  const [currentCompanyLogo, setCurrentCompanyLogo] = useState();
  const [systemLanguage, setSystemLanguage] = useState("pt-BR");
  const [userPermissions, setUserPermissions] = useState([]);

  const { data } = useResearch(id !== undefined ? id : undefined);

  useEffect(() => {
    const credential = getCookie("credential");
    const userPermissions = !!credential
      ? JSON.parse(Buffer.from(credential, "base64").toString("ascii"))
          ?.credential.user.permissions
      : [];
    setUserPermissions(userPermissions);
  }, []);

  useEffect(() => {
    if (data?.period.from) {
      const limitDate = subDays(new Date(data.period.from), 2);
      const isValid = isAfter(new Date(), limitDate);
      if (isValid) {
        setCustom(false);
      } else {
        setCustom(true);
      }
    } else {
      setCustom(true);
    }
  }, [custom, data, id]);

  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng");

    if (!!currentLanguage) {
      setSystemLanguage(currentLanguage);
    }
  }, [setSystemLanguage]);

  const handleCustom = () => {
    setCustom(false);
  };

  return (
    <CustomContext.Provider
      value={{
        custom,
        handleCustom,
        setId,
        currentCompanyLogo,
        setCurrentCompanyLogo,
        systemLanguage,
        setSystemLanguage,
        userPermissions,
      }}
    >
      {children}
    </CustomContext.Provider>
  );
}
