import { useState } from "react";

import * as Yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { recover } from "../../../services/authServices";
import Translator from "../../components/i18n/Translator";
import StrongTranslator from "../../components/i18n/StrongTranslator";
import successImage from "../../../assets/images/success.jpg";

import { MdOutlineEmail } from "react-icons/md";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  ModalFooter,
  Box,
  Image,
  useToast,
} from "@chakra-ui/react";
import { Input } from "../../components/input/index";
import { useTranslation } from "react-i18next";

const schema = Yup.object().shape({
  email: Yup.string()
    .required(<Translator path={"global.error.required"} />)
    .email(<Translator path={"global.form.email.invalid"} />),
});

const ForgotPassword = ({ onClose, isOpen, fechar }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { t } = useTranslation();

  const [success, setSuccess] = useState(false);
  const toast = useToast();

  async function handleSendEmail(data) {
    const { email } = data;
    try {
      const response = await recover(email);

      if (!!response) {
        setSuccess(true);
      }
    } catch (error) {
      toast({
        title: <Translator path={"login.recovery.form.error.title"} />,
        description: <Translator path={"global.error.tryAgain"} />,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setSuccess(false);
      console.error(error);
    }
  }

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
  };

  return success ? (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: "30px",
          height: "380px",
          width: "100%",
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box display={"flex"} flexDir="column" p="40px">
          <div
            style={{
              margin: "0 auto",
              marginBottom: "20px",
            }}
          >
            <Image src={successImage} alt="success" />
            {/* <Icon type="check-green" width="100px" height="100px" /> */}
          </div>
          <Heading fontSize="28px">
            <Translator path={"login.recovery.form.success.title"} />
          </Heading>
          <Text as="p" fontSize={"16px"} mt="30px">
            <StrongTranslator
              path={"login.recovery.form.success.description"}
            />
          </Text>
        </Box>
        <ModalFooter width="100%" p={0}>
          <Button
            width="100%"
            color="white"
            background="#013280"
            height="69px"
            position="absolute"
            bottom={0}
            onClick={handleClose}
          >
            <Translator path={"global.modal.close"} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <Modal isOpen={isOpen} onClose={handleClose} size="9xl">
      <ModalOverlay />
      <ModalContent
        style={{ borderRadius: "30px", width: "500px", padding: "40px" }}
      >
        <ModalHeader>
          <Heading fontSize="28px">
            <Translator path={"login.recovery.title"} />
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text as="p" fontSize={"18px"} mb="30px">
            <StrongTranslator path={"login.recovery.description"} />
          </Text>
          <form onSubmit={handleSubmit(handleSendEmail)}>
            <Input
              {...register("email")}
              label={<Translator path="global.form.email.title" />}
              errors={errors}
              // type='email'
              id="emailRecupera"
              placeholder={t("global.form.email.helper")}
              icon={<MdOutlineEmail color="#9ea3ad" fontSize="22px" />}
            />
            {errors.email && <p>{errors.email.message}</p>}
            <div style={{ marginTop: "40px" }}>
              <Button
                type="submit"
                isLoading={isSubmitting}
                bg="#013280"
                color="white"
                borderRadius={"10px"}
                w="100%"
                // onClick={(e) => {recuperarSenha(getEmailRecupera()); setSuccess(true);}}
                h="56px"
                fontSize="16px"
              >
                <Translator path="login.recovery.form.submit" />
              </Button>
            </div>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ForgotPassword;
