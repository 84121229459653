import { FC, Dispatch } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RiInformationFill } from "react-icons/ri";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  Checkbox,
  Tooltip,
  Grid,
  GridItem,
  Heading,
  Container,
  Text,
} from "@chakra-ui/react";

import { Input } from "../../components/input/index";

// import { InputFile } from "../../../components/ui";

// import SignUp from "../../../components/ui/form/input/SignUp";


const signInFormSchema = yup.object().shape({
  email: yup.string().required("E-mail obrigatório").email("E-mail inválido"),
  password: yup.string().required("Senha obrigatória"),
});

const CompanyInfo = ({ onClose, isOpen, setSuccess }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(signInFormSchema),
  });

  const handleClose = () => {
    onClose();
  };

  const handleCancelButton = () => {
    onClose();
  };

  const handleSubmitTest = () => {
    onClose();
    setSuccess(true)
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="full">
      <ModalOverlay />
      <ModalContent
        pt="50px"
        pl="50px"
        pr="50px"
        borderRadius="30px"
        overflow="hidden"
      >
        <Container maxW="container.xl" h="90vh" position="relative">
          <Heading as="h2" fontSize="28px">
            Informações da empresa
          </Heading>

          <Text fontSize="18px" mt="20px">
            Agora que você validou seu código vamos precisar{" "}
            <b>completar o seu cadastro </b>
            com informações da empresa e logo após <br />
            cadastrar seu <b>e-mail</b> e <b>senha </b>
            para seus próximos acessos.
          </Text>

          <Grid
            h="271px"
            templateRows="repeat(3, 1fr)"
            templateColumns="repeat(5, 1fr)"
            gap={2}
            mt="25px"
          >
            <GridItem rowSpan={3} colSpan={1} justifySelf="flex-start">
              {/* <InputFile label="Adicione o logo da empresa" /> */}
            </GridItem>

            <GridItem colSpan={4}>
              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                <GridItem colSpan={1}>
                  <Input
                    id="cnpj"
                    error={errors.cnpj}
                    {...register("cnpj", { required: true })}
                    label="CNPJ do comprador"
                  />
                </GridItem>

                <GridItem colSpan={1}>
                  <Input
                    id="rival"
                    placeholder="Digite o CNPJ do concorrente"
                    label="CNPJ de quem vai concorrer as certificações"
                    error={errors.rival}
                    {...register("rival", { required: true })}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <div
                    style={{
                      height: "85%",
                      marginTop: "auto",
                      width: "100%",
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <Checkbox colorScheme="blue" size="sm" mt="auto">
                      Cnpj é o mesmo do comprador
                    </Checkbox>
                  </div>
                </GridItem>
              </Grid>
            </GridItem>

            <GridItem colSpan={4}>
              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                <GridItem colSpan={1}>
                  <Input
                    placeholder="Digite a razão social"
                    label="Razão social"
                    type="string"
                    id="corporatename"
                    error={errors.corporatename}
                    {...register("corporatename", { required: true })}
                  />
                </GridItem>

                <GridItem colSpan={1}>
                  <Input
                    id="fantasyname"
                    error={errors.fantasyname}
                    {...register("fantasyname", { required: true })}
                    placeholder="Digite o nome social"
                    label="Nome fantasia"
                    type="string"
                  />
                </GridItem>
              </Grid>
            </GridItem>

            <GridItem colSpan={4}>
              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                <GridItem colSpan={1}>
                  <Input
                    id="address"
                    error={errors.address}
                    {...register("address", { required: true })}
                    placeholder="Digite o endereço"
                    label="Endereço"
                    type="string"
                  />
                </GridItem>

                <GridItem colSpan={1}>
                  <Input
                    id="phone"
                    error={errors.phone}
                    {...register("phone", { required: true })}
                    label="Telefone da empresa"
                    placeholder="Digite o telefone da empresa"
                    type="string"
                  />
                </GridItem>

                <GridItem colSpan={1}>
                  <Tooltip
                    hasArrow
                    w="119px"
                    label="Quem ficará responsável por fazer as pesquisas"
                    bg="#949494"
                    borderRadius="10px"
                    color="white"
                    fontSize="12px"
                    fontWeight="normal"
                    p="8px"
                    placement="top-end"
                  >
                    <div style={{ width: "100%", position: "relative" }}>
                      <RiInformationFill
                        fontSize="16px"
                        color="red"
                        style={{ position: "absolute", right: "0" }}
                      />

                      <Input
                        id="responsible"
                        error={errors.responsible}
                        {...register("responsible", { required: true })}
                        label="Nome do responsável"
                        placeholder="Digite..."
                        type="string"
                      />
                    </div>
                  </Tooltip>
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>

          <Grid
            h="138px"
            w="100%"
            templateRows="repeat(2, 1fr)"
            gap={4}
            mt="30px"
            p="0px"
          >
            <GridItem rowSpan={1} justifySelf="flex-start">
              <Text fontWeight={'600'}>Informações de acesso</Text>
            </GridItem>
            <GridItem rowSpan={1}>
              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                <GridItem colSpan={1} w="380px">
                  <Input
                    label="E-mail"
                    placeholder="Digite seu e-mail"
                    type="string"
                  />
                </GridItem>

                <GridItem colSpan={2}>
                  {/* <SignUp /> */}
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>

          <Grid
            h="56px"
            w="98%"
            templateColumns="repeat(2, 1fr)"
            gap={4}
            position="absolute"
            bottom="0"
            m="0px"
          >
            <GridItem colSpan={1} justifySelf="flex-start">
              <Button
                colorScheme="blue"
                variant="outline"
                w="285px"
                h="100%"
                borderRadius="12px"
                onClick={handleCancelButton}
              >
                Cancelar
              </Button>
            </GridItem>
            <GridItem colSpan={1} justifySelf="flex-end">
              <Button
                onClick={handleSubmitTest}
                bg="#949494"
                color="white"
                variant="outline"
                w="285px"
                h="100%"
                borderRadius="12px"
                // isDisabled
              >
                Finalizar
              </Button>
            </GridItem>
          </Grid>
        </Container>
      </ModalContent>
    </Modal>
  );
};

export default CompanyInfo;