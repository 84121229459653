import React, { useEffect, useState } from "react";
import styled from "styled-components";

import getUrlParams from "../../services/getUrlParams";

import {
  Container,
  Stack,
  Text,
  Image,
  Flex,
  Tabs,
  Box,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
  FormLabel,
  Button,
  Spinner,
} from "@chakra-ui/react";
import SimpleSidebar from "../components/nav/Sidebar";
import Card from "../components/card/index";
import { Pagination } from "../components/pagination/index";
import ResultsTable from "./ResultsTable";
import { useCompanyResearchs } from "../../helpers/useCompanyResearchs";

import resultsSearch from "../../assets/images/results-search.svg";
import RankingTable from "./RankingTable";
import Translator from "../components/i18n/Translator";
import { useContext } from "react";
import { CustomContext } from "../../context/CustomContext";

const Layout = styled.div`
  margin-left: 32px;
  background: #f6f5fb;
`;

function Results({ validateRoute }) {
  const { document: doc } = getUrlParams("");
  const companyRegister = doc[0];
  const { userPermissions } = useContext(CustomContext);

  const { data, isLoading } = useCompanyResearchs(companyRegister);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (data !== undefined && data.length > 0) {
      const dataFiltered = data.filter((research) => {
        const endSurveyDate = new Date(research.application_date_end);
        const expirationSurveyDate = new Date(
          endSurveyDate.getTime()
        ).setFullYear(endSurveyDate.getFullYear() + 1);

        return new Date() <= expirationSurveyDate;
      });

      setTableData(dataFiltered);
    } else {
      return;
    }
  }, [data]);

  return !validateRoute(userPermissions, window.location.pathname) ? (
    <></>
  ) : (
    <Layout>
      <SimpleSidebar path="">
        <Container maxW="container.xl">
          <Card
            title={<Translator path={"results.card.title"} />}
            text={<Translator path={"results.card.description"} />}
            hasCloseButton
          />

          <Flex h="24px" mt="32px" align="center" justify={"space-between"}>
            <Text fontSize={"18px"} lineHeight="21px" fontWeight={700}>
              <Translator path={"results.title"} />
            </Text>

            {/* <Button bg="none" p={0}>
              <Image src={resultsSearch} alt="search" />
            </Button> */}
          </Flex>

          <Tabs variant="unstyled" mt="20px">
            <TabList color="#292B30">
              <Tab
                px={0}
                fontSize={"18px"}
                lineHeight="21px"
                _focus={{
                  border: "none",
                }}
                _selected={{
                  fontWeight: "700",
                  borderBottom: "3px solid #013280",
                  bg: "none",
                }}
              >
                <Translator path={"results.survey.title"} />
              </Tab>
              <Tab
                px={0}
                fontSize={"18px"}
                ml="40px"
                lineHeight="21px"
                _focus={{
                  border: "none",
                }}
                _selected={{
                  fontWeight: "700",
                  borderBottom: "3px solid #013280",
                  bg: "none",
                }}
              >
                <Translator path={"results.ranking.title"} />
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={0} pt="28px">
                {/* <Flex
                  h="62px"
                  gap="16px"
                  align={"end"}
                  mb="34px"
                  justify={"space-between"}
                >
                  <Stack w="25%" spacing={"8px"}>
                    <FormLabel
                      mb={0}
                      color="#292B30"
                      fontSize={"12px"}
                      lineHeight="14px"
                    >
                      Filtro
                    </FormLabel>
                    <Select
                      h="50px"
                      placeholder="Selecione"
                      fontSize="14px"
                      fontWeight="600"
                      mt="18px"
                      borderColor="#B8CAE8"
                      borderRadius="10px"
                      color="#292B30"
                    />
                  </Stack>
                  <Stack w="25%" spacing={"8px"}>
                    <FormLabel
                      mb={0}
                      color="#292B30"
                      fontSize={"12px"}
                      lineHeight="14px"
                    >
                      Filtro
                    </FormLabel>
                    <Select
                      h="50px"
                      placeholder="Selecione"
                      fontSize="14px"
                      fontWeight="600"
                      mt="18px"
                      borderColor="#B8CAE8"
                      borderRadius="10px"
                      color="#292B30"
                    />
                  </Stack>
                  <Stack w="25%" spacing={"8px"}>
                    <FormLabel
                      mb={0}
                      color="#292B30"
                      fontSize={"12px"}
                      lineHeight="14px"
                    >
                      Filtro
                    </FormLabel>
                    <Select
                      h="50px"
                      placeholder="Selecione"
                      fontSize="14px"
                      fontWeight="600"
                      mt="18px"
                      borderColor="#B8CAE8"
                      borderRadius="10px"
                      color="#292B30"
                    />
                  </Stack>
                  <Box color="#292B30" fontSize={"14px"}>
                    Visualizando <strong>0</strong> resultados de{" "}
                    <strong>10</strong>
                  </Box>
                </Flex> */}
                {isLoading ? (
                  <Box w="100%" textAlign="center" h="30vh" pt="20px">
                    <Spinner />
                  </Box>
                ) : (
                  <ResultsTable researchsList={tableData} />
                )}
                {/* <Pagination
                  totalCountOfRegisters={tableData.length}
                  currentPage={page}
                  onPageChange={setPage}
                /> */}
              </TabPanel>
              <TabPanel p={0} pt="28px">
                {/* <Flex
                  h="62px"
                  gap="16px"
                  align={"end"}
                  mb="34px"
                  justify={"space-between"}
                >
                  <Stack w="25%" spacing={"8px"}>
                    <FormLabel
                      mb={0}
                      color="#292B30"
                      fontSize={"12px"}
                      lineHeight="14px"
                    >
                      Filtro
                    </FormLabel>
                    <Select
                      h="50px"
                      placeholder="Selecione"
                      fontSize="14px"
                      fontWeight="600"
                      mt="18px"
                      borderColor="#B8CAE8"
                      borderRadius="10px"
                      color="#292B30"
                    />
                  </Stack>
                  <Stack w="25%" spacing={"8px"}>
                    <FormLabel
                      mb={0}
                      color="#292B30"
                      fontSize={"12px"}
                      lineHeight="14px"
                    >
                      Filtro
                    </FormLabel>
                    <Select
                      h="50px"
                      placeholder="Selecione"
                      fontSize="14px"
                      fontWeight="600"
                      mt="18px"
                      borderColor="#B8CAE8"
                      borderRadius="10px"
                      color="#292B30"
                    />
                  </Stack>
                  <Stack w="25%" spacing={"8px"}>
                    <FormLabel
                      mb={0}
                      color="#292B30"
                      fontSize={"12px"}
                      lineHeight="14px"
                    >
                      Filtro
                    </FormLabel>
                    <Select
                      h="50px"
                      placeholder="Selecione"
                      fontSize="14px"
                      fontWeight="600"
                      mt="18px"
                      borderColor="#B8CAE8"
                      borderRadius="10px"
                      color="#292B30"
                    />
                  </Stack>
                  <Text color="#292B30" fontSize={"14px"}>
                    Visualizando <b>04</b> resultados de <b>04</b>
                  </Text>
                </Flex> */}
                <RankingTable />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      </SimpleSidebar>
    </Layout>
  );
}

export default Results;
