import React, { useState } from 'react'
import styled from 'styled-components'
import { Icon } from '../..'


const Check = styled.div`
    cursor: pointer;
    text-align: center;
    border-radius: 20px;
    vertical-align: middle;

    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;
    
    color: #292B30;

    ${props =>{
        if(props.type === "radio"){
            return `
                background-color: #fff;  
                color: #292B30;  
                width: 106px;
                height: 32px;
            `
        }
        else{
            if(props.estado === true){
                return `
                    background-color: #013280;  
                    color: #fff;    
                    width: 73px;
                    height: 32px;
                `
            }
            else {
                return `
                    background-color: #E5E5E5;   
                    color: #292B30;  
                    width: 73px;
                    height: 32px;    
                `
            }
        }
    }}
`

function CheckboxStyled(props){
    const [estado, setEstado ] = useState(props.estado)
    console.log(props.estado)

    let lista = []
    let option
        if(props.type !== "radio"){
            lista = ["check-no", "check-yes"]
            option = "check"
        }
        else{
            lista = ["radio-no", "radio-yes"]
            option = "radio"
        }
    const [alternar, setAlternar] = useState(lista[0])
    return <Check  
                {...props}
                estado={estado}
                type={props.type}
                onClick={()=>{
                    setEstado(estado)
                    if(lista[0] === alternar){
                        setAlternar(lista[1])
                    }
                    else{
                        setAlternar(lista[0])
                    }
                }}
            >   
                <Icon type={alternar} />
                {props.label}
           </Check>
}

export default CheckboxStyled