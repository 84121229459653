import React, { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useBreakpointValue,
  Box,
  Link,
  Select,
} from "@chakra-ui/react";
import styled from "styled-components";
import { ModalReset } from "./modalReset";
import logo from "../../assets/images/logo.svg";
import login from "../../assets/images/login.png";
import logoJungle from "../../assets/images/Jungle-logo.svg";
import iconPassword from "../../assets/images/icon-password.svg";
import iconEye from "../../assets/images/icon-eye.svg";
import iconEyeClosed from "../../assets/images/icon-eyeclosed.svg";
import iconSelected from "../../assets/images/icon-arrow-down.svg";
import iconLogin from "../../assets/images/icon-login-email.svg";
import { authenticate } from "../../services/authServices";
import { HTTP_STATUS } from "../common/httpStatus";
import { setCookie, getCookie } from "../common/cookieManager";
import { Input } from "../components/input/index";
import { researchApi } from "../../services/api-research";
import Translator from "../components/i18n/Translator";
import ForgotPassword from "./modals/ForgotPassword";

import { useForm } from "react-hook-form";

import FirstAccess from "./modals/FirstAccess";
import FirstLogin from "./firstLogin";
import { CustomContext } from "../../context/CustomContext";
import { useTranslation } from "react-i18next";

const BtnHideModal1 = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  top: 32px;
  right: 32px;
`;

function Login() {
  const { setSystemLanguage, systemLanguage } = useContext(CustomContext);
  const { i18n, t } = useTranslation();

  const [active, setIsActive] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [firstLogin, setFirstLogin] = useState(false);
  const [userData, setUserData] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const homeRedirect = (companyRegister) => {
    const baseAppUrl =
      window["runConfig"].REACT_APP_JUNGLE_BASE_URL || "http://auth.domain.tld";
    window.location.replace(`${baseAppUrl}/certificates?cr=${companyRegister}`);
  };

  const CONNECT_BASE_URL =
    window["runConfig"].REACT_APP_JUNGLE_BASE_URL ||
    "http://connect.domain.tld/";

  useEffect(() => {
    const token = getCookie("credential");
    const company = getCookie("company");
    const firstAccess = getCookie("first_access");

    const currentLanguage = localStorage.getItem("i18nextLng");

    if (!!!currentLanguage) {
      localStorage.setItem("i18nextLng", "pt-BR");
      i18n.changeLanguage("pt-BR");
    }

    if (token && company && firstAccess === "false") {
      const companyParsed = JSON.parse(
        Buffer.from(company, "base64").toString("ascii")
      );
      homeRedirect(companyParsed.id);
    }
    const params = new URLSearchParams(window ? window.location.search : {});
    if (params.get("v")) {
      setRequestKey(params.get("v"));
      handleResetPasswordModal();
    }
  }, []);

  const [modalPassword, setModalPassword] = useState(false);
  const [firstAccessModal, setFirstAccessModal] = useState(false);

  let [requestKey, setRequestKey] = useState("");

  const [isErrorVisible, setErrorVisible] = useState(false);
  const [isCompanyUnavailable, setCompanyUnavailable] = useState(false);
  const [isMessageVisible, setMessage] = useState(false);

  const [modalUpdatePassword, setModalUpdatePassword] = useState(false);
  // const [modalUpdatePassword, setModalUpdatePassword] = useState(true);

  function handleResetPasswordModal(forceOpen = false) {
    if (forceOpen) {
      return setModalUpdatePassword(true);
    }
    return setModalUpdatePassword(!modalUpdatePassword);
  }

  const handleChangeLanguage = async (e) => {
    let newLanguage;
    if (!!e.target.value) {
      newLanguage = e.target.value;
    } else {
      newLanguage = "pt-BR";
    }

    localStorage.setItem("i18nextLng", newLanguage);
    i18n.changeLanguage(newLanguage);
    setSystemLanguage(newLanguage);
  };

  async function onSubmit(data) {
    setIsActive(true);
    setIsSubmitting(true);
    setCompanyUnavailable(false);
    setErrorVisible(false);

    const { email, password } = data;

    const authResponse = await authenticate(email, password);

    if (!authResponse.data) {
      setErrorVisible(true);
      setIsSubmitting(false);
      return;
    }

    let respUserData = await researchApi.get(`/users/company?email=${email}`);

    if (!respUserData.data[0]) {
      setCompanyUnavailable(true);
      setIsSubmitting(false);
      return;
    }
    respUserData = respUserData.data[0];

    const userPermissions = respUserData.roles.reduce((value, current) => {
      current.permissions.forEach((permission) => {
        value.push(permission.description);
      });
      return value;
    }, []);

    setUserData(respUserData);

    if (authResponse.data.refresh_token) {
      setErrorVisible(false);

      const credentials = {
        credential: {
          user: {
            id: respUserData.id,
            email: respUserData.email,
            name: respUserData.name,
            permissions: userPermissions,
          },
          access_token: authResponse.data.access_token,
          refresh_token: authResponse.data.refresh_token,
          session_state: authResponse.data.session_state,
        },
      };

      const currentLanguage = localStorage.getItem("i18nextLng");

      if (respUserData.language !== currentLanguage) {
        try {
          await researchApi.put(`/users/${respUserData.id}`, {
            language: currentLanguage,
            email: respUserData.email,
          });
        } catch (error) {
          console.error("Error ao atualizar info do usuário", error);
        }
      }

      ////
      setCookie("user_id", respUserData.id);
      setCookie("user_email", email);
      setCookie("first_access", respUserData.firstAccess);
      ////

      setCookie(
        "company",
        Buffer.from(JSON.stringify(respUserData.company), "ascii").toString(
          "base64"
        )
      );
      setCookie(
        "credential",
        Buffer.from(JSON.stringify(credentials), "ascii").toString("base64")
      );

      // await new Promise((r) => setTimeout(r, 100000));

      setFirstLogin(respUserData.firstAccess);

      if (!respUserData.firstAccess) {
        homeRedirect(respUserData.company.id);
      }
    } else if (authResponse.status === HTTP_STATUS.CREATED) {
      setMessage(true);
      setErrorVisible(false);
    } else {
      setErrorVisible(true);
      setMessage(false);
    }
  }

  const handleForgotPassword = () => {
    setModalPassword(true);
  };

  const handleCloseForgotPassword = () => {
    setModalPassword(false);
  };

  const handleFirstAccess = () => {
    setFirstAccessModal(true);
  };

  const handleCloseFirst = () => {
    setFirstAccessModal(false);
  };

  function showPass(event) {
    let img = event.target.offsetParent.offsetParent.childNodes[1].firstChild;
    let input = event.target.offsetParent.offsetParent.childNodes[2];
    if (input.type === "password") {
      input.type = "text";
      img.src = iconEyeClosed;
    } else {
      input.type = "password";
      img.src = iconEye;
    }
  }

  return firstLogin ? (
    <FirstLogin userData={userData} />
  ) : (
    <Stack
      minH={"100vh"}
      maxH={"100vh"}
      direction={{ base: "column", md: "row" }}
      bg="#ffffff"
    >
      <Box flex={1} w="60vw" h="100vh" backgroundColor="#EEEEF2">
        {/* <Image
          alt={"Login Image"}
          objectFit={"cover"}
          src={login}
          h="100%"
          w="100%"
        /> */}
        <Image
          margin="auto"
          marginTop="15%"
          alt={"Login Image"}
          // objectFit={"cover"}
          src={logoJungle}
          h="500px"
          w="500px"
        />
      </Box>
      <Flex
        p={2}
        flex={1}
        align={"center"}
        justify={"center"}
        position={"relative"}
        overflow="hidden"
      >
        <Stack spacing={6} w={"443px"} maxW={"lg"}>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}>
            <Text fontSize="28px">
              <Translator path="login.welcome" />
            </Text>{" "}
          </Heading>

          <Stack spacing={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                label={<Translator path="global.form.email.title" />}
                placeholder={t("global.form.email.helper")}
                type="email"
                icon={<img src={iconLogin} alt="icon e-mail" />}
                {...register("email")}
              />

              <Input
                label={<Translator path="login.form.password.title" />}
                placeholder={t("login.form.password.helper")}
                type="password"
                icon={<img src={iconPassword} alt="icon password" />}
                iconRight={
                  <img
                    onClick={(e) => showPass(e)}
                    src={iconEye}
                    alt="icon eye"
                  />
                }
                {...register("password")}
              />
              <Flex
                justify={"right"}
                w="100%"
                h="16px"
                pt="8px"
                color="#292B30"
              >
                {/* <Checkbox size="sm" colorScheme="blue">
                  Lembrar senha
                </Checkbox> */}
                <Button
                  w="154px"
                  h="56px"
                  color="#013280"
                  fontSize="14px"
                  variant="link"
                  // rightIcon={<img src={iconLink} alt="icon chevron" />}
                  lineHeight={"16px"}
                  fontWeight="600"
                  onClick={handleForgotPassword}
                  _hover={{
                    color: "#013280",
                  }}
                  _focus={{
                    color: "#292B30",
                    fontWeight: "400",
                  }}
                >
                  <Translator path="login.recovery.title" />
                </Button>
              </Flex>
              <Button
                mt="30px"
                type="submit"
                w="100%"
                isLoading={isSubmitting}
                color="white"
                fontSize="16px"
                lineHeight={"19px"}
                borderRadius="10px"
                h="56px"
                backgroundColor={!active ? "#949494" : "#013280"}
                variant="solid"
                _hover={{
                  opacity: "0.2",
                }}
              >
                <Translator path="login.form.submit" />
              </Button>
            </form>

            {isErrorVisible && (
              <Text fontSize="14px">
                <Translator path="login.form.error.invalid" />
              </Text>
            )}

            {isCompanyUnavailable && (
              <Text fontSize="14px">
                <Translator path="login.form.error.notRegistered" />
              </Text>
            )}

            {/* {isMessageVisible && (
              <div>
                Primeiro acesso. Favor verificar o email para resetar a senha
              </div>
            )} */}
          </Stack>
        </Stack>
        {/* <Box
          w="100%"
          position="absolute"
          bottom="0"
          background="#f1f5fa"
          display="flex"
          justifyContent="center"
          alignItems={"center"}
          textAlign={"center"}
          height="82px"
        >
          <Text fontSize={"15px"} lineHeight="21px">
            Caso tenha feito sua <b>compra</b> com algum de nossos{" "}
            <b>consultores</b>, <br /> faça seu
            <Link
              as="button"
              fontSize={"15px"}
              textDecor="underline"
              color="#013280"
              lineHeight="21px"
              fontWeight="600"
              ml="5px"
              onClick={handleFirstAccess}
            >
              primeiro acesso aqui
            </Link>
          </Text>
        </Box> */}
      </Flex>
      <Flex
        ml={{ base: 0, md: 20 }}
        px={{ base: 2, md: 10 }}
        py={{ base: 0, md: 5 }}
        height="64px"
        justifyContent={{ base: "flex-end", md: "flex-end" }}
        _focus={{ boxShadow: "none" }}
      >
        <Select
          fontSize="14px"
          fontWeight="400"
          lineHeight={"16.44px"}
          icon={<img src={iconSelected} />}
          placeholder="pt-BR"
          borderRadius="10px"
          background={"#F1F5FA"}
          boxSizing="border-box"
          defaultValue="pt-BR"
          width="90px"
          height="36px"
          position={"absolute"}
          border="none"
          onChange={(e) => handleChangeLanguage(e)}
          _focus={{
            border: "none",
          }}
          value={systemLanguage}
        >
          <option value="en-US">en-US</option>
          <option value="es">es</option>
        </Select>
      </Flex>
      <ForgotPassword
        isOpen={modalPassword}
        onClose={handleCloseForgotPassword}
        fechar={handleCloseForgotPassword}
      />
      <FirstAccess
        onClose={handleCloseFirst}
        isOpen={firstAccessModal}
        url={CONNECT_BASE_URL}
      />

      <ModalReset
        isOpen={modalUpdatePassword}
        requestKey={requestKey}
        onClose={handleResetPasswordModal}
        setModalUpdatePassword={setModalUpdatePassword}
        // disabled={true}
      />

      <BtnHideModal1 onClick={handleResetPasswordModal}>
        {/* <Icon type="close-gray-circle" /> */}
      </BtnHideModal1>
    </Stack>
  );
}

export default Login;
