/* eslint-disable no-unused-vars */
import React from "react";
import { Heading, Text, Box } from "@chakra-ui/react";

function HeaderGray(props) {
  function NewlineText(text) {
    return text.split("\\n").map((str) => <p>{str}</p>);
  }

  return (
    <Box
      w="100%"
      background="#e2e1e7"
      py="30px"
      px="24px"
      borderRadius="12px"
      color="black"
    >
      <Heading fontSize="1.5rem" mb="16px">
        {props.title}
      </Heading>
      <Text fontSize="14px">{NewlineText(props.text)}</Text>
      <Text fontSize="14px">{props.textDescription}</Text>
    </Box>
  );
}

export default HeaderGray;
