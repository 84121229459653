import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Translator from "../components/i18n/Translator";
import { Container, Heading, Box, Spinner } from "@chakra-ui/react";

import getUrlParams from "../../services/getUrlParams";
import { researchApi } from "../../services/api-research";
import CompanyInput from "../components/company-input";
import SuccessModal from "../components/company-input/SuccessModal";
import { CustomContext } from "../../context/CustomContext";

const Layout = styled.div``;

const Company = ({ validateRoute }) => {
  const { userPermissions } = useContext(CustomContext);

  const { document, key } = getUrlParams("");
  const companyRegister = document[0];
  const [companyData, setCompanyData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleCloseSuccess = () => {
    const baseAppUri =
      window["runConfig"].REACT_APP_JUNGLE_BASE_URL || "http://auth.domain.tld";
    window.location.replace(`${baseAppUri}/registration?cr=${companyRegister}`);

    setSuccess(false);
  };

  useEffect(() => {
    async function getCompany() {
      try {
        setIsLoading(true);
        const { data } = await researchApi.get(`/companies/${companyRegister}`);
        console.log("data empresa", data);

        setCompanyData(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error", error);
      }
    }

    getCompany();
  }, [companyRegister]);

  return !validateRoute(userPermissions, window.location.pathname) ? (
    <></>
  ) : (
    <Layout>
      {isLoading === true ? (
        <Box w="100%" textAlign="center" h="30vh" pt="20px">
          <Spinner />
        </Box>
      ) : (
        <Container
          minH={"100vh"}
          maxH={"100vh"}
          maxW="container.xl"
          paddingTop="60px"
          position="relative"
        >
          <Heading as="h2" fontSize="28px" marginBottom="60px">
            <Translator path={"company.edit.title"} />
          </Heading>

          <CompanyInput
            companyData={companyData}
            setCompanyData={setCompanyData}
            setSuccess={setSuccess}
            onClose={handleCloseSuccess}
          />

          <SuccessModal
            isOpen={success}
            onClose={handleCloseSuccess}
            firstAccess={false}
          />
        </Container>
      )}
    </Layout>
  );
};

export default Company;
