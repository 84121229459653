import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";

import {
  Faturas,
  InfoOrganizacao,
  Cadastro,
  Login,
  Pesquisas,
  Certificacao,
  Certificados,
  Results,
  Resume,
  Company,
  Registration,
  Colaboradores,
} from "./app/index";
import TagManager from "react-gtm-module";
import { getCookie } from "./app/common/cookieManager";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./services/queryCliente";

import { ChakraProvider } from "@chakra-ui/react";
import { GlobalStyle } from "./style";
import { theme } from "./theme/theme";
import "./styles.scss";
import { CustomProvider } from "./context/CustomContext";
import Help from "../src/app/components/help";
import { Suspense, useEffect, useState } from "react";
import Loader from "./app/components/loader";
import { PrivateRoute } from "./PrivateRoute";
import CertificadosPublicos from "./app/certificadosPublico";

const tagManagerArgs = {
  gtmId: "GTM-WFHXNPQ",
};

TagManager.initialize(tagManagerArgs);

function App() {
  function isValidToken() {

    let token = getCookie("credential");
    if(!token && !window.location.pathname.includes('/certified-company')){
      if (!token && window.location.pathname !== "/login") {
        window.location.replace("/login");
      } 
    }
    return token ? true : false;
  }
  isValidToken();
  return (
    <QueryClientProvider client={queryClient}>
      <CustomProvider>
        <ChakraProvider resetCSS theme={theme}>
          <Suspense fallback={<Loader />}>
            <Help title="Help" />
            <GlobalStyle />
            <Router>
              <Switch>
                {/* <Route path="/bills">
                <Faturas />
              </Route> */}

                {/* <Route path="/registration/profile/info"> */}


                {/* <Route path="/registration/profile"><PerfilOrganizacional /></Route> */}

                <PrivateRoute path="/registration/company" component={Company} />

                <PrivateRoute path="/registration" component={Registration} />

                <PrivateRoute path="/researches/certification" component={Certificacao} />

                <PrivateRoute path="/researches" component={Pesquisas} />

                <PrivateRoute path="/certificates" component={Certificados} />

                <PrivateRoute path="/results/resume" component={Resume} />

                <PrivateRoute path="/collaborators" component={Colaboradores} />

                <PrivateRoute path="/results" component={Results} />

                <Route path="/login">
                  <Login />
                </Route>

                <Route path="/certified-companys=:id">
                  <CertificadosPublicos />
                </Route>

                {/* <Redirect from="*" to="/login" /> */}
              </Switch>
            </Router>
          </Suspense>
        </ChakraProvider>
      </CustomProvider>
    </QueryClientProvider>
  );
}

export default App;
