/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from "react";

import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactDatePicker, { registerLocale } from "react-datepicker";

import { ptBR, enUS, es } from "date-fns/locale";
import { useMutation } from "react-query";

import "react-datepicker/dist/react-datepicker.css";
import iconBar from "../../../../assets/images/icon-bar.svg";
import pending from "../../../../assets/images/icon-pending-red.svg";
import iconCalendar from "../../../../assets/images/icon-calendar.svg";
import iconCalendarSecond from "../../../../assets/images/icon-calendar-white.svg";
import {
  addDays,
  format,
  parseISO,
  formatISO,
  isBefore
} from "date-fns";

import { queryClient } from "../../../../services/queryCliente";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  ModalFooter,
  Box,
  Flex,
  Image,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import { Icon } from "../../../../style";
import { researchApi } from "../../../../services/api-research";
import { useResearch } from "../../../../helpers/useResearch";
import Translator from "../../../components/i18n/Translator";
import StrongTranslator from "../../../components/i18n/StrongTranslator";
import { CustomContext } from "../../../../context/CustomContext";

const schema = Yup.object().shape({
  from: Yup.string().required(<Translator path={"global.error.required"} />),
});

const InitialDateModal = ({
  onClose,
  isOpen,
  researchId,
  initialDate,
  endDate,
  data,
  onSuccess,
}) => {
  const { systemLanguage } = useContext(CustomContext);
  const [success, setSuccess] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [conectionError, setConectionError] = useState(false);
  const { data: researchsData } = useResearch(researchId);
  const [loading, setLoading] = useState(false);
  const [locale, setLocale] = useState(ptBR);

  const updateDate = useMutation(
    async (researchDate) => {
      const response = await researchApi.put(`/research/${researchId}`, {
        ...researchDate,
      });
      console.log("response", response);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("research");
      },
    }
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [endDateInput, setEndDateInput] = useState(endDate);

  useEffect(() => {
    if (endDate !== null && typeof endDate === "string") {
      setEndDateInput(endDate);
    }
  }, [endDate]);

  registerLocale("pt-BR", ptBR);
  registerLocale("en-US", enUS);
  registerLocale("es", es);

  useEffect(() => {
    if (systemLanguage === "en-US") {
      setLocale(enUS);
    } else if (systemLanguage === "es") {
      setLocale(es);
    } else {
      setLocale(ptBR);
    }

    if (!!data.period.from) {
      const selectedStartDate = parseISO(data.period.from)
      handleChange(selectedStartDate)
    }

  }, [locale, systemLanguage]);

  const onSubmit = async (values) => {
    setLoading(true);
    if (errorDate) return;
    const startDate = formatISO(new Date(values.from));
    const endDate = addDays(new Date(values.from), 14);

    const updateValues = {
      ...researchsData.research,
      application_date: startDate,
      application_date_end: formatISO(new Date(endDate)),
    };

    try {
      const res = await updateDate.mutateAsync(updateValues);

      setLoading(false);
      setSuccess(true);
      onSuccess();
      // if (res.status === 200 || res.status === 201) {
      //   setSuccess(true);
      // }
    } catch (err) {
      console.log(err);
      setConectionError(true);
      setLoading(false);
    }
    // setSuccess(true);
  };

  const handleChange = (date) => {
    const today = new Date();
    if (isBefore(date, today)) {
      setErrorDate(true);
    } else {
      setErrorDate(false);
    }

    const formattedDate = addDays(new Date(date), 14);

    const endDate = format(new Date(formattedDate), "P", {
      locale: locale,
    });
    setEndDateInput(endDate);
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setSuccess(false);
      setConectionError(false);
    }, 1000);
  };

  return success ? (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent w="552px" p="0px" borderRadius="20px" overflow="hidden">
        <ModalBody p="60px">
          <Box display={"flex"} flexDir="column" alignItems="center" gap="24px">
            <Icon type="check-green" width="100px" height="100px" />

            <Heading
              fontSize="24px"
              lineHeight="33.6px"
              fontWeight="700"
              textAlign="center"
            >
              <StrongTranslator
                path={"survey.config.date.modal.success.title"}
              />
            </Heading>
            <Text color="#5C6066" textAlign="center">
              <Translator
                path={"survey.config.date.modal.success.description"}
              />
            </Text>
          </Box>
        </ModalBody>
        <ModalFooter width="100%" p={0} height="69px">
          <Button
            width="100%"
            color="white"
            background="#013280"
            borderRadius={0}
            height="69px"
            onClick={handleClose}
          >
            <Translator path={"global.modal.close"} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent borderRadius="12px" w="853px" py="26px" px="10px">
        <ModalCloseButton />

        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box h="300px">
              <Box>
                <img src={iconBar} alt="icon" />
                <Text color="#292B30" fontWeight="600" mt="10px">
                  <Translator path={"survey.config.date.modal.title"} />
                </Text>

                <Text as="h4" fontSize="14px" lineHeight={"20px"} pt={2}>
                  <StrongTranslator
                    path={"survey.config.date.modal.description"}
                  />
                </Text>
              </Box>

              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={"18px"}
                mt="20px"
                h="78px"
                w="379px"
              >
                <GridItem w="100%" h="100%">
                  <Text fontSize="12px" color="#292B30" mb="5px">
                    <Translator path={"survey.config.date.start"} />
                  </Text>
                  <InputGroup>
                    <Controller
                      control={control}
                      name="from"
                      defaultValue={
                        initialDate !== null
                          ? parseISO(data.period.from)
                          : undefined
                      }
                      render={({ field }) => (
                        <ReactDatePicker
                          dateFormat="P"
                          locale={locale.code}
                          dateFormatCalendar="MMMM | yyyy"
                          placeholderText="--/--/--"
                          onChange={(date) => {
                            field.onChange(date);
                            handleChange(date);
                          }}
                          selected={field.value}
                        />
                      )}
                    />
                  </InputGroup>
                </GridItem>
                <GridItem w="100%" h="100%" fontSize="14px" borderRadius="12px">
                  <Text fontSize="12px" color="#292B30" mb="5px">
                    <Translator path={"survey.config.date.end"} />
                  </Text>

                  <InputGroup>
                    <Input
                      bg="rgba(92, 96, 102, 0.8)"
                      color="#B8CAE8"
                      value={endDateInput}
                      w="100%"
                      h="50px"
                      fontSize="14px"
                      borderColor="#B8CAE8"
                      borderRadius="10px"
                      fontWeight="600"
                    />
                    <InputRightElement h="100%" mr="10px" pointerEvents="none">
                      <Image src={iconCalendarSecond} alt="icon" h="23px" />
                    </InputRightElement>
                  </InputGroup>
                </GridItem>
              </Grid>

              {errorDate && (
                <Text
                  fontSize="12px"
                  fontWeight="600"
                  pt="5px"
                  pl="5px"
                  color="red.100"
                >
                  <Translator path={"survey.config.date.invalid"} />
                </Text>
              )}

              {conectionError && (
                <Text
                  fontSize="12px"
                  fontWeight="600"
                  pt="5px"
                  pl="5px"
                  color="red.100"
                >
                  <Translator path={"survey.config.date.modal.error"} />
                </Text>
              )}
              <Flex mt="20px" gap="10px" h="36px" alignItems="center">
                <Image src={pending} alt="icon pending" h="16px" />
                <Text fontSize={"12px"} lineHeight="18px">
                  <StrongTranslator
                    path={"survey.config.date.modal.observation1"}
                  />
                </Text>
              </Flex>
              <Flex mt="10px" gap="10px" h="36px" alignItems="center">
                <Image src={pending} alt="icon pending" h="16px" />
                <Text fontSize={"12px"} lineHeight="18px">
                  <StrongTranslator
                    path={"survey.config.date.modal.observation2"}
                  />
                </Text>
              </Flex>
            </Box>

            <Flex h="52px" w="100%" justifyContent={"space-between"} mt="16px">
              <Button
                w="265px"
                h="50px"
                variant="outline"
                color="red.100"
                borderColor="red.100"
                borderRadius="10px"
                onClick={handleClose}
              >
                <Translator path={"global.modal.cancel"} />
              </Button>
              <Button
                type="submit"
                bg="#013280"
                disabled={!!conectionError}
                w="265px"
                color="#ffffff"
                borderRadius="10px"
                h="50px"
                isLoading={loading}
              >
                <Translator path={"global.modal.save"} />
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InitialDateModal;
