/* eslint-disable no-unused-vars */
import { FC, useEffect, useState } from "react";

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { queryClient } from "../../../../services/queryCliente";

import iconBar from "../../../../assets/images/icon-bar.svg";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  ModalFooter,
  Box,
  Flex,
  Tag,
  Grid,
  GridItem,
  Stack,
  InputGroup,
  FormLabel,
} from "@chakra-ui/react";
import { Input } from "./Input";
import { Icon } from "../../../../style";
import { researchApi } from "../../../../services/api-research";

const schema = Yup.object().shape({
  manager: Yup.string().required("Campo obrigatório"),
  people: Yup.string().required("Campo obrigatório"),
});

const LanguageModal = ({ onClose, isOpen, researchId, data }) => {
  const {
    register,
    handleSubmit,
    watch,
    // formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [success, setSuccess] = useState(false);
  const [updateAction, setUpdateAction] = useState(false);

  const createLanguage = useMutation(
    async (values) => {
      const response = await researchApi.post(`/organization-language`, {
        ...values,
      });

      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("language");
      },
    }
  );

  const updateLanguage = useMutation(
    async (values) => {
      const response = await researchApi.put(
        `/organization-language/${data[0].id}`,
        {
          ...values,
        }
      );

      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("language");
      },
    }
  );

  const onSubmit = async (values) => {
    const model = {
      manager: values.manager,
      company: values.company,
      collaborator: values.people,
      research: researchId,
    };
    // retorna um id
    try {
      let res;

      updateAction
        ? (res = await updateLanguage.mutateAsync(model))
        : (res = await createLanguage.mutateAsync(model));

      if (res.status === 200 || res.status === 201) {
        setSuccess(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setUpdateAction(true);
    }
  }, [data]);

  return success ? (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent w="581px" p="0px" borderRadius="20px" overflow="hidden">
        <ModalBody p="60px">
          <Box display={"flex"} flexDir="column" alignItems="center" gap="24px">
            <Icon type="check-green" width="100px" height="100px" />

            <Heading fontSize="24px" fontWeight="700">
              Personalização salva com sucesso
            </Heading>
          </Box>
        </ModalBody>
        <ModalFooter width="100%" p={0} height="69px">
          <Button
            width="100%"
            color="white"
            background="#013280"
            borderRadius={0}
            height="69px"
            onClick={handleClose}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: "30px",
          width: "1112px",
          padding: "10px",
          height: "552px",
        }}
      >
        <ModalCloseButton />

        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <img src={iconBar} alt="icon" />
            <Heading fontSize="18px" fontWeight="600" mt="8px" mb="24px">
              Personalização da Pesquisa de Clima
            </Heading>

            <Box
              h="365px"
              p={5}
              border="1px"
              borderColor="#CCCCCC"
              borderRadius="12px"
            >
              <Box>
                <Tag
                  bg="#e5ebec"
                  color="#002070"
                  borderRadius="20px"
                  h="23px"
                  px="15px"
                  fontWeight="600"
                >
                  Linguagem organizacional
                </Tag>
                <Text as="h4" fontSize="14px" lineHeight={"20px"} pt={2}>
                  Selecione as <b>terminologias </b> utilizadas em sua empresa.
                </Text>
              </Box>

              <Grid templateColumns="repeat(3, 1fr)" gap={"20px"} mt="20px">
                <GridItem
                  w="312px"
                  h="240px"
                  border="1px"
                  borderColor="#CCCCCC"
                  fontSize="14px"
                  borderRadius="12px"
                  p="20px"
                >
                  <Text fontSize="14px" mb="20px">
                    Como são chamados os <b>Gestores?</b>
                  </Text>
                  <Stack spacing="12px">
                    <InputGroup
                      bg={watch("manager") === "Chefes" ? "red.100" : "#E5E5E5"}
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("manager")}
                        value="Chefes"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize="14px"
                        color={
                          watch("manager") === "Chefes" ? "white" : "#292B30"
                        }
                      >
                        Chefes
                      </FormLabel>
                    </InputGroup>

                    <InputGroup
                      bg={
                        watch("manager") === "Gestores" ? "red.100" : "#E5E5E5"
                      }
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("manager")}
                        value="Gestores"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize="14px"
                        color={
                          watch("manager") === "Gestores" ? "white" : "#292B30"
                        }
                      >
                        Gestores
                      </FormLabel>
                    </InputGroup>

                    <InputGroup
                      bg={
                        watch("manager") === "Líderes" ? "red.100" : "#E5E5E5"
                      }
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("manager")}
                        value="Líderes"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize="14px"
                        color={
                          watch("manager") === "Líderes" ? "white" : "#292B30"
                        }
                      >
                        Líderes
                      </FormLabel>
                    </InputGroup>
                  </Stack>
                </GridItem>
                <GridItem
                  w="312px"
                  h="240px"
                  border="1px"
                  borderColor="#CCCCCC"
                  fontSize="14px"
                  borderRadius="12px"
                  p="20px"
                >
                  <Text fontSize="14px" mb="20px">
                    Como são chamadas as <b>Pessoas?</b>
                  </Text>

                  <Stack spacing="12px">
                    <InputGroup
                      bg={
                        watch("people") === "Colaboradores"
                          ? "red.100"
                          : "#E5E5E5"
                      }
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("people")}
                        value="Colaboradores"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize="14px"
                        color={
                          watch("people") === "Colaboradores"
                            ? "white"
                            : "#292B30"
                        }
                      >
                        Colaboradores
                      </FormLabel>
                    </InputGroup>

                    <InputGroup
                      bg={
                        watch("people") === "Empregados" ? "red.100" : "#E5E5E5"
                      }
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("people")}
                        value="Empregados"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize="14px"
                        color={
                          watch("people") === "Empregados" ? "white" : "#292B30"
                        }
                      >
                        Empregados
                      </FormLabel>
                    </InputGroup>

                    <InputGroup
                      bg={
                        watch("people") === "Funcionarios"
                          ? "red.100"
                          : "#E5E5E5"
                      }
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("people")}
                        value="Funcionarios"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize="14px"
                        color={
                          watch("people") === "Funcionarios"
                            ? "white"
                            : "#292B30"
                        }
                      >
                        Funcionários
                      </FormLabel>
                    </InputGroup>

                    <InputGroup
                      bg={
                        watch("people") === "Integrantes"
                          ? "red.100"
                          : "#E5E5E5"
                      }
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("people")}
                        value="Integrantes"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize="14px"
                        color={
                          watch("people") === "Integrantes"
                            ? "white"
                            : "#292B30"
                        }
                      >
                        Integrantes
                      </FormLabel>
                    </InputGroup>
                  </Stack>
                </GridItem>
                <GridItem
                  w="312px"
                  h="240px"
                  border="1px"
                  borderColor="#CCCCCC"
                  fontSize="14px"
                  borderRadius="12px"
                  p="20px"
                >
                  <Text fontSize="14px" mb="18px">
                    Como é chamada a <b>Empresa?</b>
                  </Text>

                  <Stack spacing="12px">
                    <InputGroup
                      bg={
                        watch("company") === "Companhia" ? "red.100" : "#E5E5E5"
                      }
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("company")}
                        value="Companhia"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize="14px"
                        color={
                          watch("company") === "Companhia" ? "white" : "#292B30"
                        }
                      >
                        Companhia
                      </FormLabel>
                    </InputGroup>

                    <InputGroup
                      bg={
                        watch("company") === "Empresa" ? "red.100" : "#E5E5E5"
                      }
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("company")}
                        value="Empresa"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize="14px"
                        color={
                          watch("company") === "Empresa" ? "white" : "#292B30"
                        }
                      >
                        Empresa
                      </FormLabel>
                    </InputGroup>

                    <InputGroup
                      bg={
                        watch("company") === "Organização"
                          ? "red.100"
                          : "#E5E5E5"
                      }
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("company")}
                        value="Organização"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize="14px"
                        color={
                          watch("company") === "Organização"
                            ? "white"
                            : "#292B30"
                        }
                      >
                        Organização
                      </FormLabel>
                    </InputGroup>
                  </Stack>
                </GridItem>
              </Grid>
            </Box>

            <Flex h="92px" w="100%" mt="24px" justifyContent={"space-between"}>
              <Button
                w="265px"
                h="50px"
                variant="outline"
                color="red.100"
                fontWeight="700"
                borderColor="red.100"
                borderRadius="10px"
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                w="265px"
                bg="#013280"
                color="#ffffff"
                borderRadius="10px"
                h="50px"
              >
                Salvar
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LanguageModal;
