/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

import {
  Container,
  Grid,
  GridItem,
  Heading,
  Tag,
  Text,
  Image,
  Flex,
  Box,
  Spinner,
} from "@chakra-ui/react";
import SimpleSidebar from "../components/nav/Sidebar";
import Steps from "./certficacao/Steps";

import Card from "../components/card/index";
import certificationLogo from "../../assets/images/icon-logo-certificacao.svg";
import backBlue from "../../assets/images/back-blue.svg";

import getUrlParams from "../../services/getUrlParams";
import { researchApi } from "../../services/api-research";
import Translator from "../components/i18n/Translator";
import { CustomContext } from "../../context/CustomContext";

const Layout = styled.div`
  margin-left: 32px;
  background: #f6f5fb;
`;

const makeId = () => {
  let id = "";

  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  for (let index = 0; index < 7; index++) {
    id += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return id;
};

const Pesquisas = ({ validateRoute }) => {
  const { document, key } = getUrlParams("");
  const { userPermissions } = useContext(CustomContext);

  const companyRegister = document[0];
  const [creatingRegistration, setCreatingRegistration] = useState(false);
  const [researchData, setResearchData] = useState([]);

  useEffect(() => {
    async function getResearches() {
      try {
        const response = await researchApi.get(
          `/research?id_company_register=${companyRegister}`
        );
        const companyResearchs = response.data.data;

        if (companyResearchs.length === 0 && !creatingRegistration) {
          setCreatingRegistration(true);

          handleResearchCreation();
        } else {
          const companyResearchsFiltered = companyResearchs.filter(
            (research) => {
              const endSurveyDate = new Date(research.application_date_end);
              const expirationSurveyDate = new Date(
                endSurveyDate.getTime()
              ).setFullYear(endSurveyDate.getFullYear() + 1);

              return (
                !!!research.application_date_end ||
                new Date() <= expirationSurveyDate
              );
            }
          );

          const sortedCompanyResearchs = companyResearchsFiltered.sort(
            (a, b) =>
              new Date(b.application_creation_date) -
              new Date(a.application_creation_date)
          );

          setResearchData(sortedCompanyResearchs);
        }
      } catch (error) {
        console.error("Error", error);
      }
    }

    getResearches();
  }, [creatingRegistration]);

  const researchRedirect = (companyRegister, researchId) => {
    const authApi = "http://auth.domain.tld";
    window.location.replace(
      `${authApi}/researches/certification?cr=${companyRegister}&st=${researchId}`
    );
  };

  const handleResearchCreation = async () => {
    const model = {
      status: "Active",
      salesforce_participation_code: makeId(),
      id_company_register: companyRegister,
      application_creation_date: new Date(),
    };

    const response = await researchApi.post(`/research`, model);

    if (response.status === 201 || response.status === 200) {
      setCreatingRegistration(false);
      // researchRedirect(companyRegister, response.data.id);
    }
  };

  return !validateRoute(userPermissions, window.location.pathname) ? (
    <></>
  ) : (
    <Layout>
      <SimpleSidebar path="">
        {creatingRegistration === true ? (
          <Box w="100%" textAlign="center" h="30vh" pt="20px">
            <Text fontWeight={500}>
              <Translator path={"survey.registering"} />
            </Text>
            <Spinner />
          </Box>
        ) : (
          <Container maxW="container.xl">
            <Card
              title={<Translator path={"survey.card.title"} />}
              text={<Translator path={"survey.card.description"} />}
              hasCloseButton
            />

            <Heading as="h4" fontSize={"18px"} mt="30px" mb="30px">
              <Translator path={"survey.title"} />
            </Heading>

            {researchData.length ? (
              researchData.map((research) => (
                <Grid
                  key={research.id}
                  templateColumns="repeat(4, 1fr)"
                  gap={4}
                  maxW="100%"
                  h="148px"
                  bg="white"
                  borderRadius={"12px"}
                  overflow="hidden"
                  mb="20px"
                  border="1px"
                  borderColor="#CCCCCC"
                >
                  <GridItem
                    colSpan={1}
                    w="350px"
                    backgroundSize="fill"
                    backgroundRepeat={"no-repeat"}
                    h="100%"
                    p="32px"
                    backgroundImage={backBlue}
                  >
                    <Tag
                      bg="#40BF91"
                      color="white"
                      fontSize="14px"
                      lineHeight="16px"
                      h="24px"
                      w="96px"
                    >
                      <Translator path={"survey.infoCard.title"} />
                    </Tag>
                    <Heading
                      as="h3"
                      color="white"
                      fontSize="18px"
                      lineHeight="21px"
                      mt="10px"
                      mb="10px"
                    >
                      <Translator path={"survey.infoCard.subtitle"} />
                    </Heading>

                    <RouterLink
                      to={
                        userPermissions.includes("SURVEY_EDIT")
                          ? `/researches/certification?cr=${companyRegister}&st=${research.id}`
                          : window.location.search
                      }
                      style={{
                        fontWeight: "300",
                        fontSize: "13px",
                        color: "white",
                        textDecoration: "underline",
                      }}
                    >
                      <Translator path={"survey.infoCard.config"} />
                    </RouterLink>
                  </GridItem>
                  <GridItem colSpan={3} h="100%" p="20px">
                    {research.status !== "Aprovada" ? (
                      <Steps researchId={research.id} />
                    ) : (
                      <Flex gap="36px">
                        <Image
                          src={certificationLogo}
                          alt="Logo certificação"
                          maxH="116px"
                        />
                        <Box>
                          <Text fontSize="15px" lineHeight="22.4px">
                            Parabéns!
                          </Text>
                          <Text
                            fontSize="15px"
                            fontWeight="600"
                            lineHeight="20.4px"
                            mt="5px"
                          >
                            Sua empresa
                            <br />
                            foi certificada!
                          </Text>

                          <Tag
                            fontSize="14px"
                            mt="10px"
                            bg="rgba(0, 32, 112, 0.1)"
                            borderRadius="6px"
                            color="#002070"
                            h="16px"
                            fontWeight="600"
                          >
                            Nota: 92
                          </Tag>
                        </Box>

                        <Box
                          fontSize="13px"
                          w="250px"
                          color="#292B30"
                          ml="20px"
                        >
                          <Text mb="10px" fontSize="13px" lineHeight="18.2px">
                            Agora que você conquistou a
                            <br />
                            <b>
                              Certificação GPTW, é hora de concorrer aos nossos
                              rankings!
                            </b>
                          </Text>
                          <Text fontSize="13px" lineHeight="18.2px">
                            Confira os
                            <Text
                              as="span"
                              color="red.100"
                              fontWeight="600"
                              textDecor="underline"
                              ml="4px"
                            >
                              Rankings disponíveis aqui
                            </Text>
                          </Text>
                        </Box>
                      </Flex>
                    )}
                  </GridItem>
                </Grid>
              ))
            ) : (
              <></>
            )}

            {/* <Grid
              templateColumns="repeat(4, 1fr)"
              gap={4}
              h="148px"
              bg="white"
              borderRadius={"12px"}
              overflow="hidden"
            >
              
              <GridItem
                colSpan={1}
                w="350px"
                backgroundImage={backPurple}
                backgroundSize="fill"
                backgroundRepeat={"no-repeat"}
                h="100%"
                p="32px"
              >
          
                <Heading
                  as="h3"
                  color="white"
                  fontSize="18px"
                  lineHeight="21px"
                  mt="10px"
                  mb="10px"
                >
                  Customize sua pesquisa
                </Heading>

                <Button
                  onClick={() => handleResearchCreation()}
                  style={{
                    fontWeight: "300",
                    fontSize: "13px",
                    color: "white",
                    textDecoration: "underline",
                    backgroundColor: "transparent"
                  }}
                >
                  Configurar nova pesquisa
                </Button>

                <RouterLink
                  to={`/researches?cr=${companyRegister}`}
                  style={{
                    fontWeight: "300",
                    fontSize: "13px",
                    color: "white",
                    textDecoration: "underline",
                  }}
                >
                  Configurar pesquisa
                </RouterLink>
              </GridItem>

              <GridItem
                colSpan={3}
                h="100%"
                display={"flex"}
                justifyContent="center"
                alignItems={"center"}
              >
                <Text fontSize={"14px"} fontWeight="600">
                  PRECISAMOS DAS INFORMAÇÕES SOBRE ESSA PESQUISA
                </Text>
              </GridItem>
            </Grid> */}
          </Container>
        )}
      </SimpleSidebar>
    </Layout>
  );
};

export default Pesquisas;
