import Cookies from 'universal-cookie';
const cookies = new Cookies();

const DOMAIN = window['runConfig'].REACT_APP_COOKIE_DOMAIN || ".domain.tld"


function setCookie(key, value) {
    cookies.set(key, value, { path: '/', domain: DOMAIN });
}

function getCookie(key) {
    return cookies.get(key)
}

function removeCookie(key) {
    cookies.remove(key, { path: '/', domain: DOMAIN })
}

export { setCookie, getCookie, removeCookie }

