/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

import {
  Container,
  Grid,
  GridItem,
  Heading,
  Tag,
  Text,
  Image,
  Flex,
  Box,
  Spinner,
  Button,
  Input,
  Checkbox,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Icon,
  ModalFooter,
} from "@chakra-ui/react";
import SimpleSidebar from "../components/nav/Sidebar";
import Translator from "../components/i18n/Translator";
import getUrlParams from "../../services/getUrlParams";
import { researchApi } from "../../services/api-research";
import CompanyInput from "../components/company-input";
import SuccessModal from "../components/company-input/SuccessModal";
import navBar from "../../assets/images/navbar-icon.svg";
import { CustomContext } from "../../context/CustomContext";
import { setCookie, setCurrentCompanyLogo } from "../common/cookieManager";

const Layout = styled.div`
  margin-left: 32px;
  background: #f6f5fb;
`;

const Registration = ({ validateRoute }) => {
  const { setCurrentCompanyLogo, userPermissions } = useContext(CustomContext);

  const { document, key } = getUrlParams("");
  const companyRegister = document[0];
  const [companyData, setCompanyData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [editCompany, setEditCompany] = useState(false);
  const [currentLogo, setCurrentLogo] = useState();

  const formatCNPJ = (cnpj) => {
    if (!!!cnpj) return "-";

    return cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  };

  const formatPhone = (phone) => {
    if (!!!phone) return "-";

    return phone.replace(/(\d{2})(\d{5})(\d{4})/, "$1 $2-$3");
  };

  const handleEditData = () => {
    console.log("CLICOU");
    setEditCompany(true);
  };

  useEffect(() => {
    async function getLogo() {
      setIsLoading(true);

      try {
        const response = await researchApi.get(
          `/companies/${companyData.id}/logo`,
          {
            responseType: "blob",
          }
        );

        const blob = new Blob([response.data], { type: "image/png" });
        const logoObject = URL.createObjectURL(blob);

        setCurrentLogo(logoObject);
        setCurrentCompanyLogo(logoObject);
      } catch (error) {
        console.error(
          `Erro ao recuperar a logo da empresa: ${companyData.id}`,
          error
        );
      } finally {
        setIsLoading(false);
      }
    }

    if (companyData.logoKey) {
      getLogo();
    }
  }, [companyData.id, companyData.logoKey, setCurrentCompanyLogo]);

  useEffect(() => {
    async function getCompany() {
      try {
        setIsLoading(true);
        const { data } = await researchApi.get(`/companies/${companyRegister}`);
        console.log("data empresa", data);

        delete data.users;

        setCompanyData(data);
        setCookie(
          "company",
          Buffer.from(JSON.stringify(data), "ascii").toString("base64")
        );
      } catch (error) {
        console.error("Error", error);
      }

      setIsLoading(false);
    }

    getCompany();
  }, [companyRegister]);

  return !validateRoute(userPermissions, window.location.pathname) ? (
    <></>
  ) : (
    <Layout>
      <SimpleSidebar path="" companyData={companyData}>
        {isLoading === true ? (
          <Box w="100%" textAlign="center" h="30vh" pt="20px">
            <Spinner />
          </Box>
        ) : (
          <Container maxW="container.xl" position="relative" marginTop="32px">
            <Heading as="h2" fontSize="18px">
              <Translator path={"company.subtitle"} />
            </Heading>

            <Box bgColor="#FFFFFF" w="1112px" h="331px" marginTop="32px">
              <Tag
                bg="rgba(0, 32, 112, 0.1);"
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                padding="2px 12px"
                gap="10px"
                position="absolute"
                w="213px"
                h="23px"
                borderRadius="20px"
                margin="32px"
              >
                <Text
                  color="#002070"
                  w="283px"
                  h="19px"
                  fontFamily="Raleway"
                  fontStyle="normal"
                  fontSize="16px"
                  lineHeight="19px"
                  fontWeight="600"
                  whiteSpace="nowrap"
                >
                  <Translator path={"company.title"} />
                </Text>
              </Tag>

              <Grid
                templateRows="repeat(3, 1fr)"
                templateColumns="repeat(5, 1fr)"
                mt="91px"
                position="absolute"
                w="1035px"
                h="208px"
                ml="32px"
                mr="45px"
                mb="32px"
              >
                <GridItem rowSpan={3} colSpan={1} justifySelf="flex-start">
                  {/* <Flex
                    boxSizing="border-box"
                    position="absolute"
                    w="197px"
                    h="208px"
                    border="1px"
                    borderStyle="dashed"
                    borderColor="#5C6066"
                    borderRadius="10px"
                    justifyContent="center"
                    alignItems="center"
                    gap="16px"
                  >
                  </Flex>
                   */}

                  <Flex
                    position="absolute"
                    w="150px"
                    h="190px"
                    borderColor="#B8CAE8"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {companyData.logoKey ? (
                      <Image src={currentLogo} alt="logo" position="absolute" />
                    ) : (
                      <Image
                        src={navBar}
                        alt="logo"
                        position="absolute"
                        w="153px"
                        h="144px"
                      />
                    )}
                  </Flex>
                </GridItem>

                <GridItem colSpan={3}>
                  <Grid templateColumns="repeat(3, 1fr)" gap={"68px"}>
                    <GridItem colSpan={1}>
                      <Text
                        w="134px"
                        h="28px"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="28px"
                        display="flex"
                        alignItems="center"
                        color="#292B30"
                        flex="none"
                        order="0"
                        flexGrow="0"
                      >
                        <Translator
                          path={"company.form.registryCode.buyer.title"}
                        />
                        :
                      </Text>

                      <Text
                        w="234px"
                        // h="18px"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="600"
                        fontSize="16px"
                        lineHeight="28px"
                        display="flex"
                        alignItems="center"
                        color="#292B30"
                        flex="none"
                        order="1"
                        flexGrow="0"
                      >
                        {formatCNPJ(companyData.registryCode)}
                      </Text>
                    </GridItem>

                    <GridItem colSpan={1}>
                      <Text
                        w="290px"
                        h="28px"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="28px"
                        display="flex"
                        alignItems="center"
                        color="#292B30"
                        flex="none"
                        order="0"
                        flexGrow="0"
                      >
                        <Translator
                          path={"company.form.registryCode.rival.title"}
                        />
                        :
                      </Text>

                      <Text
                        w="234px"
                        // h="18px"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="600"
                        fontSize="16px"
                        lineHeight="28px"
                        display="flex"
                        alignItems="center"
                        color="#292B30"
                        flex="none"
                        order="1"
                        flexGrow="0"
                      >
                        {formatCNPJ(companyData.rivalRegistryCode)}
                      </Text>
                    </GridItem>

                    <GridItem colSpan={1}>
                      <Text
                        w="85px"
                        h="28px"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="28px"
                        display="flex"
                        alignItems="center"
                        color="#292B30"
                        flex="none"
                        order="0"
                        flexGrow="0"
                      >
                        <Translator path={"company.form.name.title"} />:
                      </Text>

                      <Text
                        w="234px"
                        // h="18px"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="600"
                        fontSize="16px"
                        lineHeight="28px"
                        display="flex"
                        alignItems="center"
                        color="#292B30"
                        flex="none"
                        order="1"
                        flexGrow="0"
                      >
                        {companyData.name || "-"}
                      </Text>
                    </GridItem>
                  </Grid>
                </GridItem>

                <GridItem colSpan={3}>
                  <Grid
                    templateColumns="repeat(3, 1fr)"
                    gap={"68px"}
                    // position="absolute"
                  >
                    <GridItem colSpan={1}>
                      <Text
                        w="100px"
                        h="28px"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="28px"
                        display="flex"
                        alignItems="center"
                        color="#292B30"
                        flex="none"
                        order="0"
                        flexGrow="0"
                      >
                        <Translator path={"company.form.fantasyName.title"} />:
                      </Text>

                      <Text
                        w="234px"
                        // h="18px"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="600"
                        fontSize="16px"
                        lineHeight="28px"
                        display="flex"
                        alignItems="center"
                        color="#292B30"
                        flex="none"
                        order="1"
                        flexGrow="0"
                      >
                        {companyData.fantasyName || "-"}
                      </Text>
                    </GridItem>

                    <GridItem colSpan={1}>
                      <Text
                        w="66px"
                        h="28px"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="28px"
                        display="flex"
                        alignItems="center"
                        color="#292B30"
                        flex="none"
                        order="0"
                        flexGrow="0"
                      >
                        <Translator path={"company.form.address.title"} />:
                      </Text>

                      <Text
                        w="234px"
                        // h="18px"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="600"
                        fontSize="16px"
                        lineHeight="28px"
                        display="flex"
                        alignItems="center"
                        color="#292B30"
                        flex="none"
                        order="1"
                        flexGrow="0"
                        // whiteSpace="nowrap"
                      >
                        {companyData.address || "-"}
                      </Text>
                    </GridItem>

                    <GridItem colSpan={1} ml="55px">
                      <Text
                        w="140px"
                        h="28px"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="28px"
                        display="flex"
                        alignItems="center"
                        color="#292B30"
                        flex="none"
                        order="0"
                        flexGrow="0"
                      >
                        <Translator path={"company.form.phone.title"} />:
                      </Text>

                      <Text
                        w="234px"
                        // h="18px"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="600"
                        fontSize="16px"
                        lineHeight="28px"
                        display="flex"
                        alignItems="center"
                        color="#292B30"
                        flex="none"
                        order="1"
                        flexGrow="0"
                      >
                        {formatPhone(companyData.phone)}
                      </Text>
                    </GridItem>
                  </Grid>
                </GridItem>

                <GridItem colSpan={3}>
                  <Grid templateColumns="repeat(3, 1fr)" gap={"68px"}>
                    <GridItem colSpan={1}>
                      <Text
                        w="146px"
                        h="28px"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="28px"
                        display="flex"
                        alignItems="center"
                        color="#292B30"
                        flex="none"
                        order="0"
                        flexGrow="0"
                      >
                        <Translator path={"company.form.responsible.title"} />:
                      </Text>

                      <Text
                        w="234px"
                        // h="18px"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="600"
                        fontSize="16px"
                        lineHeight="28px"
                        display="flex"
                        alignItems="center"
                        color="#292B30"
                        flex="none"
                        order="1"
                        flexGrow="0"
                      >
                        {companyData.responsibleEmployee || "-"}
                      </Text>
                    </GridItem>

                    {/* <GridItem colSpan={1}>
                      <Text
                        w="211px"
                        h="28px"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="28px"
                        display="flex"
                        alignItems="center"
                        color="#292B30"
                        flex="none"
                        order="0"
                        flexGrow="0"
                      >
                        E-mail de acesso:
                      </Text>
                      <Text
                        w="234px"
                        h="18px"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="600"
                        fontSize="16px"
                        lineHeight="28px"
                        display="flex"
                        alignItems="center"
                        color="#292B30"
                        flex="none"
                        order="1"
                        flexGrow="0"
                      >
                        ivan_campos@mbox.com
                      </Text>
                    </GridItem> */}
                  </Grid>
                </GridItem>
              </Grid>
            </Box>

            <Box mt="32px">
              <Button
                left="847px"
                bg="#013280"
                color="white"
                variant="outline"
                w="265px"
                h="40px"
                borderRadius="10px"
                as={RouterLink}
                to={`/registration/company?cr=${companyRegister}`}
              >
                <Translator path={"company.edit.title"} />
              </Button>
            </Box>
          </Container>
        )}
      </SimpleSidebar>
    </Layout>
  );
};

export default Registration;
