import { Box, Spinner } from "@chakra-ui/react";

const Loader = () => {
  return (
    <Box w="100%" textAlign="center" h="30vh" pt="20px">
      <Spinner />
    </Box>
  );
};

export default Loader;
