/* eslint-disable no-unused-vars */
import { useEffect, useState, useContext } from "react";
import {
  Box,
  Flex,
  GridItem,
  Tag,
  Text,
  Button,
  Input,
  Image,
  VStack,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import iconSuccess from "../../../assets/images/icon-success.svg";
import ResearchCustomModal from "./Modals/ResearchCustom";
import { useListAffirmatives } from "../../../helpers/useListAffirmatives";
import { CustomContext } from "../../../context/CustomContext";

const Affirmatives = ({ researchId }) => {
  const { custom } = useContext(CustomContext);
  const [modal, setModal] = useState(false);

  const {
    data: affirmativeData,
    isLoading: isAffirmativeLoading,
    isFetched,
  } = useListAffirmatives(researchId);

  const [options, setOptions] = useState([]);
  const [success, setSuccess] = useState(false);

  useEffect(() => {    
    if (isFetched) {
      const findOptions = affirmativeData.filter(
        (af) => af.id_research.id === researchId
      );
      setSuccess(true);
      setOptions(findOptions);
    }
  }, [affirmativeData, researchId, isFetched]);

  console.log('findOptions ' + JSON.stringify(options))

  const handleModalResearch = () => {
    if (custom) {
      setModal(true);
    } else return;
  };

  const handleCloseModalResearch = () => {    
    setModal(false);
    window.location.reload()
  };

  return success ? (
    
    <GridItem
      h="277px"
      border="1px"
      borderColor="#CCCCCC"
      borderRadius="20px"
      p="20px"
    >
      <ResearchCustomModal
        previousData={options}
        isOpen={modal}
        onClose={handleCloseModalResearch}
        researchId={researchId}
      />
      <Flex justify={"space-between"} align="center">
        <Box>
          <Tag
            bg="#e5ebec"
            color="#002070"
            fontSize="16px"
            borderRadius="20px"
            h="23px"
            px="15px"
            fontWeight="600"
          >
            Afirmativas
          </Tag>
          <Text as="p" fontSize="14px" pt={1} mt="8px">
            É possível adicionar até <b>3 afirmativas </b> pré-determinadas a
            sua Pesquisa de Clima.
          </Text>
        </Box>
        <Button
          w="175px"
          variant="outline"
          color={!custom ? "#292B30" : "red.100"}
          border="1px"
          disabled={!custom}
          borderColor={!custom ? "#292B30" : "red.100"}
          borderRadius="10px"
          fontWeight="bold"
          onClick={handleModalResearch}
        >
          {!!options.length ? "Editar" : "Configurar"}
        </Button>
      </Flex>

      {!options.length ? (
        <VStack spacing={1} align="stretch" mt="18px">
          <Input
            placeholder="01. Configure uma afirmativa"
            w="100%"
            h="54px"
            fontSize="14px"
            borderColor="#5C6066"
            borderRadius="10px"
            borderStyle="dashed"
          />
          <Input
            placeholder="02. Configure uma afirmativa"
            w="100%"
            h="54px"
            borderColor="#5C6066"
            fontSize="14px"
            borderRadius="10px"
            borderStyle="dashed"
          />
          <Input
            placeholder="03. Configure uma afirmativa"
            w="100%"
            h="54px"
            borderColor="#5C6066"
            fontSize="14px"
            borderRadius="10px"
            borderStyle="dashed"
          />
        </VStack>
      ) : (
        <VStack spacing={1} align="stretch" mt="18px">
          <InputGroup display="flex" alignItems="center" h="54px">
            <Input
              readOnly
              w="100%"
              h="54px"
              defaultValue={options[0] === undefined ? "01. Configure uma afirmativa" : options[0].description}
              fontSize="14px"
              borderColor="#40BF91"
              borderRadius="12px"
            />
            <InputLeftElement
              children={
                <Image src={iconSuccess} alt="success" w="16px" h="16px" />
              }
              alignSelf="center"
              display={"flex"}
              alignItems="center"
              h="100%"
            />
          </InputGroup>
          <InputGroup display="flex" alignItems="center" h="54px">
            <Input
              readOnly
              w="100%"
              h="54px"
              defaultValue={options[1] === undefined ? "02. Configure uma afirmativa" : options[1].description}
              fontSize="14px"
              borderColor="#40BF91"
              borderRadius="12px"
            />
            <InputLeftElement
              children={
                <Image src={iconSuccess} alt="success" w="16px" h="16px" />
              }
              alignSelf="center"
              display={"flex"}
              alignItems="center"
              h="100%"
            />
          </InputGroup>
          <InputGroup display="flex" alignItems="center" h="54px">
            <Input
              readOnly
              w="100%"
              h="54px"
              defaultValue={options[2] === undefined ? "32. Configure uma afirmativa" : options[2].description}
              fontSize="14px"
              borderColor="#40BF91"
              borderRadius="12px"
            />
            <InputLeftElement
              children={
                <Image src={iconSuccess} alt="success" w="16px" h="16px" />
              }
              alignSelf="center"
              display={"flex"}
              alignItems="center"
              h="100%"
            />
          </InputGroup>
        </VStack>
      )}
    </GridItem>
  ): (<a></a>);
};

export default Affirmatives;
