import { useState, useContext } from "react";
import {
  Box,
  Flex,
  GridItem,
  Tag,
  Text,
  Button,
  Grid,
  Image,
} from "@chakra-ui/react";
import { CustomContext } from "../../../context/CustomContext";
import ResearchLanguageModal from "./Modals/ResearchLanguageModal";
import Translator from "../../components/i18n/Translator";
import iconSuccess from "../../../assets/images/icon-success.svg";
import iconRemove from "../../../assets/images/icon-remove-red.svg";

const ResearchLanguage = ({
  researchLanguages,
  researchId,
  setResearchLanguages,
}) => {
  const { custom } = useContext(CustomContext);

  const [modalOpen, setModalOpen] = useState(false);

  const handleSpecificModal = () => {
    if (custom) {
      setModalOpen(true);
    } else return;
  };

  const handleCloseSpecificModal = () => {
    setModalOpen(false);
  };

  const handleSuccess = (newLanguages) => {
    setResearchLanguages(newLanguages);
  };

  return (
    <GridItem
      borderColor="#CCCCCC"
      borderRadius="12px"
      p="9px"
      position="relative"
    >
      <ResearchLanguageModal
        researchLanguages={researchLanguages}
        onClose={handleCloseSpecificModal}
        isOpen={modalOpen}
        onSuccess={handleSuccess}
        researchId={researchId}
      />
      <Flex justify={"space-between"} align="center">
        <Box>
          <Tag
            bg="#e5ebec"
            color="#002070"
            fontSize="16px"
            borderRadius="20px"
            h="23px"
            px="15px"
            fontWeight="600"
          >
            <Translator path={"survey.config.personalization.language.title"} />
          </Tag>
          {/* <Text as="p" fontSize="14px" pt={1} mt="4px" w="195px">
            Aqui você poderá configurar os idiomas
            <b> disponíveis </b> para <b> pesquisa</b>.
          </Text> */}

          <GridItem colSpan={2} fontSize="14px" pt={6} mt="1px">
            <Grid templateColumns="repeat(2, 1fr)" gap={"18px"}>
              <GridItem colSpan={1}>
                <Flex>
                  {researchLanguages.includes("pt-BR") ? (
                    <Image src={iconSuccess} alt="selecionado" />
                  ) : (
                    <Image
                      w="16px"
                      h="21px"
                      src={iconRemove}
                      alt="não selecionado"
                    />
                  )}
                  <Text ml="5px">
                    <Translator
                      path={"survey.config.personalization.language.portuguese"}
                    />
                  </Text>
                </Flex>
              </GridItem>

              <GridItem colSpan={1}>
                <Flex>
                  {researchLanguages.includes("en-US") ? (
                    <Image src={iconSuccess} alt="selecionado" />
                  ) : (
                    <Image
                      w="16px"
                      h="21px"
                      src={iconRemove}
                      alt="não selecionado"
                    />
                  )}
                  <Text ml="5px">
                    <Translator
                      path={"survey.config.personalization.language.english"}
                    />
                  </Text>
                </Flex>
              </GridItem>
            </Grid>

            <Grid templateColumns="repeat(1, 1fr)" gap={"18px"}>
              <GridItem colSpan={1}>
                <Flex>
                  {researchLanguages.includes("es") ? (
                    <Image src={iconSuccess} alt="selecionado" />
                  ) : (
                    <Image
                      w="16px"
                      h="21px"
                      src={iconRemove}
                      alt="não selecionado"
                    />
                  )}
                  <Text ml="5px">
                    <Translator
                      path={"survey.config.personalization.language.spanish"}
                    />
                  </Text>
                </Flex>
              </GridItem>
            </Grid>
          </GridItem>
        </Box>

        <Button
          w="105px"
          variant="outline"
          color={!custom ? "#292B30" : "red.100"}
          border="1px"
          disabled={!custom}
          borderColor={!custom ? "#292B30" : "red.100"}
          borderRadius="10px"
          fontWeight="bold"
          onClick={handleSpecificModal}
          alignSelf="end"
        >
          <Translator path={"global.modal.config"} />
        </Button>
      </Flex>
    </GridItem>
  );
};

export default ResearchLanguage;
