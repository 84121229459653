import React, {useState} from 'react';
import styled from 'styled-components';
import Modal from 'react-modal'
import { Button, Grid, Icon, Select } from '../../../style';
import OPTIONSDAYS from '../../../helpers/expirationDaysHelper';

const TabStyle = styled.table`
    border: none;
    width: 100%;


    border-collapse: collapse;
    
    margin-top: 24px;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 24px;
    background-color:  #fff;
`

const LineBody = styled.tr`
    border-bottom: 1px solid #ccc;
`

const TheadStyled = styled.thead`
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;

    color: #292B30;
`

const TbodyStyled = styled.tbody`
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: #292B30;
`

const TagStatus = styled.span`
    background-color: ${props=>props.background ? props.background : '#fff'};
    color: ${props=>props.background ? '#fff' : '#000'}; 
    padding-top: 4px; 
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 6px;
`


const LineHead = styled.tr`
    background-color: #E1E1E7;
    border: none;
`
const ThHead = styled.th`
    padding-top: 13px;
    padding-bottom: 13px;
`

const TdBody = styled.td`
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 80px;
`

const BtnHide = styled.button`
    background-color: #fff;
    border: none;
`

const Title = styled.h1`
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;

    color: #292B30;
`

const ButtonFooter = styled.button`
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-align: center;

    color: #FFFFFF;


    padding-top: 19px;
    padding-bottom: 19px;


    border-radius: 0 0 20px 20px;
    border: 0px solid black;
    background-color: #013280;
    width: 581px;

    position: absolute;
    bottom: 0;
    left: 0;


    &:hover{
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

`
const ModalStyled8 = styled(Modal)`
    z-index: 1;

    margin-top: 15%;
    margin-left: 35%;

    width: 600px;
    height: 330px;

    background-color: #fff;

    position: relative;

    border-radius: 20px;
    border: 1px solid #ccc;
`
const ModalStyled9 = styled(Modal)`
    z-index: 1;

    margin-top: 15%;
    margin-left: 35%;

    width: 581px;
    height: 341px;

    background-color: #fff;

    position: relative;

    border-radius: 20px;
    border: 1px solid #ccc;
`
const TagStyled  = styled.span`
    padding: 1px;
    width: 20px;
    height: 5px;
    border-radius: 6px;
    background-color: #40BF91;
    margin-bottom: 8px;
    
`

function ToolTip(props){
    const Tooltip = styled.div`
        position: relative;
        display: inline-block;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:hover > div {
            visibility: visible;
        }
    `
    const TooltipText = styled.div`
        display: flex;
        widht: 120px;
        background-color: #545559;
        color: #fff;
        text-aling: center;
        border-radius:6px;

        position: absolute;
        z-index: 1;
        padding: 4px;

        margin-top: 120px;

        visibility: hidden;
    `

    return  <Tooltip {...props} >{props.children}
                <TooltipText>{props.label}</TooltipText>
            </Tooltip>
}

function TableCollabs(props){
    const [ modal8, setModal8 ] = useState(false)
    const [ modal9, setModal9 ] = useState(false)    
    const handleModal8 = ()=>setModal8(!modal8)
    const handleModal9 = ()=>setModal9(!modal9)

    const options = OPTIONSDAYS.expirationDays();
    
    return  <Grid row style={{width: '100%'}}>

                <TabStyle {...props}>
                        <TheadStyled>
                            <LineHead>    
                                {props.header.map((chave, indice)=>{
                                    return  <ThHead>
                                                {chave.item === 'Ações'? <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                                    {chave.item+' '}
                                                    <ToolTip style={{marginLeft: '6px'}} label="Visualizar Fatura"><Icon type="alert-gray" /></ToolTip>
                                                </div> : chave.item}
                                            </ThHead>
                                })}
                            </LineHead>
                        </TheadStyled>
                        <TbodyStyled>
                                {props.body.map((chave, indice)=>{
                                    return  <LineBody>
                                                <TdBody>{chave.id}</TdBody>
                                                <TdBody>{chave.emissao}</TdBody>
                                                <TdBody>{chave.vencimento}</TdBody>
                                                <TdBody>{chave.ciclo}</TdBody>
                                                <TdBody>{chave.valor}</TdBody>
                                                <TdBody>
                                                    {chave.status === 'paid' ?
                                                        <TagStatus background="#2CD9DD">Pagamento efetuado</TagStatus> :
                                                        <TagStatus background="#FF6700">Aguardando Pagamento </TagStatus>
                                                    }
                                                </TdBody>
                                                <TdBody>
                                                    {chave.icones ?
                                                        <span>                                                            
                                                            <BtnHide onClick={() => {
                                                                        window.open(
                                                                            chave.url,
                                                                            '_blank' 
                                                                        );
                                                                        }}>
                                                                <Icon type="bill"  />
                                                            </BtnHide>
                                                        </span>:
                                                        <span></span> 
                                                    }
                                                </TdBody>
                                            </LineBody>
                                })}                    
                        </TbodyStyled>
                        
                    </TabStyle>


                    <ModalStyled8 isOpen={modal8} onRequestClose={handleModal8}>
                        <Grid column spacing="space-between" style={{
                            position: 'absolute',
                            top: '32px',
                            left: '32px'
                        }}>
                            <TagStyled  />
                            <Title style={{ marginBottom: '29px'}}>Alterar data de vencimento</Title>
                            <Grid row >
                                <Select width="250px" background="#F5F5FB" options={options}  />     
                            </Grid>
                            <Grid row spacing="space-between" style={{marginTop: '60px'}}> 
                                <span style={{marginRight: '35px'}}>
                                    <Button label="Cancelar" type="outline"  width="265px" height="56px" onClick={handleModal8}/>
                                </span>
                                <Button label="Confirmar" width="245px" height="56px" onClick={handleModal9}/>
                            </Grid>
                        </Grid>
                    </ModalStyled8>
                    
                    <ModalStyled9 isOpen={modal9} onRequestClose={handleModal9}>
                        <Grid column  style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            top: '32px',
                            left: '0px',
                            width: '100%',
                        }}> 
                            <div style={{marginBottom: '24px', marginTop: '30px'}}>
                                <Icon type="check-green" width="100px" height="100px"  />
                            </div>
                            <Title>Alteração realizada com sucesso</Title>
                        </Grid>
                        
                        <ButtonFooter onClick={()=>{
                            handleModal8();
                            handleModal9();	
                        }}>
                            Fechar
                        </ButtonFooter>
                    </ModalStyled9>
                </Grid>
            
}

export default TableCollabs;