import axios from "axios";

// export const researchApi = axios.create({
//   baseURL: "https://connect-research.herokuapp.com",
//   auth: {
//     username: "connect@gptw.com.br",
//     password: "1234",
//   },
// });

const username = window['runConfig'].REACT_APP_RESEARCH_API_USER || "connect@gptw.com.br"
const password = window['runConfig'].REACT_APP_RESEARCH_API_PASS || "1234"

export const researchApi = axios.create({
  // baseURL: window['runConfig'].REACT_APP_URL_JUNGLE_RESEARCH_API || 'https://connect.gptw.info/api/certification/',
  baseURL: window['runConfig'].REACT_APP_URL_JUNGLE_RESEARCH_API || 'http://connect.domain.tld/api/certification/',
  auth: {
    username: username,
    password: password
  },
});
