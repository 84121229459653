import React from 'react'
import styled from 'styled-components'
import senha from './senha.svg'
import olho from './olho.svg'

const InputText = styled.input.attrs({type: 'password'})`

	font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    border: none;
    border: 1px solid #B8CAE8;
    box-sizing: border-box;
    border-radius: 10px;
    outline:  none;
    padding: 16px;
    width: 443px;
    height: 56px;

    ${props=>{
		if(props.icon === false){
			return `

			`
		}
		else {
			return `
			 	background-image: url(${senha});
			    background-repeat: no-repeat, no-repeat;
			    background-position: 16px 16px, 404px 17px;
			    text-indent: 36px;
			    vertical-algn: middle;
			    background-size: 20px;
			`
		}
	}}
`

const StyledContainer =styled.div`
  display: flex;
  flex-direction: column;

`

const StyleLabel = styled.label`
font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;

color: #292B30;
`



function InputPassword(props){
	return  <StyledContainer>
            <StyleLabel>{props.label}</StyleLabel>
            <InputText type="password" {...props} />         
          </StyledContainer>
}

export default InputPassword
