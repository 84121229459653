import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Heading,
  ModalFooter,
  Box,
} from "@chakra-ui/react";
import { Icon } from "../../../style";
import Translator from "../../components/i18n/Translator";

const SuccessModal = ({ onClose, isOpen, firstAccess }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent w="581px" p="0px" borderRadius="20px" overflow="hidden">
        <ModalBody p="60px">
          <Box display={"flex"} flexDir="column" alignItems="center" gap="24px">
            <Icon type="check-green" width="100px" height="100px" />

            <Heading fontSize="24px" fontWeight="700">
              {firstAccess ? (
                <Translator path={"company.firstAccess.success"} />
              ) : (
                <Translator path={"company.edit.success"} />
              )}
            </Heading>
          </Box>
        </ModalBody>
        <ModalFooter width="100%" p={0} height="69px">
          <Button
            width="100%"
            color="white"
            background="#013280"
            borderRadius={0}
            height="69px"
            onClick={handleClose}
          >
            <Translator path={"global.modal.close"} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SuccessModal;
