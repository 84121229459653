import React, { useEffect, useState } from 'react'
import {
  Button,
  Flex,
  Image,
  Spinner,
  Text
} from '@chakra-ui/react'
import logoJungle from "../../assets/images/Jungle-logo.svg";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import apiCertified from '../../services/api-public';
import { useQuery } from 'react-query';

const CertificadosPublicos = () => {
  let { id } = useParams();
  
  async function getCompany(){
    const response = await apiCertified.get(`/certification/research/info/${id}`)
    
    return response.data.fantasyName
  };

  async function getCertfied(){
    const response = await apiCertified.get(`/certification/labels/Research?type=jpeg&idResearch=${id}`, {
      responseType: 'arraybuffer'
    })

    const image = Buffer.from(response.data, 'binary').toString('base64');

    return image
  };

  function useCompany(){
    return useQuery(['company'], () => getCompany(), {
      refetchOnWindowFocus: false
    })
  }

  function useCertfied(){
    return useQuery(['certfied'], () => getCertfied(), {
      refetchOnWindowFocus: false
    })
  }

  const { data: company, isLoading:  companyLoading, isError: companyError } = useCompany();
  const { data: certfied, isLoading: certfiedLoading, isError: certfiedError } = useCertfied();

  if(companyLoading && certfiedLoading){
    return (
      <Flex justifyContent="center" alignItems="center" h="100vh">
        <Spinner fontSize="50px" />
      </Flex>
    )
  } else if (companyError && certfiedError){
    return (
      <Flex bg="#FFF" h="100vh" direction='column' alignItems="center" >
        <Button 
          as={'a'} 
          href='junglexp.com.br' 
          target='_blank' 
          bg='none' 
          position="fixed" 
          h="60px" 
          top={0} 
          _hover={{bg: 'none'}}
        >
          <Image src={logoJungle} alt="logo"/>
        </Button>
        <Text marginTop="60px">
          Nenhuma participação com certificado disponível encontrada.
        </Text>
        <Text>Saiba mais sobre o Jungle Medical e a Certificação <a style={{color: "red"}} href="https://www.gpmentalhealth.com.br/certificacao-em-saude-mental" target='_blank'>clicando aqui!</a></Text>
      </Flex>
    )
  } else {
    return (
      <Flex bg="#FFF" h="100vh" direction='column' alignItems='center' justifyContent='center'>
        <Button 
          as={'a'} 
          href='https://www.gpmentalhealth.com.br/certificacao-em-saude-mental' 
          target='_blank' 
          bg='none' 
          position="fixed" 
          h="60px" 
          top={0} 
          _hover={{bg: 'none'}}
        >
          <Image src={logoJungle} alt="logo"/>
        </Button>
        <Text fontSize="32px" marginTop="30px" fontWeight="bold">{company}</Text>
        <Image src={`data:image/jpeg;base64,${certfied}`} alt="logo-and-qrcode" maxWidth="440px"/>
        <Text>Saiba mais sobre o Jungle Medical e a Certificação <a style={{color: "red"}} href="https://www.gpmentalhealth.com.br/certificacao-em-saude-mental" target='_blank'>clicando aqui!</a></Text>
      </Flex>
    )
  }
  

}

export default CertificadosPublicos