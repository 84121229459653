import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import iconBar from "../../../../assets/images/icon-bar.svg";
import checkYes from "../../../../style/image/icons/icons/check-yes.svg";
import checkNo from "../../../../style/image/icons/icons/check-no.svg";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  ModalFooter,
  Box,
  Flex,
  Tag,
  Grid,
  GridItem,
  Stack,
  Image,
  useCheckbox,
  useCheckboxGroup,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { Icon } from "../../../../style";
import { researchApi } from "../../../../services/api-research";
import Translator from "../../../components/i18n/Translator";
import StrongTranslator from "../../../components/i18n/StrongTranslator";

const ResearchLanguageModal = ({
  onClose,
  isOpen,
  onSuccess,
  researchLanguages,
  researchId,
}) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const toast = useToast();

  const [success, setSuccess] = useState(false);

  const handleChecked = (languages) => {
    if (languages?.length === 0) {
      setValue([value[0]]);
      setChecked([value[0]]);
      return;
    }

    setChecked(languages);
  };

  const { getCheckboxProps, setValue, value } = useCheckboxGroup({
    onChange: handleChecked,
    defaultValue: ["pt-BR"],
  });

  useEffect(() => {
    setValue(researchLanguages);
  }, [researchLanguages, isOpen]);

  const onSubmit = async () => {
    try {
      const { data } = await researchApi.put(`/research/${researchId}`, {
        languages: checked,
      });

      setValue(data.languages);
      onSuccess(data.languages);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      toast({
        title: "Erro ao atualizar idiomas",
        description: <Translator path={"global.error.tryAgain"} />,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
  };

  const CustomCheckbox = (props) => {
    const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
      useCheckbox(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
      <Box as="label" {...htmlProps} cursor="pointer">
        <input {...input} />
        <Box
          {...checkbox}
          bg="#E5E5E5"
          w="140px"
          h="32px"
          borderRadius={"20px"}
          display="flex"
          alignItems={"center"}
          justifyContent="left"
          _checked={{
            bg: "#013280",
            color: "white",
            borderColor: "#013280",
          }}
          _focus={{
            boxShadow: "outline",
          }}
          px={5}
          py={3}
        >
          <Box>
            {props.isChecked ? (
              <Image src={checkYes} marginRight="9.33px" alt="icon check" />
            ) : (
              <Image src={checkNo} marginRight="9.33px" alt="icon not check" />
            )}
          </Box>
          {props.children}
        </Box>
      </Box>
    );
  };

  const options = ["pt-BR", "en-US", "es"];
  const [checked, setChecked] = useState([]);

  return success ? (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent w="581px" p="0px" borderRadius="20px" overflow="hidden">
        <ModalBody p="60px">
          <Box display={"flex"} flexDir="column" alignItems="center" gap="24px">
            <Icon type="check-green" width="100px" height="100px" />

            <Heading fontSize="24px" fontWeight="700">
              <Translator
                path={"survey.config.personalization.success.title"}
              />
            </Heading>
          </Box>
        </ModalBody>
        <ModalFooter width="100%" p={0} height="69px">
          <Button
            width="100%"
            color="white"
            background="#013280"
            borderRadius={0}
            height="69px"
            onClick={handleClose}
          >
            <Translator path={"global.modal.close"} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <Modal isOpen={isOpen} onClose={handleClose} size="2xl">
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: "30px",
          width: "1112px",
          padding: "10px",
          height: "552px",
        }}
      >
        <ModalCloseButton />

        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <img src={iconBar} alt="icon" />
            <Heading fontSize="18px" fontWeight="600" mt="8px" mb="24px">
              <Translator path={"survey.config.personalization.title"} />
            </Heading>

            <Box
              h="365px"
              p={5}
              border="1px"
              borderColor="#CCCCCC"
              borderRadius="12px"
            >
              <Box>
                <Tag
                  bg="#e5ebec"
                  color="#002070"
                  borderRadius="20px"
                  h="23px"
                  px="15px"
                  fontWeight="600"
                >
                  <Translator
                    path={"survey.config.personalization.language.title"}
                  />
                </Tag>
                <Text as="h4" fontSize="14px" lineHeight={"20px"} pt={2}>
                  <StrongTranslator
                    path={"survey.config.personalization.subtitle"}
                  />
                </Text>
              </Box>

              <Grid
                templateColumns="repeat(1, 1fr)"
                gap={"20px"}
                mt="20px"
                alignSelf={"center"}
              >
                <GridItem
                  w="560px"
                  h="240px"
                  border="1px"
                  borderColor="#CCCCCC"
                  fontSize="14px"
                  borderRadius="12px"
                  p="20px"
                >
                  <Text fontSize="14px" mb="20px">
                    <StrongTranslator
                      path={"survey.config.personalization.instruction"}
                    />
                  </Text>

                  <Stack spacing={5}>
                    {options.map((value) => {
                      const checkbox = getCheckboxProps({ value });
                      return (
                        <CustomCheckbox key={value} {...checkbox}>
                          {value === "pt-BR" ? (
                            <Translator
                              path={
                                "survey.config.personalization.language.portuguese"
                              }
                            />
                          ) : value === "en-US" ? (
                            <Translator
                              path={
                                "survey.config.personalization.language.english"
                              }
                            />
                          ) : (
                            <Translator
                              path={
                                "survey.config.personalization.language.spanish"
                              }
                            />
                          )}
                        </CustomCheckbox>
                      );
                    })}
                  </Stack>
                </GridItem>
              </Grid>
            </Box>

            <Flex h="92px" w="100%" mt="24px" justifyContent={"space-between"}>
              <Button
                w="265px"
                h="50px"
                variant="outline"
                color="red.100"
                fontWeight="700"
                borderColor="red.100"
                borderRadius="10px"
                onClick={handleClose}
              >
                <Translator path={"global.modal.cancel"} />
              </Button>
              <Button
                type="submit"
                w="265px"
                bg="#013280"
                color="#ffffff"
                borderRadius="10px"
                h="50px"
              >
                {isSubmitting ? (
                  <Spinner />
                ) : (
                  <Translator path={"global.modal.save"} />
                )}
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ResearchLanguageModal;
