import { Button } from "@chakra-ui/button";

export function PaginationItem({ 
  isCurrent = false, 
  onPageChange,
  number
}) {
  if (isCurrent) {
    return (
      <Button
        size="sm"
        color='white'
        fontSize="sm"
        width="4"
      bg='gray.200'
        disabled
        _disabled={{
          bg: 'blackAlpha',
          cursor: 'default',
        }}
      >
        {number}
      </Button>
    )
  }

  return (
    <Button
      size="sm"
      fontSize="sm"
      width="4"
      color='white'
      bg="gray.400"
      _hover={{
        bg: 'gray.500'
      }}
      onClick={() => onPageChange(number)}
    >
      {number}
    </Button>
  )
}