import { researchApi } from "../services/api-research";
import { useQuery } from "react-query";
import { queryClient } from "../services/queryCliente";

const fetchResearchEmployees = async (
  searchLogin,
  companyRegister,
  page,
  filterOrderBy,
  FilterStatus
) => {
  const responseCompany = await researchApi.get(
    `/research?id_company_register=${companyRegister}&sort_by=application_creation_date`
  );

  const responseEmployees = await researchApi.get(
    `/employees?research=${responseCompany.data.data[0].id}&page=${page}&sort_by=${filterOrderBy}&order=ASC&status=${FilterStatus}&login=${searchLogin}`
  );

  const csvData = await researchApi.get(`/export/${responseCompany.data.data[0].id}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  });

  return {
    employees: responseEmployees.data,
    company: responseCompany.data.data[0],
    csv: csvData.data
  };
};

export const useResearchEmployees = (
  companyRegister,
  page,
  filterOrderBy,
  FilterStatus,
  searchLogin
) => {
  return useQuery(
    `companyResearchEmployees-${searchLogin}-${companyRegister}-${page}-${filterOrderBy}-${FilterStatus}`,
    () =>
      fetchResearchEmployees(
        searchLogin,
        companyRegister,
        page,
        filterOrderBy,
        FilterStatus
      ),
    {
      enabled: !!companyRegister && !!page,
    },
    {
      staleTime: 30 * 1000,
      initialData: () =>
        queryClient.getQueryData(
          `companyResearchEmployees-${searchLogin}-${companyRegister}-${page}-${filterOrderBy}-${FilterStatus}`
        ),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(
          `companyResearchEmployees-${searchLogin}-${companyRegister}-${page}-${filterOrderBy}-${FilterStatus}`
        )?.dataUpdatedAt,
    }
  );
};
