import React, { useRef } from 'react'
import styled from 'styled-components'
import { Icon } from '../../..'

const StyledBtn = styled.a`
  
`
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;

  margin-right: 20px;
  margin-bottom: 40px;

  width: 197px;
  height: 281px;
  border-radius: 10px;
  border: 1px dashed 5C6066;
`
const StyledInput = styled.input.attrs({
  type: 'file'
})`
  display: none
`

const Container = styled.div`


display: flex;
flex-direction: column;
justify-items: center;
align-items: center;
`

function InputFile(props){
  const inputFile = useRef(null)
    return <Container>
            <StyledContainer>
                <StyledInput type="file" ref={inputFile}   {...props} />
                <StyledBtn
                  href=""
                  onClick={()=>{
                    inputFile.current.click()
                  }}
                >
                  <Icon type="plus-red" />
                </StyledBtn>
                <p>{props.label}</p>
            </StyledContainer>
          </Container>
}

export default InputFile
