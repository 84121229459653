import {
  Box,
  FormLabel,
  CircularProgress,
  CircularProgressLabel,
  Icon,
  Image,
  Text,
  FormControl,
  FormErrorMessage,
  Flex,
  useToast,
  Tooltip,
  Input,
  Button,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import axios from "axios";
import { useState, forwardRef, useCallback, useEffect } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { researchApi } from "../../../services/api-research";
import iconAdd from "../../../assets/images/icon-add-file.svg";
import iconRemove from "../../../assets/images/icon-remove-red.svg";
import Translator from "../../components/i18n/Translator";
import StrongTranslator from "../../components/i18n/StrongTranslator";

const FileInputBase = (
  {
    name,
    currentFile,
    error,
    setImageUrl,
    setCurrentFile,
    setError,
    errorsTotal,
    setErrorsTotal,
    setEmployeesTotal,
    onChange,
    trigger,
    researchId,
    onClose,
    ...rest
  },
  ref
) => {
  const toast = useToast();
  const [progress, setProgress] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [cancelToken, setCancelToken] = useState({});
  const [errorFull, setErrorFull] = useState(false);
  const [fileName, setFileName] = useState("");

  const handleImageUpload = useCallback(
    async (event) => {
      if (!event.target.files?.length) {
        return;
      }

      setCurrentFile(undefined);
      setEmployeesTotal(0);
      setIsSending(true);

      await onChange(event);
      trigger("file");

      const formData = new FormData();

      formData.append("research", researchId);
      formData.append(event.target.name, event.target.files[0]);

      const { CancelToken } = axios;
      const source = CancelToken.source();
      setCancelToken(source);
      const config = {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (e) => {
          setProgress(Math.round((e.loaded / e.total) * 100));
        },
        // cancelToken: source.token,
      };

      try {
        const response = await researchApi.post(
          "/employees/upload",
          formData,
          config
        );
        console.log("res", response);
        const success = response.data.filter((res) => !res.error);
        const findErrors = response.data.filter((res) => res.error);

        if (findErrors.length) {
          setErrorsTotal(findErrors.length);
        }

        if (success.length > 0) {
          setEmployeesTotal(success.length);
          setFileName(event.target.files[0].name);
          setCurrentFile(event.target.files[0]);
        } else {
          setErrorFull(true);
          throw Error(response.data.find((res) => res.error === true).message);
        }
      } catch (error) {
        console.error(`Erro ao enviar planilha`, error.response);
        const code = error.response?.data?.code;
        let login = "";

        if (code) {
          let message;
          switch (code) {
            case "EMPLOYEE_LIMIT_REACHED":
              message = (
                <Translator path={"global.error.employee_sheet.limitReached"} />
              );

              break;
            case "EMPLOYEE_SHEET_INCONSISTENT":
              message = (
                <Translator path={"global.error.employee_sheet.inconsistent"} />
              );

              break;
            case "EMPLOYEE_SHEET_NOT_REACHED_MINIMUM":
              message = (
                <Translator
                  path={"global.error.employee_sheet.notReachedMinimum"}
                />
              );

              break;
            case "RESEARCH_NOT_IN_PROGRESS":
              message = (
                <Translator
                  path={"global.error.employee_sheet.notInProgress"}
                />
              );

              break;
            case "REQUIRED_FIELDS_NOT_FOUND":
              message = (
                <Translator
                  path={"global.error.employee_sheet.requiredFields"}
                />
              );

              break;
            case "LOGIN_NOT_UNIQUE":
              message = (
                <Translator path={"global.error.employee_sheet.notUnique"} />
              );

              break;
            case "LOGIN_INVALID":
              login = getLoginFromMessage(error.response?.data?.message[0]);
              message = (
                <StrongTranslator
                  login={login}
                  path={"global.error.employee_sheet.invalidLogin"}
                />
              );

              break;
            case "PASSWORD_INVALID":
              login = getLoginFromMessage(error.response?.data?.message[0]);
              message = (
                <StrongTranslator
                  login={login}
                  path={"global.error.employee_sheet.invalidPassword"}
                />
              );

              break;
            default:
              message = <Translator path={"global.error.tryAgain"} />;
              break;
          }

          toast({
            title: (
              <Translator path={"survey.config.sheet.modal.error.title"} />
            ),
            description: message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: (
              <Translator path={"survey.config.sheet.modal.error.title"} />
            ),
            description: (
              <Translator
                path={"survey.config.sheet.modal.error.description"}
              />
            ),
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }

        setTimeout(() => {
          onClose();
        }, 1000);
      } finally {
        setIsSending(false);
        setProgress(0);
      }
    },
    [
      trigger,
      toast,
      onChange,
      onClose,
      setCurrentFile,
      researchId,
      setEmployeesTotal,
      setErrorsTotal,
    ]
  );

  useEffect(() => {
    if (errorFull && isSending && cancelToken?.cancel) {
      cancelToken.cancel("Cancelled file upload.");
      setCancelToken(null);
    }
  }, [cancelToken, errorFull, isSending]);

  const handleRemoveFile = () => {
    setEmployeesTotal(0);
    setCancelToken(null);
    setCurrentFile(undefined);
  };

  const getLoginFromMessage = (message) => {
    if (!!!message) return "";
    const aux = message.split("{{");
    return aux[1].split("}}")[0];
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel
        mx="auto"
        w="100%"
        h="92px"
        htmlFor={name}
        cursor={isSending ? "progress" : "pointer"}
        opacity={isSending ? 0.5 : 1}
      >
        {currentFile && !isSending ? (
          <Box textAlign="center">
            <InputGroup display="flex" h="58px" alignItems="center">
              <Input
                defaultValue={fileName}
                readOnly
                w="100%"
                h="58px"
                fontWeight="500"
                px="25px"
                color="#292B30"
                borderRadius="10px"
                border="1px"
                borderColor="#40BF91"
              />
              <InputRightElement width="4.5rem" h="100%">
                <Button
                  cursor="pointer"
                  variant="ghost"
                  display="flex"
                  alignItems="center"
                  onClick={handleRemoveFile}
                >
                  <Image src={iconRemove} alt="remove" />
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>
        ) : (
          <Flex
            w="100%"
            h="full"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            borderRadius="md"
            bgColor="pGray.800"
            color="pGray.200"
            borderWidth={error?.message && 2}
            borderColor={error?.message && "red.500"}
          >
            {isSending ? (
              <>
                <CircularProgress
                  trackColor="pGray.200"
                  value={progress}
                  color="orange.500"
                >
                  <CircularProgressLabel>{progress}%</CircularProgressLabel>
                </CircularProgress>
                <Text as="span" pt={2} textAlign="center">
                  <Translator path={"survey.config.sheet.modal.progress"} />
                </Text>
              </>
            ) : (
              <Box pos="relative" h="full" w="100%">
                {!!error && (
                  <Tooltip label={error.message} bg="red.500">
                    <FormErrorMessage
                      pos="absolute"
                      right={2}
                      top={2}
                      mt={0}
                      zIndex="tooltip"
                    >
                      <Icon as={FiAlertCircle} color="red.500" w={4} h={4} />
                    </FormErrorMessage>
                  </Tooltip>
                )}

                <Flex
                  w="100%"
                  h="102px"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="10px"
                  border={!!error ? "none" : "1px"}
                  gap="16px"
                  borderColor="#5C6066"
                  borderStyle="dashed"
                >
                  <Image src={iconAdd} alt="icon add" />
                  <Text as="span" pt={2} textAlign="center" fontSize="14px">
                    <StrongTranslator
                      path={"survey.config.sheet.modal.instruction"}
                    />
                  </Text>
                </Flex>
              </Box>
            )}
          </Flex>
        )}
        <input
          data-testid={name}
          // disabled={isSending}
          id={name}
          name={name}
          onChange={handleImageUpload}
          ref={ref}
          type="file"
          style={{
            display: "none",
          }}
          {...rest}
        />
      </FormLabel>

      {!!error && (
        <Text color="red.100" fontSize="14px" fontWeight="600" mt="10px">
          {error.message}
        </Text>
      )}
    </FormControl>
  );
};

export const FileInput = forwardRef(FileInputBase);
