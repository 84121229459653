import { researchApi } from "../services/api-research";
import { useQuery } from "react-query";
import { queryClient } from "../services/queryCliente";

export const fetchResearch = async (researchId) => {
  const response = await researchApi.get(`/status/${researchId}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
  const status = response.data;
  return status;
};

export const useResearch = (researchId) => {
  return useQuery(
    `research-${researchId}`,
    () => fetchResearch(researchId),
    {
      // The query will not execute until the userId exists
      enabled: researchId !== undefined,
    },
    {
      staleTime: 30 * 1000,
      initialData: () => queryClient.getQueryData("research"),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState("research")?.dataUpdatedAt,
    }
  );
};
