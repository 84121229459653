import { useState } from "react";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Heading,
  Text,
  ModalFooter,
  Box,
  Flex,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import confImage from "../../../assets/images/confirmation.svg";
import checkGreen from "../../../style/image/icons/icons/green-check-100.svg";
import { researchApi } from "../../../services/api-research";
import Translator from "../../components/i18n/Translator";
const ConfModal = ({
  onClose,
  isOpen,
  currentEmployee,
  setCurrentEmployee,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const toast = useToast();

  const handleSubmit = async () => {
    setIsLoading(true);
    await researchApi
      .delete(`/employees/${currentEmployee.id}`)
      .then(() => {
        setSuccess(true);
        setCurrentEmployee(null);
      })
      .catch((error) => {
        console.error(
          "Error ao atualizar informações do funcionário",
          error.response
        );
        setSuccess(false);

        toast({
          title: <Translator path={"employees.action.remove.error.title"} />,
          description: <Translator path={"global.error.tryAgain"} />,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    setIsLoading(false);
  };

  const handleClose = () => {
    onClose();
    setCurrentEmployee(null);
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
  };

  return success ? (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: "30px",
          height: "341px",
          width: "552px",
          // padding: "40px",
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box display={"flex"} flexDir="column" pt="60px">
          <div
            style={{
              margin: "0 auto",
              marginBottom: "20px",
            }}
          >
            <img src={checkGreen} alt="icon check green" />
          </div>
          <Heading fontSize="24px" fontWeight="700">
            <Translator path={"employees.action.remove.yes.success"} />
          </Heading>
        </Box>
        <ModalFooter width="100%" p={0}>
          <Button
            width="100%"
            color="white"
            background="#013280"
            height="69px"
            position="absolute"
            bottom={0}
            onClick={handleClose}
          >
            <Translator path={"global.modal.close"} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <Modal isOpen={isOpen} onClose={handleClose} size="2xl">
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: "30px",
          width: "766px",
          position: "relative",
          overflow: "hidden",
          height: "401px",
        }}
      >
        <ModalBody p="30px">
          <Flex>
            <Box p="4" w="60%">
              <Text color="#FF6700" fontSize="20px">
                <Translator path={"employees.action.remove.warning"} />
              </Text>
              <Heading fontSize="28px" fontFamily="Raleway" lineHeight="34px">
                <Translator path={"employees.action.remove.title"} />
              </Heading>
            </Box>
            <Box p="4" w="50%" h="270px">
              <img src={confImage} alt="confirmation item" />
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter position="absolute" bottom={0} w="100%" h="69px" p={0}>
          <Button
            w="100%"
            fontSize="16px"
            variant="outline"
            h="100%"
            borderRadius={0}
            onClick={handleClose}
          >
            <Translator path={"employees.action.remove.no"} />
          </Button>
          <Button
            bg="#013280"
            color="#FFFFFF"
            onClick={handleSubmit}
            type="submit"
            w="100%"
            fontSize="16px"
            h="100%"
            borderRadius={0}
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <Translator path={"employees.action.remove.yes.title"} />
            )}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfModal;
