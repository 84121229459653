/* eslint-disable no-unused-vars */
import { useEffect, useState, useContext } from "react";
import { Box, Flex, Grid, GridItem, Tag, Text, Button, Spinner } from "@chakra-ui/react";
import { useSpecificQuestions } from "../../../helpers/useSpecificQuestions";
import SpecificQuestionsModal from "./Modals/SpecificQuestionsModal";

import { CustomContext } from "../../../context/CustomContext";

const SpecificQuestions = ({ researchId , hasEmployees, onSuccess}) => {
  const { custom } = useContext(CustomContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [languageData, setLanguageData] = useState(false);
  const [customOptions, setCustomOptions] = useState([]);
  const [workOptions, setWorkOptions] = useState([]);
  const [positionOptions, setPositionOptions] = useState([]);
  const [info, setInfo] = useState([]);
  const { data, isLoading } = useSpecificQuestions(researchId);

  useEffect(() => {
    if (data instanceof Array && data.length > 0) {
      setLanguageData(true);
      setInfo(data);
    }
  }, [data]);

  const handleSpecificModal = () => {
    if (custom) {
      setModalOpen(true);
    } else return;
  };

  const handleCloseSpecificModal = () => {
    setModalOpen(false);
  };

  return !isLoading ? (
    <GridItem
      h="277px"
      border="1px"
      borderColor="#CCCCCC"
      borderRadius="20px"
      p="20px"
    >
      <SpecificQuestionsModal
        info={info}
        researchId={researchId}
        isOpen={modalOpen}
        onClose={handleCloseSpecificModal}
        onSuccess={onSuccess}
      />
      <Flex justify={"space-between"} align="center">
        <Box>
          <Tag
            bg="#e5ebec"
            color="#002070"
            fontSize="16px"
            borderRadius="20px"
            h="23px"
            px="15px"
            fontWeight="600"
          >
            Pergunta customizada
          </Tag>
          <Text as="p" fontSize="14px" pt={1} mt="8px">
            Aqui você poderá gerenciar as criação de
            <b> uma pergunta livre </b> sobre <b> qualquer tópico</b>.
          </Text>
        </Box>
        <Button
          w="175px"
          variant="outline"
          color={data.length >= 1 || !hasEmployees ? "#292B30" : "red.100"}
          border="1px"
          disabled={data.length >= 1 || !hasEmployees}
          borderColor={data.length >= 1 || !hasEmployees ? "#292B30" : "red.100"}
          borderRadius="10px"
          fontWeight="bold"
          onClick={handleSpecificModal}
        >
          {customOptions.length > 0 ? "Editar" : "Configurar"}
        </Button>
      </Flex>

      <Grid templateColumns="repeat(3, 1fr)" gap={6} mt="25px">

        {customOptions.length > 0 ? (
          <GridItem
            w="100%"
            h="95px"
            border="1px"
            borderColor="#40BF91"
            fontSize="14px"
            borderRadius="10px"
            p="20px"
          >
            <Text fontSize={"16px"} fontWeight="bold">
              Pergunta customizável
            </Text>
            <div
              style={{
                backgroundColor: "#013280",
                width: "25px",
                height: "2px",
              }}
            ></div>
            <Text fontSize={"13px"} fontWeight="600" mt="5px">
              Foram selecionadas {customOptions.length} alternativas
            </Text>
          </GridItem>
        ) : (
          <GridItem w="100%" h="95px">
            <Box
              cursor={data.length >= 1 || !hasEmployees ? "not-allowed" : "pointer"}
              display="flex"
              alignItems="center"
              justifyContent="center"
              w="100%"
              h="100%"
              border="1px"
              borderRadius="10px"
              borderColor="#40BF91"
              borderStyle="dashed"
              onClick={data.length >= 1 || !hasEmployees ? () => {} : handleSpecificModal}
            >
              <Text fontSize="14px"> Crie e customize uma pergunta</Text>
            </Box>
          </GridItem>
        )}
      </Grid>
    </GridItem>
  ) : (
    <Box w="100%" h="40vh" textAlign="center" pt="20px">
      <Spinner />
    </Box>
  );
};

export default SpecificQuestions;
