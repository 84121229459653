import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Icon } from '../../../style'

const StyledLink = styled(Link)`
  text-decoration: ${props=>props.underline ? `underline`: 'none'};
  color: ${props=>props.color};

  ${props=>{
    switch(props.type){
      case "libraryLink":
        return `
            font-family: Raleway;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            text-decoration-line: underline;

            color: #FFFFFF;
        `
      break;
      case "breadcumbs":
        return `
        font-family: Raleway;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        
        
        color: #292B30;
        `
      break;
      case "menuVertical":
        return `
          font-family: Raleway;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;

          margin: 0;
          margin-top: 9px;
          margin-bottom: 9px;

          padding: 0;
          padding-top: 9px;
          padding-bottom: 7px;

          border: 3px solid inherit;


          &:hover{
            border-right: 3px solid #013280;
            border-color: #013280;
            
          }
          
         

        `
      break;
      case "searchLink":
          return `
          font-family: Raleway;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          text-decoration-line: underline;

          color: #FFFFFF;
       
      `
        break;
        case "":
            return `
              font-family: Raleway;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 16px;
              text-decoration-line: underline;
              color: #FFFFFF;
            `
        break;
        case "normal": 
          return `
            font-family: Raleway;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #013280;
          `
        break;
        case "colorRed":
            return ` color: #013280; `
        break;
      default:
        return `
        font-family: Raleway;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 21px;

        color: #fff;
        `
    }}}

    margin-top: ${props=>props.inicial ? `0px` : `9px`} !important;
    margin-bottom: ${props=>props.final ? `0px` : `9px`} !important;
    
    ${props=>props.bolder ? `
      color: #292B30;  
      border-right: 3px solid #013280;
      border-color: #013280;
    ` : 
    `
      color: ${props=>props.color}; 
      border: none;
    `}
    
`
const DivImg = styled.span`
  margin: 0;
  margin-left: 32px;
  margin-right: 20px;
`

const DivImg2 = styled.span`
margin-left: 8px;
`

function Links(props){
  console.log(props)

  return <StyledLink {...props}>
            {props.icone &&
              <DivImg>
                <Icon type={props.icone} />
              </DivImg>
            }
            {props.label}
            {props.icon &&
              <DivImg2>
                <Icon type={props.icon} />
              </DivImg2>
            }
         </StyledLink>
}

export default Links
