import { useState } from "react";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import iconBar from "../../../assets/images/icon-bar.svg";
import checkGreen from "../../../style/image/icons/icons/green-check-100.svg";
import checkYes from "../../../style/image/icons/icons/check-yes.svg";
import checkNo from "../../../style/image/icons/icons/check-no.svg";
import Translator from "../../components/i18n/Translator";
import InputMask from "react-input-mask";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  ModalFooter,
  Box,
  Grid,
  GridItem,
  Flex,
  FormLabel,
  Stack,
  Input,
  useRadio,
  useRadioGroup,
  Image,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { researchApi } from "../../../services/api-research";
import { useTranslation } from "react-i18next";

const CustomStatus = (props) => {
  const { getInputProps, getCheckboxProps, htmlProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  let bgColor = "#E60000";
  if (props.value === "Ativo") {
    bgColor = "#2CD9DD";
  }

  return (
    <Box as="label" {...htmlProps} cursor="pointer">
      <input {...input} />
      <Box
        {...checkbox}
        bg="#E5E5E5"
        w="90px"
        h="32px"
        borderRadius={"20px"}
        display="flex"
        alignItems={"center"}
        justifyContent="center"
        _checked={{
          bg: bgColor,
          color: "white",
          borderColor: bgColor,
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
      >
        {props.isChecked ? (
          <Image src={checkYes} marginRight="9.33px" alt="icon check" />
        ) : (
          <Image src={checkNo} marginRight="9.33px" alt="icon not check" />
        )}
        {props.children}
      </Box>
    </Box>
  );
};

const ModalColaborator = ({
  onClose,
  isOpen,
  onSuccess,
  researchId,
  currentEmployee,
  setCurrentEmployee,
}) => {
  const [selectedStatus, setSelectedStatus] = useState("Ativo");
  const [success, setSuccess] = useState(false);
  const [login, setLogin] = useState("");
  const [description, setDescription] = useState(null);

  const toast = useToast();
  const { t } = useTranslation();
  const schema = yup.object().shape({
    login: yup
      .string()
      .transform((_, val) => (val.length === 0 ? login : val))
      .required(<Translator path={"global.error.required"} />)
      .email(<Translator path={"global.form.email.invalid"} />),
    description: yup
      .string()
      .transform((_, val) => (val.length === 0 ? description : val))
      .nullable(true),
  });

  const { getRootProps, getRadioProps, setValue } = useRadioGroup({
    name: "isActive",
    defaultValue: selectedStatus,
    onChange: setSelectedStatus,
  });
  const options = ["Ativo", "Inativo"];
  const group = getRootProps();

  useEffect(() => {
    if (currentEmployee) {
      setSelectedStatus(currentEmployee.status);
      setLogin(currentEmployee.login);
      setDescription(currentEmployee.name);
      setValue(currentEmployee.status);
    } else {
      setSelectedStatus("Ativo");
      setLogin("");
      setDescription(null);
      setValue("Ativo");
    }
  }, [currentEmployee]);

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const createEmployee = async (data) => {
    console.log("Create employee", data);

    await researchApi
      .post(`/employees`, {
        name: data.description,
        status: selectedStatus,
        email: data.login,
        login: data.login,
        research: researchId,
      })
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        console.error(
          "Error ao cadastrar informações do funcionário",
          error.response
        );
        setSuccess(false);
        const code = error.response?.data?.code;

        if (code) {
          let message;
          switch (code) {
            case "LOGIN_ALREADY_REGISTERED":
              message = (
                <Translator path={"global.error.employees.loginExist"} />
              );

              break;
            case "EMPLOYEE_LIMIT_REACHED":
              message = (
                <Translator path={"global.error.employees.limitReached"} />
              );

              break;
            default:
              message = <Translator path={"global.error.tryAgain"} />;
              break;
          }

          toast({
            title: (
              <Translator path={"employees.add.manual.form.error.title"} />
            ),
            description: message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: (
              <Translator path={"employees.add.manual.form.error.title"} />
            ),
            description: <Translator path={"global.error.tryAgain"} />,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      });
  };

  const editEmployee = async (data) => {
    console.log("Edit employee", data);

    await researchApi
      .put(`/employees/${currentEmployee.id}`, {
        name: description,
        status: selectedStatus,
        email: login,
        login: login,
        research: researchId,
      })
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        console.error("Error ao atualizar informações do funcionário", error);
        setSuccess(false);
        const code = error.response?.data?.code;

        if (code) {
          let message;
          switch (code) {
            case "LOGIN_ALREADY_REGISTERED":
              message = (
                <Translator path={"global.error.employees.loginExist"} />
              );

              break;
            case "EMPLOYEE_LIMIT_REACHED":
              message = (
                <Translator path={"global.error.employees.limitReached"} />
              );

              break;
            default:
              message = <Translator path={"global.error.tryAgain"} />;
              break;
          }

          toast({
            title: <Translator path={"employees.action.edit.error.title"} />,
            description: message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: <Translator path={"employees.action.edit.error.title"} />,
            description: <Translator path={"global.error.tryAgain"} />,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      });
  };

  const onValid = async (data) => {
    console.log("Formulário valido");

    if (currentEmployee) {
      await editEmployee(data);
    } else {
      await createEmployee(data);
    }
  };

  const onInvalid = (invalid) => {
    console.log("Formulário invalido", invalid);

    setSuccess(false);
  };

  const handleInput = (event) => {
    const targetName =
      event.target.id.charAt(0).toUpperCase() + event.target.id.slice(1);

    const targetValue = event.target.value;

    console.log("Atualizando variável", `set${targetName}('${targetValue}')`);

    eval(`set${targetName}('${targetValue}')`);
  };

  const handleClose = () => {
    clearErrors();
    setCurrentEmployee(null);
    setLogin("");
    setDescription(null);
    setSelectedStatus("Ativo");
    setValue("Ativo");
    reset();
    if (success) onSuccess();
    onClose();

    setTimeout(() => {
      setSuccess(false);
    }, 1000);
  };

  return success ? (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: "30px",
          height: "341px",
          width: "552px",
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box display={"flex"} flexDir="column" pt="60px">
          <div
            style={{
              margin: "0 auto",
              marginBottom: "20px",
            }}
          >
            <img src={checkGreen} alt="icon check green" />
          </div>
          <Heading fontSize="24px" fontWeight="700">
            {currentEmployee ? (
              <Translator path={"employees.action.edit.success"} />
            ) : (
              <Translator path={"employees.add.manual.form.success"} />
            )}
          </Heading>
        </Box>
        <ModalFooter
          width="100%"
          position={"absolute"}
          p={0}
          bottom={0}
          height="69px"
        >
          <Button
            width="100%"
            color="white"
            background="#013280"
            height="100%"
            fontWeight="300"
            onClick={handleClose}
          >
            <Translator path={"global.modal.close"} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <Modal isOpen={isOpen} onClose={handleClose} size="2xl">
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: "12px",
          width: "800px",
          position: "relative",
          height: "396px",
          overflow: "hidden",
        }}
      >
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody px="33px">
          <img src={iconBar} alt="icon bar" />
          <Heading fontSize="18px" fontWeight="600" mb="10px" mt="8px">
            {currentEmployee ? (
              <Translator path={"employees.action.edit.title"} />
            ) : (
              <Translator path={"employees.add.manual.subtitle"} />
            )}
          </Heading>

          <Grid templateColumns="repeat(2, 1fr)" gap={4} mt="20px">
            <GridItem colSpan={1} h="77px">
              <Text fontSize="12px">
                <Translator
                  path={"employees.add.manual.form.description.title"}
                />
              </Text>
              <Input
                id="description"
                label={
                  <Translator
                    path={"employees.add.manual.form.description.helper"}
                  />
                }
                isInvalid={errors.description}
                errorBorderColor="crimson"
                placeholder={t("employees.add.manual.form.description.helper")}
                {...register("description", { required: true })}
                border="1px"
                borderStyle="solid"
                borderColor="#B8CAE8"
                borderRadius="10px"
                w="305px"
                h="56px"
                mt="8px"
                value={description || ""}
                as={InputMask}
                onChange={handleInput}
              />
            </GridItem>
            {errors.description && <p>{errors.description.message}</p>}
            <GridItem colSpan={1} h="77px">
              <Text fontSize="12px">
                <Translator path={"employees.add.manual.form.login.title"} />
              </Text>
              <Input
                id="login"
                label={
                  <Translator path={"employees.add.manual.form.login.title"} />
                }
                error={errors.login}
                placeholder={t("employees.add.manual.form.login.helper")}
                {...register("login", { required: true })}
                border="1px"
                borderStyle="solid"
                borderColor="#B8CAE8"
                borderRadius="10px"
                w="305px"
                h="56px"
                mt="8px"
                as={InputMask}
                value={login || ""}
                onChange={handleInput}
              />
              {errors.login && <p>{errors.login.message}</p>}
            </GridItem>
            <GridItem colSpan={1} h="77px">
              <FormLabel
                fontSize="xs"
                color="#292B30"
                fontWeight="normal"
                mb="8px"
              >
                <Translator path={"employees.add.manual.form.status.title"} />
              </FormLabel>

              <Stack {...group} spacing={5} direction="row">
                {options.map((value) => {
                  const radio = getRadioProps({ value });
                  return (
                    <CustomStatus key={value} {...radio}>
                      {value === "Ativo" ? (
                        <Translator
                          path={"employees.add.manual.form.status.active"}
                        />
                      ) : (
                        <Translator
                          path={"employees.add.manual.form.status.inactive"}
                        />
                      )}
                    </CustomStatus>
                  );
                })}
              </Stack>
            </GridItem>
          </Grid>
        </ModalBody>
        <ModalFooter w="100%" mb="15px">
          <Flex justify={"space-between"} w="100%">
            <Button
              w="265px"
              variant="outline"
              size="lg"
              color="#013280"
              fontSize="16px"
              fontWeight="600"
              borderColor="#013280"
              borderRadius="10px"
              border="1px"
              onClick={handleClose}
            >
              <Translator path={"global.modal.cancel"} />
            </Button>
            <Button
              w="265px"
              onClick={handleSubmit(onValid, onInvalid)}
              type="submit"
              bg="#013280"
              color="white"
              borderRadius="10px"
              size="lg"
              fontSize="16px"
            >
              {isSubmitting ? (
                <Spinner />
              ) : (
                <Translator path={"global.modal.add"} />
              )}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalColaborator;
