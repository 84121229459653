import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ProgressBar from "@ramonak/react-progress-bar";

import {
  Container,
  Stack,
  Text,
  Image,
  Flex,
  Button,
  Box,
  Grid,
  GridItem,
  Heading,
  ListItem,
  UnorderedList,
  Spinner,
} from "@chakra-ui/react";
import SimpleSidebar from "../../components/nav/Sidebar";
import Breadcrumbs from "../../components/breadcrumbs";
import icon from "../../../assets/images/heading-icon.svg";

import { ResumeCard, BottomCard } from "./Card";
import CircularChart, { CircularChartDescription } from "./CircularChart";
import { useResearch } from "../../../helpers/useResearch";
import Translator from "../../components/i18n/Translator";
import StrongTranslator from "../../components/i18n/StrongTranslator";
import { researchApi } from "../../../services/api-research";
import { useContext } from "react";
import { CustomContext } from "../../../context/CustomContext";

const Layout = styled.div`
  margin-left: 32px;
  background: #f6f5fb;
`;

function Results({ validateRoute }) {
  const { userPermissions } = useContext(CustomContext);

  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);
  const st = urlParams.get("st");
  const cr = urlParams.get("cr");

  const [researchData, setResearchData] = useState();
  const { data } = useResearch(st);

  console.log("researchData", researchData);

  useEffect(() => {
    const handleGetResearchNote = async () => {
      if (data === undefined) {
        return;
      }

      let summaryData = {};
      try {
        const response = await researchApi.get(
          `/research/summary/${data.research.extern_researchId}`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        );

        summaryData = response.data;
      } catch (error) {
        console.log("Erro ao recuperar summary: ", error);
      }

      setResearchData({
        ...data,
        summary: summaryData,
      });
    };

    handleGetResearchNote();
  }, [st, data]);

  const breadcumbs = [
    {
      nome: <Translator path={"results.title"} />,
      tipo: "link",
      path: `/results?cr=${cr}`,
      icone: "",
      ativado: true,
    },
    {
      nome: (
        <Translator path={"results.survey.table.content.resume.subtitle"} />
      ),
      tipo: "link",
      link: queryString,
      icone: "",
      ativado: true,
    },
  ];

  const participationPercentage = Math.round(
    (researchData?.summary?.finished_answers /
      researchData?.summary?.total_workers) *
      100
  );

  const ChartValues = [
    {
      value: researchData?.summary?.total_workers || "-",
      inicial: 0,
      final: researchData?.summary?.total_workers || "-",
      color: "#E60000",
      description: () => {
        return (
          <Text
            h="28px"
            textAlign={"center"}
            color="#292B30"
            fontSize="11px"
            lineHeight={"14.09px"}
          >
            <StrongTranslator
              path={"results.survey.table.content.resume.participation.invited"}
            />
          </Text>
        );
      },
    },
    {
      value: isNaN(participationPercentage)
        ? 0
        : participationPercentage || "-",
      inicial: 0,
      final: 100,
      color: "#40BF91",
      description: () => {
        return (
          <Text
            h="28px"
            textAlign={"center"}
            color="#292B30"
            fontSize="11px"
            lineHeight={"14.09px"}
          >
            <StrongTranslator
              path={
                "results.survey.table.content.resume.participation.percentage"
              }
            />
          </Text>
        );
      },
    },
    {
      value: researchData?.summary?.finished_answers || "-",
      inicial: 0,
      final: researchData?.summary?.total_workers,
      color: "#5E22B8",
      description: () => {
        return (
          <Text
            h="28px"
            textAlign={"center"}
            color="#292B30"
            fontSize="11px"
            fontWeight={600}
            lineHeight={"14.09px"}
          >
            <Translator
              path={"results.survey.table.content.resume.participation.total"}
            />
          </Text>
        );
      },
    },
  ];

  return !validateRoute(userPermissions, window.location.pathname) ? (
    <></>
  ) : (
    <Layout>
      <SimpleSidebar path="">
        <Container
          maxW={["100%", "calc(100vw - 250px)"]}
          ml={["unset", "auto"]}
          pt={["unset", "20px"]}
          pl={["unset", "20px"]}
        >
          <Stack spacing={"24px"}>
            <Breadcrumbs breadcumbsItems={breadcumbs} />

            <Flex h="24px" align="center" justifyContent={"space-between"}>
              <Flex h="21px" align="center" gap="10px">
                <Image src={icon} alt="" />
                <Heading fontSize={"18px"} fontWeight={700}>
                  <Translator
                    path={"results.survey.table.content.resume.title"}
                  />
                </Heading>
              </Flex>
              <Button variant="naked">
                <Translator
                  path={"results.survey.table.content.resume.download"}
                />
              </Button>
            </Flex>

            {researchData === undefined ||
            researchData.summary === undefined ? (
              <Box w="100%" textAlign="center" h="30vh" pt="20px">
                <Spinner />
              </Box>
            ) : (
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <ResumeCard
                  title={
                    <Translator
                      path={
                        "results.survey.table.content.resume.participation.title"
                      }
                    />
                  }
                >
                  <Grid templateColumns="repeat(3, 1fr)" gap={0}>
                    {ChartValues.map((chart, index) => (
                      <Stack key={index} pt="22px" spacing={"12px"}>
                        <CircularChart options={chart} />

                        {chart.description()}
                      </Stack>
                    ))}
                  </Grid>
                </ResumeCard>
                <ResumeCard
                  title={
                    <Translator
                      path={"results.survey.table.content.resume.data.title"}
                    />
                  }
                >
                  <Grid templateColumns="repeat(1, 1fr)" gap={0}>
                    <Stack pt="22px" spacing={"12px"}>
                      <CircularChart
                        options={{
                          value: researchData.research.note || "-",
                          inicial: 0,
                          final: 100,
                          color: "#0062FF",
                        }}
                      />
                      <Text
                        h="28px"
                        textAlign={"center"}
                        color="#292B30"
                        fontSize="11px"
                        lineHeight={"14.09px"}
                      >
                        <StrongTranslator
                          path={"results.survey.table.content.resume.data.note"}
                        />
                      </Text>
                    </Stack>
                  </Grid>
                </ResumeCard>
              </Grid>
            )}
          </Stack>
        </Container>
      </SimpleSidebar>
    </Layout>
  );
}

export default Results;
